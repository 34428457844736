import React, {
    useLayoutEffect,
    useContext,
    useReducer,
} from "react";
import { reducer } from "./reducer";
import { notification } from "antd";

const context = React.createContext();

export const usePurchaseContext = () => {
    const ctx = useContext(context);
    if (ctx === undefined) {
        throw new Error("useAuthState must be used within a AuthProvider");
    }
    return ctx;
};


const initialState = {
    groups: [],
    deviceType: "Desktop",
    profile_dialog: false,
    sidebarOpen: true,
    pageLoader: false,
    menu_list: []

};

export const PurchaseContext = ({ children }) => {
    const [api, contextHolder] = notification.useNotification();
    const [state, dispatch] = useReducer(reducer, initialState);

    useLayoutEffect(() => {
        // const token = localStorage.getItem("token");
        // const type = localStorage.getItem("type");
        // dispatch({ pageLoader: true });

        // const fetchData = async () => {
        //     if (token) {
        //         var url = type === "SSO" ? "employee/info" : "user/info";
        //         try {
        //             const res = await API.GET(url);
        //             await dispatch({ info: res.response });

        //         } catch (error) {

        //         } finally {
        //             dispatch({ pageLoader: false });
        //         }

        //     }
        // }
        // fetchData()
    }, []);

    const message = (type, title, description = null) => {
        if (type === "error") {
            api.error({
                message: title,
                description: description,
                placement: "topRight",
                duration: 5,
                showProgress: true,
            });
        }
        if (type === "info") {
            api.info({
                message: title,
                description: description,
                placement: "topRight",
                duration: 5,
                showProgress: true,
            });
        }
        if (type === "success") {
            api.success({
                message: title,
                description: description,
                placement: "topRight",
                duration: 5,
                showProgress: true,
            });
        }
        if (type === "warning") {
            api.warning({
                message: title,
                description: description,
                placement: "topRight",
                duration: 5,
                showProgress: true,
            });
        }
    };

    return (
        <context.Provider
            value={{
                purchaseState: state,
                purchaseDispatch: dispatch,
                message,
            }}
        >
            {contextHolder}
            {children}
        </context.Provider>
    );
};

export default React.memo(PurchaseContext);
