import {
  Input,
  DatePicker,
  Select,
  Upload,
  Button,
  Table,
  Spin,
  Modal,
  Tag,
  Space,
  TreeSelect,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import * as API from "src/api/request";
import TextArea from "antd/es/input/TextArea";
import { useUserContext } from "src/context/userContext";
import _ from "lodash";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
  CloseButton,
} from "src/hr/components/button";

dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);

const Leave = () => {
  const [state, setState] = useState({
    open: false,
    submit: false,
    loading: false,
    id: null,
    begin_date: null,
    start_date: null,
    end_date: null,
    date_work: null,
    duration_days: "",
    name: "",
    type_id: null,
    plan_id: null,
    status: null,
    substitute_employee_id: null,
    fee: null,
    day_abnormal_str: null,
    day_emc_str: null,
    day_normal_str: null,
    day_sum_str: null,
    work_begin: null,
    work_end: null,
    reviews: [],
    attachments: [],
    types: [],
    plans: [],
    leaves: [],
    substitute_employees: [],
    holidays: [],
    prev_leaves: [],
    location_list: [],
    location: null,
  });

  const { user, message } = useUserContext();

  // function isWeekend(date) {
  //   const day = new Date(date).getDay();
  //   return (
  //     day === 0 ||
  //     day === 6 ||
  //     _.find(
  //       state?.holidays,
  //       (i) => i.date_from === dayjs(date).format("YYYY-MM-DD")
  //     )
  //   );
  // }

  const Types = () => {
    API.GET("/employee/leave/type").then((res) => {
      var result_types = _.map(res?.response, (item) => ({
        ...item,
        title: item.display_name,
        key: item.id,
        value: item.id,
        pId: item.parent_id ? item.parent_id[0] : null,
      }));
      result_types = _.map(result_types, (item) => ({
        ...item,
        selectable: _.filter(result_types, { pId: item.id }).length === 0,
      }));
      setState((old) => ({
        ...old,
        types: _.orderBy(result_types, ["parent_id"], ["desc"]),
      }));
    });
  };

  const Plans = () => {
    API.GET("/employee/leave/plan").then((res) => {
      setState((old) => ({
        ...old,
        plans: res?.response,
      }));
    });
  };

  const SubstituteEmployees = () => {
    API.GET("/department/employee").then((res) => {
      var employees = [];
      if (res?.response?.length > 0) {
        _.map(res?.response, (item) => {
          return employees.push({
            id: item.id,
            name: item.name + " [" + item.tn + "]",
          });
        });
      }
      setState((old) => ({
        ...old,
        substitute_employees: employees,
      }));
    });
  };

  const Holidays = () => {
    API.GET("/reference", {
      model_name: "hr.leave.holidays",
    }).then((res) => {
      var result_holidays = [];
      if (res?.response?.length > 0) {
        _.map(res?.response, (item) => {
          return result_holidays.push({
            ...item,
            key: item?.id,
            date_from: item?.start_date,
            date_to: item?.end_date,
          });
        });
      }
      setState((old) => ({
        ...old,
        holidays: result_holidays,
      }));
    });
  };

  const fetch_list = async () => {
    try {
      setState((old) => ({
        ...old,
        loading: true,
      }));
      const leaves = await API.GET("/employee/leave");

      var result_leaves = [];
      if (leaves?.response?.length > 0) {
        _.map(leaves?.response, (item) => {
          return result_leaves.push({
            ...item,
            key: item?.id,
            plan_id: item?.plan_id[0],
            type_id: item?.type_id[0],
            type_name: item?.type_id[1],
          });
        });
      }

      setState((old) => ({
        ...old,
        leaves: _.orderBy(result_leaves, ["start_date"], ["desc"]),
        prev_leaves: _.take(
          _.orderBy(
            _.filter(result_leaves, { type_id: 891 }),
            ["start_date"],
            ["desc"]
          ),
          3
        ),
        loading: false,
      }));
    } catch (error) {
      message("error", "Алдаа", error?.message);
    } finally {
      setState((old) => ({
        ...old,
        loading: false,
      }));
    }
  };

  const Location = () => {
    API.GET("/reference/selection", {
      model_name: "new.hr.leave",
      field_name: "location",
    }).then((res) => {
      var result_location = [];
      if (res?.response?.length > 0) {
        _.map(res?.response, (item) => {
          return result_location.push({
            ...item,
            key: item?.id,
          });
        });
      }
      setState((old) => ({
        ...old,
        location_list: result_location,
      }));
    });
  };

  const fetch_data = () => {
    fetch_list();
    Types();
    Plans();
    SubstituteEmployees();
    Holidays();
    Location();
  };

  useEffect(() => {
    fetch_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculate = async (start_date) => {
    const res = await API.GET("/employee/leave/calculate", {
      begin_date: dayjs(start_date).format("YYYY-MM-DD"),
    });
    return res?.response;
  };

  const working = async (start_date, total_days) => {
    const res = await API.GET("/employee/leave/working", {
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      total_days: parseInt(total_days),
    });
    return res?.response;
  };

  const vacation = async (start_date, end_date) => {
    const res = await API.GET("/employee/leave/vacation", {
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      end_date: dayjs(end_date).format("YYYY-MM-DD"),
    });
    return res?.response;
  };

  const vacation_shift = async (start_date, end_date) => {
    const res = await API.GET("/employee/leave/vacation_shift", {
      start_date: dayjs(start_date).format("YYYY-MM-DD"),
      end_date: dayjs(end_date).format("YYYY-MM-DD"),
    });
    return res?.response;
  };

  const plan_change = async (plan_id) => {
    var plan = _.find(state?.plans, { id: plan_id });

    if (plan) {
      setState((old) => ({
        ...old,
        loading: true,
      }));

      var leaves = _.find(state?.prev_leaves, {
        plan_id: plan_id,
        state: "approved",
      });

      var start_date = leaves
        ? plan?.current_leave_date_second
        : plan?.current_leave_date;

      const calc = await calculate(start_date);

      var tday = parseInt(calc?.day_const) + parseInt(calc?.day_extra);

      var total_days = plan?.current_split ? plan?.curr_day : tday;

      const end_date = await working(start_date, total_days);

      var date_work;
      if (user?.info?.work_graph === "1") {
        date_work = await working(start_date, total_days + 1);
      } else if (user?.info?.work_graph === "2") {
        date_work = dayjs(end_date).add(1, "day");
      } else {
        message("warning", "Анхааруулга", "Ажилтны график байхгүй байна");
      }

      setState((old) => ({
        ...old,
        loading: false,
        plan_id: plan_id,
        duration_days: total_days,
        begin_date: plan?.begin_date || null,
        start_date: dayjs(start_date) || null,
        end_date: dayjs(end_date) || null,
        date_work: dayjs(date_work),
        fee: calc?.amount || null,
        day_abnormal_str: calc?.day_abnormal_str || null,
        day_emc_str: calc?.day_emc_str || null,
        day_normal_str: calc?.day_normal_str || null,
        day_sum_str: calc?.day_sum_str || null,
        work_begin: calc?.work_begin || null,
        work_end: calc?.work_end || null,
      }));
    }
  };

  const clearState = () => {
    setState((old) => ({
      ...old,
      open: false,
      loading: false,
      submit: false,
      id: null,
      begin_date: null,
      start_date: null,
      location: null,
      end_date: null,
      date_work: null,
      duration_days: "",
      name: "",
      type_id: null,
      plan_id: null,
      status: null,
      substitute_employee_id: null,
      fee: null,
      day_abnormal_str: null,
      day_emc_str: null,
      day_normal_str: null,
      day_sum_str: null,
      work_begin: null,
      work_end: null,
      attachments: [],
      reviews: [],
    }));
  };

  const get_reviews = async (id) => {
    var res = await API.GET("/tier/review", {
      res_id: id,
      model: "new.hr.leave",
    });
    var result = [];
    _.map(res?.response, (item) => {
      return result.push({
        ...item,
        done_by_id: item?.done_by[0],
        done_by: item?.done_by[1],
      });
    });
    return result;
  };

  const save = async () => {
    setState((old) => ({ ...old, submit: true }));
    if (!state?.type_id) {
      message("warning", "Анхааруулга", "Төрөл сонгоно уу.");
      return false;
    }
    if (state?.type_id === 891 && !state?.plan_id) {
      message("warning", "Анхааруулга", "Төлөвлөгөө сонгоно уу.");
      return false;
    }
    if (state?.type_id === 891 && !state?.substitute_employee_id) {
      message("warning", "Анхааруулга", "Орлох ажилтан сонгоно уу.");
      return false;
    }
    if (![620, 891].includes(state?.type_id) && !state?.attachments) {
      message("warning", "Анхааруулга", "Файл сонгоно уу.");
      return false;
    }
    if (!state?.start_date && !state?.end_date) {
      message("warning", "Анхааруулга", "Огноо сонгоно уу.");
      return false;
    }

    var params = {
      type_id: state?.type_id || null,
      plan_id: state?.plan_id || null,
      sub_id: state?.substitute_employee_id || null,
      start_date: dayjs(state?.start_date).format("YYYY-MM-DD") || null,
      end_date: dayjs(state?.end_date).format("YYYY-MM-DD") || null,
      work_date: dayjs(state?.date_work).format("YYYY-MM-DD") || null,
      location: state?.location || null,
      amount: _.toString(state?.fee) || null,
      day_abnormal: state?.day_abnormal_str || null,
      day_emc: state?.day_emc_str || null,
      day_normal: state?.day_normal_str || null,
      day_sum: state?.day_sum_str || null,
      duration_days: _.toString(state?.duration_days) || null,
      employee_id: user?.info?.id || null,
      work_begin: state?.work_begin
        ? dayjs(state?.work_begin).format("YYYY-MM-DD")
        : null,
      name: state?.name || null,
    };

    var res;
    var id;
    if (state?.id) {
      res = await API.PUT("/employee/leave/" + state?.id, params);
      id = state?.id;
    } else {
      res = await API.POST("/employee/leave", params);
      id = res?.id;
    }

    if (res?.status_code && id) {
      await API.POST(`/employee/leave/${id}/state`, {
        state: "hr_manager",
      });
      var new_files = _.filter(state?.attachments, (item) => !item?.id);
      if (id && new_files?.length > 0) {
        res = await UploadFile(id, new_files);
      }
      if (res?.status_code) {
        await fetch_list();
        clearState();
        message("success", "Амралт чөлөө", res?.response);
      } else {
        message("error", "Алдаа", res?.response);
      }
    } else {
      message("warning", "Анхааруулга", res?.response);
    }
  };

  const delete_leave = async (id) => {
    setState((old) => ({
      ...old,
      loading: true,
    }));
    const res = await API.DELETE("/employee/leave/" + id);
    if (res?.status_code) {
      await fetch_list();
      setState((old) => ({
        ...old,
        loading: false,
      }));
      message("success", "Амралт чөлөө", res?.response);
    } else {
      message("error", "Алдаа", res?.response);
    }
  };

  // const delete_reviews = async (id) => {
  //   setState((old) => ({
  //     ...old,
  //     loading: true,
  //   }));
  //   const res = await API.DELETE("/employee/leave/approvals/" + id);
  //   if (res?.status_code) {
  //     var approvals = await API.GET("/employee/leave/approvals", {
  //       leave_id: id,
  //     });
  //     if (approvals?.status_code) {
  //       var result = [];
  //       _.map(approvals?.response, (el) => {
  //         return result.push({
  //           ...el,
  //           done_by: el?.employee_id[1],
  //           reviewed_date: el?.create_date,
  //           gadot: 1,
  //         });
  //       });
  //       setState((old) => ({
  //         ...old,
  //         reviews: result,
  //         loading: false,
  //       }));
  //     }

  //     message("success", "Амралт чөлөө", res?.response);
  //   } else {
  //     message("error", "Алдаа", res?.response);
  //   }
  // };

  const day_and_work_date = async () => {
    if (state?.start_date && state?.end_date && state?.type_id !== 891) {
      setState((old) => ({
        ...old,
        loading: true,
      }));

      var days = 0;
      if (user?.info?.work_graph === "1") {
        days = await vacation(state?.start_date, state?.end_date);
      } else {
        days = await vacation_shift(state?.start_date, state?.end_date);
      }

      var date_work;
      if (user?.info?.work_graph === "1") {
        date_work = await working(state?.start_date, days + 1);
      } else if (user?.info?.work_graph === "2") {
        date_work = dayjs(state?.end_date).add(1, "day");
      } else {
        message("warning", "Анхааруулга", "Ажилтны график байхгүй байна");
      }

      setState((old) => ({
        ...old,
        duration_days: days,
        date_work: dayjs(date_work),
        loading: false,
      }));
    }
  };

  useEffect(() => {
    day_and_work_date();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.start_date, state?.end_date, state?.type_id]);

  const UploadFile = async (id, new_files) => {
    const formData = new FormData();
    formData.append("res_id", parseInt(id));
    formData.append("model_name", "new.hr.leave");
    _.map(new_files, (item) => {
      formData.append("files", item?.originFileObj);
    });
    formData.append("field_name", "attachment");
    formData.append("upload_type", "many2many");
    const res = await API.UPLOAD_FILE("/file/upload", formData);
    return res;
  };

  const getView = async (record) => {
    var reviews = await get_reviews(record?.id);

    setState((old) => ({
      ...old,
      id: record?.id || null,
      fee: record?.amount || 0,
      type_id: record?.type_id || null,
      plan_id: record?.plan_id ? record?.plan_id : null,
      status: record?.state || null,
      substitute_employee_id: record?.substitute_employee
        ? record?.substitute_employee[0]
        : null,
      begin_date: dayjs(record?.begin_date).format("YYYY-MM-DD") || "",
      start_date: dayjs(record?.start_date) || null,
      location: record?.location || null,
      end_date: dayjs(record?.end_date) || null,
      date_work: dayjs(record?.work_date) || null,
      work_begin: dayjs(record?.work_begin).format("YYYY-MM-DD") || "",
      work_end: dayjs(record?.work_end).format("YYYY-MM-DD") || "",
      duration_days: record?.number_of_days || 0,
      name: record?.description || "",
      day_abnormal_str: record?.abnormal_day || "",
      day_emc_str: record?.current_day || "",
      day_normal_str: record?.normal_day || "",
      day_sum_str: record?.sum_day || "",
      attachments: record?.attachment || null,
      reviews: reviews || [],
      open: true,
    }));
  };

  const calc_location = async () => {
    if (state?.start_date && state?.location && state?.type_id) {
      var find_data = _.find(state?.types, (el) => el.id === state?.type_id);
      var end_date = null;
      if (find_data) {
        if (state?.location === "current") {
          end_date = await working(state?.start_date, find_data?.days_current);
        } else if (state?.location === "other") {
          end_date = await working(state?.start_date, find_data?.days_other);
        } else if (state?.location === "abroad") {
          end_date = await working(state?.start_date, find_data?.days_abroad);
        }
      }
      if (end_date) {
        setState((old) => ({
          ...old,
          end_date: dayjs(end_date),
        }));
      }
    }
  };

  useEffect(() => {
    calc_location();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.start_date, state?.location]);

  return (
    <div className="card">
      <div className="card-body">
        <Spin spinning={state?.loading}>
          <CreateButton
            onClick={() => {
              setState((old) => ({
                ...old,
                open: true,
                loading: false,
                submit: false,
                id: null,
                begin_date: null,
                start_date: null,
                location: null,
                end_date: null,
                date_work: null,
                duration_days: "",
                name: "",
                type_id: null,
                plan_id: null,
                status: "draft",
                substitute_employee_id: null,
                fee: null,
                day_abnormal_str: null,
                day_emc_str: null,
                day_normal_str: null,
                day_sum_str: null,
                work_begin: null,
                work_end: null,
                attachments: [],
                reviews: [],
              }));
            }}
            name="Хүсэлт үүсгэх"
            className="mb-2"
          />

          <Table
            size="small"
            bordered
            pagination={false}
            dataSource={state?.leaves}
            rowKey="id"
            className="w-[600px] overflow-auto sm:w-full"
          >
            <Table.Column
              title="№"
              width={50}
              align="center"
              render={(data, record, index) => index + 1}
            />
            <Table.Column
              title="Төрөл"
              align="center"
              key="type_name"
              dataIndex="type_name"
              className="cursor-pointer"
            />
            <Table.Column
              title="Эхлэх огноо"
              align="center"
              key="start_date"
              dataIndex="start_date"
              className="cursor-pointer"
            />
            <Table.Column
              title="Дуусах огноо"
              align="center"
              key="end_date"
              dataIndex="end_date"
              className="cursor-pointer"
            />
            <Table.Column
              title="Ажилд орох огноо"
              align="center"
              key="work_date"
              dataIndex="work_date"
              className="cursor-pointer"
            />
            <Table.Column
              title="Үргэлжлэх хугацаа"
              align="center"
              key="number_of_days"
              dataIndex="number_of_days"
              className="cursor-pointer"
            />
            <Table.Column
              title="Төлөв"
              align="center"
              key="state"
              dataIndex="state"
              className="cursor-pointer"
              render={(item) => {
                if (["draft"].includes(item)) {
                  return <Tag color="gray">Ноорог</Tag>;
                } else if (["hr_manager", "hr_approved"].includes(item)) {
                  return <Tag color="orange">ХНМ Хүлээгдэж байна</Tag>;
                } else if (["pending"].includes(item)) {
                  return <Tag color="orange">Хүлээгдэж байна.</Tag>;
                } else if (["economist"].includes(item)) {
                  return <Tag color="orange">Эдийн засагч</Tag>;
                } else if (["accountant"].includes(item)) {
                  return <Tag color="orange">Нягтлан</Tag>;
                } else if (["approved"].includes(item)) {
                  return <Tag color="green">Батлагдсан</Tag>;
                } else if (["refuse"].includes(item)) {
                  return <Tag color="red">Татгалзсан</Tag>;
                }
              }}
            />
            <Table.Column
              title="#"
              align="center"
              rowKey="id"
              dataIndex="id"
              render={(_, record) => {
                if (["draft"].includes(record?.state)) {
                  return (
                    <Space size="middle">
                      <EditButton
                        onClick={async () => {
                          const data = await calculate(record?.start_date);
                          await getView({
                            ...record,
                            fee: data?.amount || 0,
                            day_abnormal_str: data?.day_abnormal_str || "",
                            day_emc_str: data?.day_emc_str || "",
                            day_normal_str: data?.day_normal_str || "",
                            day_sum_str: data?.day_sum_str || "",
                            work_begin:
                              dayjs(record?.work_begin).format("YYYY-MM-DD") ||
                              "",
                            work_end:
                              dayjs(record?.work_end).format("YYYY-MM-DD") ||
                              "",
                          });
                        }}
                      />

                      <DeleteButton
                        onClick={() => {
                          delete_leave(record?.id);
                        }}
                      />
                      <SaveButton
                        onClick={async () => {
                          if (record?.id) {
                            setState((old) => ({
                              ...old,
                              loading: true,
                            }));

                            var res = await API.POST(
                              `/employee/leave/${record?.id}/state`,
                              {
                                state: "hr_manager",
                              }
                            );
                            if (res?.status_code) {
                              await fetch_list();
                              message("success", "Амжилттай", res?.response);
                            } else {
                              message("warning", "Алдаа", res?.response);
                            }
                            setState((old) => ({
                              ...old,
                              loading: false,
                              open: false,
                            }));
                          }
                        }}
                        name="ХНМ Илгээх"
                      />
                    </Space>
                  );
                } else if (["hr_manager"].includes(record?.state)) {
                  return (
                    <div className="flex items-center justify-center gap-2">
                      <div
                        className="border border-gray-700 text-gray-700 px-2 py-1 rounded cursor-pointer duration-300 hover:bg-gray-700 hover:text-white"
                        onClick={async () => await getView(record)}
                      >
                        Харах
                      </div>
                      <div
                        className="bg-gray-700 text-white px-2 py-1 rounded cursor-pointer"
                        onClick={async () => {
                          if (record?.id) {
                            setState((old) => ({
                              ...old,
                              loading: true,
                            }));
                            await API.POST(
                              `/employee/leave/${record?.id}/state`,
                              {
                                state: "draft",
                              }
                            );
                            await fetch_list();
                            setState((old) => ({
                              ...old,
                              loading: false,
                            }));
                          }
                        }}
                      >
                        Буцаах
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="border border-gray-700 text-gray-700 px-2 py-1 rounded cursor-pointer duration-300 hover:bg-gray-700 hover:text-white"
                      onClick={async () => await getView(record)}
                    >
                      Харах
                    </div>
                  );
                }
              }}
            />
          </Table>
        </Spin>
      </div>
      <Modal
        title="Амралт чөлөө"
        centered
        open={state?.open}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setState((old) => ({
            ...old,
            open: false,
          }));
        }}
        width={1000}
      >
        <div className="card-body">
          <Spin spinning={state?.loading}>
            <div className="flex flex-col xl:flex-row gap-4">
              <div className="m-3 flex flex-col gap-4">
                <div className="flex flex-col md:flex-row md:items-center gap-1">
                  <span className="md:w-1/3">Чөлөөний төрөл</span>
                  <TreeSelect
                    showSearch
                    disabled={state?.status !== "draft"}
                    className="w-full md:w-60"
                    placeholder="Сонгох"
                    treeDefaultExpandAll={true}
                    treeDataSimpleMode={true}
                    treeData={state?.types}
                    treeLine={(true, { showLeafIcon: false })}
                    filterTreeNode={(search, item) =>
                      item.title.toLowerCase().indexOf(search.toLowerCase()) >=
                      0
                    }
                    status={state.submit && !state.type_id && "error"}
                    value={state?.type_id}
                    onChange={(value) => {
                      setState((old) => ({
                        ...old,
                        type_id: value,
                        start_date: null,
                        end_date: null,
                        date_work: null,
                        location: null,
                        duration_days: "",
                        name: "",
                        attachments: [],
                        plan_id: null,
                        substitute_employee_id: null,
                      }));
                    }}
                  />
                </div>
                {[
                  600, 601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 615,
                ].includes(parseInt(state?.type_id)) && (
                  <div className="flex flex-col md:flex-row md:items-center gap-1">
                    <span className="md:w-1/3">Газар сонгох</span>
                    <Select
                      showSearch
                      disabled={state?.status !== "draft"}
                      placeholder="Сонгох"
                      className="w-full md:w-60"
                      status={state.submit && !state?.location && "error"}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={state?.location}
                      onChange={async (value) => {
                        setState((old) => ({
                          ...old,
                          location: value,
                        }));
                      }}
                    >
                      {_.map(state?.location_list, (item) => (
                        <Select.Option key={item.id} value={item.value}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
                {state?.type_id === 891 && (
                  <div className="flex flex-col md:flex-row md:items-center gap-1">
                    <span className="md:w-1/3">Төлөвлөгөө сонгох</span>
                    <Select
                      showSearch
                      disabled={state?.status !== "draft"}
                      placeholder="Сонгох"
                      className="w-full md:w-60"
                      status={
                        state?.type_id === 891 &&
                        state.submit &&
                        !state?.plan_id &&
                        "error"
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={state?.plan_id}
                      onChange={async (value) => {
                        await plan_change(value);
                      }}
                    >
                      {_.map(state?.plans, (item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.display_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}
                {state?.type_id === 891 && (
                  <div className="flex flex-col md:flex-row md:items-center gap-1">
                    <span className="md:w-1/3">Орлох ажилтан</span>
                    <Select
                      showSearch
                      disabled={state?.status !== "draft"}
                      placeholder="Сонгох"
                      className="w-full md:w-60"
                      status={
                        state?.type_id === 891 &&
                        state.submit &&
                        !state?.substitute_employee_id &&
                        "error"
                      }
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      value={state?.substitute_employee_id}
                      onChange={(value) =>
                        setState((old) => ({
                          ...old,
                          substitute_employee_id: value,
                        }))
                      }
                    >
                      {_.map(state?.substitute_employees, (item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                )}

                <div className="flex flex-col md:flex-row md:items-center gap-1">
                  <span className="md:w-1/3">Эхлэх өдөр</span>
                  <DatePicker
                    disabledDate={(d) => {
                      if (state?.type_id === 891) {
                        var plan = _.find(state?.plans, {
                          id: state?.plan_id,
                        });

                        if (!d) return true;

                        const startDate = dayjs(plan?.current_leave_date);
                        if (!startDate) return true;

                        const minDate = startDate.clone().subtract(14, "days");
                        const maxDate = startDate.clone().add(14, "day");
                        return d.isBefore(minDate) || d.isAfter(maxDate);
                      } else {
                        if (!d) return true;
                        return d.isAfter(state?.end_date);
                      }
                    }}
                    disabled={state?.status !== "draft"}
                    className="w-full md:w-60"
                    status={state.submit && !state?.start_date && "error"}
                    value={state?.start_date}
                    onChange={async (value) => {
                      if (state?.type_id === 891) {
                        if (state?.plan_id) {
                          const calc = await calculate(value);
                          const end_date = await working(
                            value,
                            state?.duration_days
                          );

                          var date_work;
                          if (user?.info?.work_graph === "1") {
                            date_work = await working(
                              value,
                              state?.duration_days + 1
                            );
                          } else if (user?.info?.work_graph === "2") {
                            date_work = dayjs(end_date).add(1, "day");
                          } else {
                            message(
                              "warning",
                              "Анхааруулга",
                              "Ажилтны график байхгүй байна"
                            );
                          }
                          setState((old) => ({
                            ...old,
                            start_date: dayjs(value),
                            end_date: dayjs(end_date),
                            date_work: dayjs(date_work),
                            fee: calc?.amount || null,
                            day_abnormal_str: calc?.day_abnormal_str || null,
                            day_emc_str: calc?.day_emc_str || null,
                            day_normal_str: calc?.day_normal_str || null,
                            day_sum_str: calc?.day_sum_str || null,
                            work_begin: calc?.work_begin || null,
                            work_end: calc?.work_end || null,
                          }));
                        } else {
                          message(
                            "warning",
                            "Анхааруулга",
                            "Төлөвлөгөө сонгоно уу."
                          );
                        }
                      } else {
                        setState((old) => ({
                          ...old,
                          start_date: value ? dayjs(value) : null,
                        }));
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col md:flex-row md:items-center gap-1">
                  <span className="md:w-1/3">Дуусах өдөр</span>
                  <DatePicker
                    disabledDate={(d) => d.isBefore(state?.start_date)}
                    disabled={
                      [
                        891, 600, 601, 602, 603, 604, 605, 606, 607, 608, 609,
                        610, 615,
                      ].includes(parseInt(state?.type_id)) ||
                      state?.status !== "draft"
                    }
                    status={state.submit && !state?.end_date && "error"}
                    value={state?.end_date}
                    className="w-full md:w-60"
                    onChange={(value) =>
                      setState((old) => ({ ...old, end_date: value }))
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-row md:items-center gap-1">
                  <span className="md:w-1/3">Ажилд орох өдөр</span>
                  <DatePicker
                    disabled
                    className="w-full md:w-60"
                    value={state?.date_work}
                    onChange={(value) =>
                      setState((old) => ({ ...old, date_work: value }))
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-row md:items-center gap-1">
                  <span className="md:w-1/3">Үргэжлэх хугацаа</span>
                  <Input
                    disabled
                    value={state?.duration_days}
                    className="w-full md:w-60"
                    onChange={(e) =>
                      setState((old) => ({
                        ...old,
                        duration_days: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-row md:items-center gap-1">
                  <span className="md:w-1/3">Тайлбар</span>

                  <TextArea
                    disabled={state?.status !== "draft"}
                    placeholder="..."
                    className="w-full md:w-60"
                    autoSize={{
                      minRows: 2,
                      maxRows: 6,
                    }}
                    value={state?.name}
                    onChange={(e) =>
                      setState((old) => ({ ...old, name: e.target.value }))
                    }
                  />
                </div>

                {state?.type_id && state?.type_id !== 891 && (
                  <div className="flex flex-col md:flex-row md:items-center gap-1">
                    <span className="md:w-1/3">Хавсралт</span>

                    <div>
                      <Upload
                        disabled={state?.status !== "draft"}
                        beforeUpload={() => false}
                        accept=".pdf, .jpeg, .png, .jpg"
                        multiple={true}
                        fileList={state?.attachments}
                        onChange={({ file, fileList }) => {
                          setState((old) => ({
                            ...old,
                            attachments: fileList,
                          }));
                        }}
                        onPreview={async (file) => {
                          if (file.id) {
                            try {
                              const res = await API.GET_FILE(
                                `/file/upload/${file.id}`
                              );
                              const link = document.createElement("a");
                              link.href = res;
                              link.setAttribute("download", file?.name);
                              document.body.appendChild(link);
                              link.click();
                            } catch (error) {
                              message(
                                "error",
                                "Алдаа",
                                "Файл татах үед алдаа гарлаа."
                              );
                            }
                          }
                        }}
                        onRemove={async (file) => {
                          if (file.id) {
                            try {
                              await API.DELETE(`/file/upload/${file?.id}`);
                            } catch (error) {
                              message(
                                "error",
                                "Алдаа",
                                "Файл устгах үед алдаа гарлаа."
                              );
                            }
                          }
                        }}
                      >
                        <div className="flex items-center gap-2 flex-wrap">
                          <Button
                            className={
                              state?.submit &&
                              !state?.attachments &&
                              ![620, 891].includes(state?.type_id) &&
                              "border-red-500 text-red-500"
                            }
                            icon={<UploadOutlined />}
                          >
                            Файл оруулах
                          </Button>
                        </div>
                      </Upload>
                    </div>
                  </div>
                )}
                <Table
                  size="small"
                  bordered
                  pagination={false}
                  dataSource={state?.reviews}
                  rowKey="id"
                >
                  <Table.Column
                    title="№"
                    width={50}
                    align="center"
                    render={(data, record, index) => index + 1}
                  />
                  <Table.Column title="Үе шат" key="name" dataIndex="name" />
                  <Table.Column
                    title="Овог нэр"
                    key="todo_by"
                    dataIndex="todo_by"
                    render={(data, record, index) => {
                      return record.done_by ? record.done_by : record.todo_by;
                    }}
                  />
                  <Table.Column
                    title="Огноо"
                    key="reviewed_date"
                    dataIndex="reviewed_date"
                  />
                  <Table.Column
                    title="Тайлбар"
                    key="comment"
                    dataIndex="comment"
                  />
                  <Table.Column
                    title="Төлөв"
                    key="status"
                    dataIndex="status"
                    render={(data, record, index) => {
                      if (record?.can_review && record.status === "pending") {
                        return (
                          <Tag color="orange" className="font-semibold">
                            Хүлээгдэж байна
                          </Tag>
                        );
                      } else if (record.status === "revalidate") {
                        return (
                          <Tag color="orange" className="font-semibold">
                            Дахин батлах
                          </Tag>
                        );
                      } else if (record.status === "rejected") {
                        return (
                          <Tag color="red" className="font-semibold">
                            Буцаагдсан
                          </Tag>
                        );
                      } else if (record.status === "rejected") {
                        return (
                          <Tag color="green" className="font-semibold">
                            Баталсан
                          </Tag>
                        );
                      } else if (record.status === "revalidated") {
                        return (
                          <Tag color="green" className="font-semibold">
                            Дахин баталсан
                          </Tag>
                        );
                      }
                    }}
                  />
                </Table>
              </div>

              <div className="m-3 flex flex-col gap-4">
                {state?.type_id === 891 && (
                  <>
                    <div className="flex flex-col md:flex-row md:items-center gap-1">
                      <span className="md:w-1/3">Ажилд орсон огноо:</span>
                      <Input
                        disabled
                        value={state?.begin_date}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center gap-1">
                      <span className="md:w-1/3">Ажлын жил:</span>
                      <Input
                        disabled
                        value={state?.work_begin}
                        className="w-full md:w-[120px]"
                      />
                      <Input
                        disabled
                        value={state?.work_end}
                        className="w-full md:w-[120px]"
                      />
                    </div>

                    <div className="flex flex-col md:flex-row md:items-center gap-1">
                      <span className="md:w-1/3">Эмчилгээний мөнгө:</span>
                      <Input
                        disabled
                        value={state?.fee}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center gap-1">
                      <span className="md:w-1/3">Улсад ажилласан жил:</span>
                      <Input
                        disabled
                        value={state?.day_sum_str}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center gap-1">
                      <span className="md:w-1/3">Үйлдвэрт ажилласан жил:</span>
                      <Input
                        disabled
                        value={state?.day_emc_str}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center gap-1">
                      <span className="md:w-1/3">
                        Хэвийн нөхцөлд ажилласан жил:
                      </span>
                      <Input
                        disabled
                        value={state?.day_normal_str}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col md:flex-row md:items-center gap-1">
                      <span className="md:w-1/3">
                        Хэвийн бус нөхцөлд ажилласан жил:
                      </span>
                      <Input
                        disabled
                        value={state?.day_abnormal_str}
                        className="w-full md:w-60"
                      />
                    </div>
                    <span className="md:w-1/3">Өмнөх амралтын мэдээлэл</span>
                    <Table
                      size="small"
                      bordered
                      pagination={false}
                      dataSource={state?.prev_leaves}
                      rowKey="id"
                    >
                      <Table.Column
                        title="Өмнөх амралтын огноо"
                        key="start_date"
                        dataIndex="start_date"
                      />
                    </Table>
                  </>
                )}
              </div>
            </div>
            <div className="flex items-center justify-end gap-4">
              <CloseButton
                onClick={() => {
                  setState((old) => ({
                    ...old,
                    open: false,
                  }));
                }}
              />
              {state?.status === "draft" && (
                <SaveButton
                  name={"Илгээх"}
                  onClick={() => {
                    save();
                  }}
                />
              )}
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(Leave);
