import React, { useEffect, useState } from "react";
import * as API from "src/api/request";
import _ from "lodash";

import { Spin, Table } from "antd";

const Family = () => {
  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);

  useEffect(() => {
    set_loading(true);
    API.GET("employee/family")
      .then((res) => {
        set_list(_.sortBy(res.response, ["birthday"]));
      })
      .finally(() => set_loading(false));
  }, []);

  return (
    <Spin spinning={loading}>
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        dataSource={_.filter(list,{family_class: "1"})}
        className="w-[600px] overflow-auto sm:w-full"
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          dataIndex="id"
          render={(data, row, index) => index + 1}
        />
        <Table.Column
          title="Хэн болох"
          align="center"
          render={(data) => data.family_type && data.family_type[1]}
        />
        <Table.Column title="Овог" align="center" dataIndex="last_name" />
        <Table.Column title="Нэр" align="center" dataIndex="first_name" />
        <Table.Column
          title="Төрсөн огноо"
          align="center"
          dataIndex="birthday"
        />
        <Table.Column
          title="Одоо эрхэлж буй ажил"
          align="center"
          dataIndex="job"
        />
      </Table>
      <br/>
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        dataSource={_.filter(list,{family_class: "2"})}
        className="w-[600px] overflow-auto sm:w-full"
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          dataIndex="id"
          render={(data, row, index) => index + 1}
        />
        <Table.Column
          title="Хэн болох"
          align="center"
          render={(data) => data.family_type && data.family_type[1]}
        />
        <Table.Column title="Овог" align="center" dataIndex="last_name" />
        <Table.Column title="Нэр" align="center" dataIndex="first_name" />
        <Table.Column
          title="Төрсөн огноо"
          align="center"
          dataIndex="birthday"
        />
        <Table.Column
          title="Одоо эрхэлж буй ажил"
          align="center"
          dataIndex="job"
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Family);
