import React, { useState } from "react";
import { useUserContext } from "src/context/userContext";
// import Setting from "src/components/layout/setting";
import _ from "lodash";

const Header = (props) => {
  const { user } = useUserContext();

  // const [setting, setSetting] = useState(false);
  const [notif, setNotif] = useState(false);

  // const toggleSetting = (e) => {
  //   e.preventDefault();
  //   setSetting(!setting);
  // };

  return (
    <div className="main-header side-header">
      <div className="container-fluid">
        <div className="main-header-left ">
          <div
            className="app-sidebar__toggle mobile-toggle"
            data-toggle="sidebar"
          >
            <a
              className="open-toggle md:hidden"
              href="/"
              onClick={(e) => e.preventDefault()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                className="eva eva-menu-outline header-icons"
              >
                <g data-name="Layer 2">
                  <g data-name="menu">
                    <rect
                      width={24}
                      height={24}
                      transform="rotate(180 12 12)"
                      opacity={0}
                    />
                    <rect
                      x={3}
                      y={11}
                      width={18}
                      height={2}
                      rx=".95"
                      ry=".95"
                    />
                    <rect
                      x={3}
                      y={16}
                      width={18}
                      height={2}
                      rx=".95"
                      ry=".95"
                    />
                    <rect x={3} y={6} width={18} height={2} rx=".95" ry=".95" />
                  </g>
                </g>
              </svg>
            </a>

            <a
              className="close-toggle"
              href="/"
              onClick={(e) => e.preventDefault()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                className="eva eva-close-outline header-icons"
              >
                <g data-name="Layer 2">
                  <g data-name="close">
                    <rect
                      width={24}
                      height={24}
                      transform="rotate(180 12 12)"
                      opacity={0}
                    />
                    <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                  </g>
                </g>
              </svg>
            </a>
          </div>
        </div>

        <div className="main-header-right">
          <div
            className={`dropdown nav-item main-header-notification ${
              notif ? "show" : ""
            }`}
          >
            <a
              className="new nav-link"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setNotif(!notif);
              }}
            >
              <i className="fe fe-bell" />
              {user?.notification?.length > 0 && <span className="pulse" />}
            </a>
            <div className="dropdown-menu">
              <div className="menu-header-content bg-primary-gradient text-start d-flex">
                <div>
                  <h6 className="menu-header-title text-white mb-0">
                    {user?.notification?.length} Шинэ мэдэгдэл байна.
                  </h6>
                </div>
              </div>
              <div className="main-notification-list Notification-scroll ps">
                {_.map(
                  _.orderBy(user?.notification, ["date"], ["desc"]),
                  (el, index) => {
                    return (
                      <a
                        className="d-flex p-3 border-bottom flex items-center justify-between"
                        href="/"
                        key={"notif_" + index}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="flex justify-center items-center flex-1">
                          <div className="notifyimg bg-success-transparent w-10 h-10">
                            <i
                              className="far fa-envelope"
                              style={{ color: "rgb(74, 198, 201)" }}
                            />
                          </div>
                          <div className="ml-3 flex-1 overflow-hidden flex-nowrap">
                            <h5 className="notification-label mb-1">
                              {el.title}
                            </h5>
                            <div className="notification-subtext overflow-hidden flex-nowrap">
                              {el.body}
                            </div>
                            <div className="notification-subtext overflow-hidden flex-nowrap">
                              {el.date}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <i className="las la-angle-right text-end text-muted" />
                        </div>
                      </a>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          {/* <div className="nav nav-item  navbar-nav-right ml-auto">
            <div
              className={
                setting
                  ? "dropdown  nav-item main-header-message show"
                  : "dropdown  nav-item main-header-message"
              }
            >
              <a
                className="new nav-link"
                href="/"
                onClick={(e) => toggleSetting(e)}
              >
                <i className="fe fe-settings" />
              </a>
              <div className="dropdown-menu">
                <Setting />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Header);
