import React, { useEffect } from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import _ from "lodash";
import { IoIosArrowBack } from "react-icons/io";
import { Alert, Button, Input, Space, Spin } from "antd";
import { useUserContext } from "src/context/userContext";

const Step2 = ({ state, setState }) => {
  const { message } = useUserContext();
  useEffect(() => {
    var result = state?.organization;
    if (state?.search) {
      result = _.filter(result, (a) =>
        _.includes(
          _.toLower(_.toString(a.value)),
          _.toLower(_.toString(state?.search))
        )
      );
    }
    setState((old) => ({ ...old, organization_filter: result }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.organization, state?.search]);
  return (
    <Spin spinning={state?.loading}>
      <div className="card-body relative pt-0">
        <div className="pt-2 pb-2 cursor-pointer group sticky top-0 bg-white border-b">
          <div className="flex items-center">
            <div
              className="flex items-center text-xs sm:text-base"
              onClick={() => {
                setState((old) => ({ ...old, search: "", step: 1 }));
              }}
            >
              <IoIosArrowBack className="duration-300 group-hover:text-blue-800 h-6 w-6 sm:h-8 sm:w-8" />
              <div className="duration-300 group-hover:text-blue-800 group-hover:font-semibold">
                Буцах
              </div>
            </div>
            <div className="text-xs sm:text-lg w-full text-center">
              {state?.definition_type_name}
            </div>
          </div>
          <div className="w-full mt-2">
            <Alert
              message="Таны хайсан байгууллага байхгүй байвал доор бичих хэсэгт бичиж дараах товч дарж тодорхойлолтоо авна уу."
              type="warning"
            />
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <Input
                placeholder="Нэр бичих хэсэг"
                value={state?.search}
                onChange={(e) => {
                  setState((old) => ({ ...old, search: e.target.value }));
                }}
                className="py-2 sm:py-3"
              />
              <Button
                onClick={() => {
                  if (state?.search) {
                    setState((old) => ({
                      ...old,
                      step: 3,
                      organization_name: state?.search,
                    }));
                  } else {
                    message("warning", "Анхааруулга", "Нэр хоосон байна.");
                  }
                }}
                className="h-10 sm:h-12"
              >
                Дараах
              </Button>
            </Space.Compact>
          </div>
        </div>

        <div className="flex items-center justify-center flex-col gap-4 pt-2">
          {_.map(_.take(state?.organization_filter, 30), (el, index) => {
            return (
              <div
                key={"org_" + index}
                className="rounded-3xl border w-full flex items-center gap-2 px-4 py-2 cursor-pointer duration-300 hover:bg-gray-100 overflow-hidden"
                onClick={() => {
                  setState((old) => ({
                    ...old,
                    step: 3,
                    organization_id: el?.id,
                    organization_name: el?.value,
                  }));
                }}
              >
                <IoDocumentTextOutline
                  size={18}
                  className="min-h-[19px] min-w-[19px]"
                />
                <div>{el?.value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Spin>
  );
};

export default Step2;
