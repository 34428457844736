import React from 'react'
import DirenctPurchase from "src/pages/public/purchase/directPurchase";

function UserDirectPurchase() {
    return (
        <div className=" w-full h-screen dark:text-white/80 bg-white border p-4  bg-opacity-60  rounded-xl shadow-sm  dark:bg-slate-950 dark:border-slate-800">
            <DirenctPurchase />
        </div>
    )
}

export default UserDirectPurchase