import React, { useEffect, useState } from "react";
import * as API from "src/api/request";

import { Spin, Table } from "antd";

const Award = () => {
  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);

  useEffect(() => {
    set_loading(true);
    API.GET("employee/award")
      .then((res) => {
        set_list(res.response);
      })
      .finally(() => set_loading(false));
  }, []);

  return (
    <Spin spinning={loading}>
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        dataSource={list}
      >
        <Table.Column
          title="№"
          align="center"
          render={(data, row, index) => index + 1}
        />
        <Table.Column
          title="Шагнасан огноо"
          align="center"
          dataIndex="award_date"
        />
        <Table.Column
          title="Шагналын нэр"
          align="center"
          dataIndex="name"
          render={(data) => <div className="flex">{data}</div>}
        />
        <Table.Column
          title="Үнэмлэхний дугаар"
          align="center"
          dataIndex="award_no"
        />
        <Table.Column
          title="Төрөл"
          align="center"
          dataIndex="award_type"
          render={(data) => data && data[1]}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Award);
