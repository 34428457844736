import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";
import * as API from "src/api/request";

import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
} from "../../components/button";
import { Spin, Table, Modal, Select, Input, DatePicker } from "antd";
import _ from "lodash";
import dayjs from "dayjs";

var _form = {
  modal: false,
  submit: false,

  id: null,
  ssnid: null,
  family_class: "1",
  family_type: null,
  last_name: null,
  first_name: null,
  birthday: null,
  gender: null,
  city_id: null,
  district_id: null,
  job: null,
};

const Family = () => {
  const { message } = useUserContext();
  const { state } = useCVContext();
  const [loading, set_loading] = useState(false);
  const [reference_family_type, set_reference_family_type] = useState([]);
  const [reference_city_all, set_reference_city_all] = useState([]);
  const [reference_city, set_reference_city] = useState([]);
  const [reference_district, set_reference_district] = useState([]);

  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [form, setForm] = useState({
    ..._form,
  });

  const clearForm = () => {
    setForm({
      ...form,
      ..._form,
    });
  };

  useEffect(() => {
    API.GET("reference", {
      model_name: "emc.hr.family.type",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_family_type(res.response);
      } else message("error", res.response);
    });

    API.GET("reference", { model_name: "emc.hr.city" }).then((res) => {
      if (res.status_code === 1) {
        set_reference_city_all(res.response);
        set_reference_city(_.filter(res.response, (a) => a.level === 2));
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/family", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            setList(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, refresh]);

  useEffect(() => {
    var result = [];
    if (form.city_id) {
      var item = _.find(reference_city_all, (a) => a.id === form.city_id);
      if (item) {
        result = _.filter(
          reference_city_all,
          (a) => _.startsWith(a.code, item.code) && a.level === 4
        );
      }
    }
    set_reference_district(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference_city_all, form.city_id]);

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        width={1000}
        title={
          <div className="text-center border-b pb-2">
            {form.family_class === "1" ? "Гэр бүл" : "Садан төрөл"}
          </div>
        }
        open={form.modal}
        onCancel={() => clearForm()}
        footer={false}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Хэн болох: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={form.submit && !form.family_type && "error"}
              value={form.family_type}
              onChange={(value) => setForm({ ...form, family_type: value })}
            >
              {_.map(reference_family_type, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">Регистрийн дугаар:</span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              value={form.ssnid}
              onChange={(e) => setForm({ ...form, ssnid: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Эцэг (эх)-ийн нэр: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.last_name && "error"}
              value={form.last_name}
              onChange={(e) => setForm({ ...form, last_name: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Өөрийн нэр: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.first_name && "error"}
              value={form.first_name}
              onChange={(e) => setForm({ ...form, first_name: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Төрсөн огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              allowClear
              className="w-full"
              status={form.submit && !form.birthday && "error"}
              value={form.birthday}
              onChange={(value) => setForm({ ...form, birthday: value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Төрсөн аймаг, хот: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={form.submit && !form.city_id && "error"}
              value={form.city_id}
              onChange={(value) => {
                setForm({
                  ...form,
                  city_id: value,
                  district_id: null,
                });
              }}
            >
              {_.map(reference_city, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Төрсөн сум, дүүрэг: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={form.submit && !form.district_id && "error"}
              value={form.district_id}
              onChange={(value) => setForm({ ...form, district_id: value })}
            >
              {_.map(reference_district, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Одоо эрхэлж буй ажил: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.job && "error"}
              value={form.job}
              onChange={(e) => setForm({ ...form, job: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <SaveButton
            onClick={() => {
              setForm({ ...form, submit: true });

              var error = false;
              if (
                !form.family_type ||
                !form.last_name ||
                !form.first_name ||
                !form.birthday ||
                !form.city_id ||
                !form.district_id ||
                !form.job
              ) {
                error = true;
                message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
              }

              if (!error) {
                var data = {
                  cv_id: state.id,
                  ssnid: form.ssnid || null,
                  family_class: form.family_class || null,
                  family_type: form.family_type || null,
                  last_name: form.last_name || null,
                  first_name: form.first_name || null,
                  birthday: form.birthday
                    ? dayjs(form.birthday).format("YYYY-MM-DD")
                    : null,
                  gender: form.gender || null,
                  city_id: form.city_id || null,
                  district_id: form.district_id || null,
                  job: form.job || null,
                };
                if (!form.id) {
                  API.POST("cv/family", data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        setRefresh((prev) => prev + 1);
                        message("success", res.response);
                        clearForm();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Бүртгэж чадсангүй"));
                } else {
                  API.PUT("cv/family/" + form.id, data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        setRefresh((prev) => prev + 1);
                        message("success", res.response);
                        clearForm();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Засварлаж чадсангүй"));
                }
              }
            }}
          />
        </div>
      </Modal>

      <CreateButton
        className="mb-2"
        name="Гэр бүл нэмэх"
        onClick={() => setForm({ ...form, family_class: "1", modal: true })}
      />
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={_.filter(list, (a) => a.family_class[0] === "1")}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Хэн болох"
          align="center"
          dataIndex="family_type"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Эцэг (эх)-ийн нэр"
          align="center"
          dataIndex="last_name"
        />
        <Table.Column
          title="Өөрийн нэр"
          align="center"
          dataIndex="first_name"
        />
        <Table.Column
          title="Төрсөн огноо"
          align="center"
          dataIndex="birthday"
        />
        <Table.Column
          title="Төрсөн аймаг, хот"
          align="center"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Төрсөн сум, дүүрэг"
          align="center"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Одоо эрхэлж буй ажил"
          align="center"
          dataIndex="job"
        />
        <Table.Column
          title=""
          width={100}
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  setForm({
                    ...form,
                    ...record,
                    modal: true,
                    family_type: record.family_type
                      ? record.family_type[0]
                      : null,
                    birthday: record.birthday ? dayjs(record.birthday) : null,
                    city_id: record.city_id ? record.city_id[0] : null,
                    district_id: record.district_id
                      ? record.district_id[0]
                      : null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  API.DELETE("cv/family/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        setRefresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"));
                }}
              />
            </div>
          )}
        />
      </Table>

      <hr className="my-3" />

      <CreateButton
        className="mb-2"
        name="Садан төрөл нэмэх"
        onClick={() => setForm({ ...form, family_class: "2", modal: true })}
      />
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={_.filter(list, (a) => a.family_class[0] === "2")}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Хэн болох"
          align="center"
          dataIndex="family_type"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Эцэг (эх)-ийн нэр"
          align="center"
          dataIndex="last_name"
        />
        <Table.Column
          title="Өөрийн нэр"
          align="center"
          dataIndex="first_name"
        />
        <Table.Column
          title="Төрсөн огноо"
          align="center"
          dataIndex="birthday"
        />
        <Table.Column
          title="Төрсөн аймаг, хот"
          align="center"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Төрсөн сум, дүүрэг"
          align="center"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Одоо эрхэлж буй ажил"
          align="center"
          dataIndex="job"
        />
        <Table.Column
          title=""
          width={100}
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  setForm({
                    ...form,
                    ...record,
                    modal: true,
                    family_type: record.family_type
                      ? record.family_type[0]
                      : null,
                    birthday: dayjs(record.birthday),
                    city_id: record.city_id ? record.city_id[0] : null,
                    district_id: record.district_id
                      ? record.district_id[0]
                      : null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  API.DELETE("cv/family/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        setRefresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"));
                }}
              />
            </div>
          )}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Family);
