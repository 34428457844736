import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";
import * as API from "src/api/request";

import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
} from "../../components/button";
import { Spin, Table, Modal, Input, DatePicker, TreeSelect } from "antd";
import _ from "lodash";
import dayjs from "dayjs";

const _form = {
  modal: false,
  submit: false,

  id: null,
  department_name: null,
  job_name: null,
  state_id: null,
  begin_date: null,
  end_date: null,
};

const Job = () => {
  const { message } = useUserContext();
  const { state } = useCVContext();

  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);
  const [refresh, set_refresh] = useState(1);

  const [reference_state_type, set_reference_state_type] = useState([]);

  const [form, set_form] = useState({ ..._form });

  const clear_form = () => {
    set_form({ ...form, ..._form });
  };

  useEffect(() => {
    API.GET("reference", {
      model_name: "emc.hr.state.type",
    }).then((res) => {
      if (res.status_code === 1) {
        var result = _.map(res.response, (item) => ({
          ...item,
          title: item.display_name,
          key: item.id,
          value: item.id,
          pId: item.parent_id ? item.parent_id[0] : null,
        }));

        result = _.map(result, (item) => ({
          ...item,
          selectable: _.filter(result, { pId: item.id }).length === 0,
        }));
        set_reference_state_type(result);
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/job", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_list(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, refresh]);

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        width={1000}
        title={<div className="text-center border-b pb-2">Туршлага</div>}
        open={form.modal}
        onCancel={() => clear_form()}
        footer={false}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Байгууллага: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.department_name && "error"}
              value={form.department_name}
              onChange={(e) =>
                set_form({ ...form, department_name: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Албан тушаал: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.job_name && "error"}
              value={form.job_name}
              onChange={(e) => set_form({ ...form, job_name: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Хөдөлмөрийн нөхцөл: <b className="text-red-500">*</b>
            </span>
            <TreeSelect
              showSearch
              allowClear={true}
              className="w-full"
              placeholder="Сонгох"
              treeDefaultExpandAll={true}
              treeDataSimpleMode={true}
              treeData={reference_state_type}
              treeLine={(true, { showLeafIcon: false })}
              filterTreeNode={(search, item) =>
                item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
              }
              status={form.submit && !form.state_id && "error"}
              value={form.state_id}
              onChange={(value) => set_form({ ...form, state_id: value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Эхлэсэн огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              allowClear
              className="w-full"
              status={form.submit && !form.begin_date && "error"}
              value={form.begin_date}
              onChange={(value) =>
                set_form({ ...form, begin_date: dayjs(value) })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">Дууссан огноо:</span>
            <DatePicker
              allowClear
              className="w-full"
              value={form.end_date}
              onChange={(value) =>
                set_form({ ...form, end_date: dayjs(value) })
              }
            />
          </div>

          <hr className="my-2" />

          <SaveButton
            onClick={() => {
              set_form({ ...form, submit: true });

              var error = false;
              if (
                !form.department_name ||
                !form.job_name ||
                !form.state_id ||
                !form.begin_date
              ) {
                error = true;
                message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
              }

              if (!error) {
                var data = {
                  cv_id: state.id,
                  department_name: form.department_name || null,
                  job_name: form.job_name || null,
                  state_id: form.state_id || null,
                  begin_date: form.begin_date
                    ? form.begin_date.format("YYYY-MM-DD")
                    : null,
                  end_date: form.end_date
                    ? form.end_date.format("YYYY-MM-DD")
                    : null,
                };
                if (!form.id) {
                  set_loading(true);
                  API.POST("cv/job", data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        set_refresh((prev) => prev + 1);
                        message("success", res.response);
                        clear_form();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Бүртгэж чадсангүй"))
                    .finally(() => set_loading(false));
                } else {
                  set_loading(true);
                  API.PUT("cv/job/" + form.id, data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        set_refresh((prev) => prev + 1);
                        message("success", res.response);
                        clear_form();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Засварлаж чадсангүй"))
                    .finally(() => set_loading(false));
                }
              }
            }}
          />
        </div>
      </Modal>

      <CreateButton
        className="mb-2"
        name="Туршлага нэмэх"
        onClick={() => set_form({ ...form, modal: true })}
      />

      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={list}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Байгууллага"
          align="center"
          dataIndex="department_name"
        />
        <Table.Column
          title="Албан тушаал"
          align="center"
          dataIndex="job_name"
        />
        <Table.Column
          title="Хөдөлмөрийн нөхцөл"
          align="center"
          dataIndex="state_id"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Эхлэсэн огноо"
          align="center"
          dataIndex="begin_date"
        />
        <Table.Column
          title="Дууссан огноо"
          align="center"
          dataIndex="end_date"
        />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_form({
                    ...form,
                    ...record,
                    modal: true,
                    state_id: record.state_id ? record.state_id[0] : null,
                    begin_date: record.begin_date
                      ? dayjs(record.begin_date)
                      : null,
                    end_date: record.end_date ? dayjs(record.end_date) : null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  set_loading(true);
                  API.DELETE("cv/job/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_refresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"))
                    .finally(() => set_loading(false));
                }}
              />
            </div>
          )}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Job);
