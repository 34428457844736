import React, { useEffect, useState } from "react";
import * as API from "src/api/request";

import { Spin, Table } from "antd";

const Help = () => {
  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);

  useEffect(() => {
    set_loading(true);
    API.GET("employee/help")
      .then((res) => {
        set_list(res.response);
      })
      .finally(() => set_loading(false));
  }, []);

  return (
    <Spin spinning={loading}>
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        dataSource={list}
        className="w-[600px] overflow-auto sm:w-full"
      >
        <Table.Column
          title="№"
          align="center"
          render={(data, row, index) => index + 1}
        />

        <Table.Column
          title="Тусламжид хамрагдсан огноо"
          align="center"
          dataIndex="date"
        />
        <Table.Column title="Дүн" align="center" dataIndex="amount" />
        <Table.Column
          title="Төрөл"
          align="center"
          dataIndex="type_parent_id"
          render={(value, record) =>
            record.type_parent_id && record.type_parent_id[1]
          }
        />
        <Table.Column
          title="Төрөл"
          align="center"
          dataIndex="type_id"
          render={(value, record) => record.type_id && record.type_id[1]}
        />
        <Table.Column title="Тайлбар" align="center" dataIndex="description" />
      </Table>
    </Spin>
  );
};

export default React.memo(Help);
