import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";
import * as API from "src/api/request";

import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
} from "../../components/button";
import { Spin, Select, Table, Modal, Input, InputNumber } from "antd";
import _ from "lodash";

const _form = {
  modal: false,
  submit: false,

  id: null,
  military_no: null,
  military_year: null,
  military_type: null,
  description: null,
};

const Military = () => {
  const { message } = useUserContext();
  const { state } = useCVContext();

  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);
  const [refresh, set_refresh] = useState(1);

  const [reference_military_type, set_reference_military_type] = useState([]);

  const [form, set_form] = useState({ ..._form });

  const clear_form = () => {
    set_form({ ...form, ..._form });
  };

  useEffect(() => {
    API.GET("reference/selection", {
      model_name: "emc.cv.military",
      field_name: "military_type",
    }).then((res) => {
      if (res.status_code === 1) set_reference_military_type(res.response);
      else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/military", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_list(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, refresh]);

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        width={1000}
        title={<div className="text-center border-b pb-2">Цэргийн алба</div>}
        open={form.modal}
        onCancel={() => clear_form()}
        footer={false}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Үнэмлэхний дугаар: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.military_no && "error"}
              value={form.military_no}
              onChange={(e) =>
                set_form({ ...form, military_no: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Он: <b className="text-red-500">*</b>
            </span>
            <InputNumber
              className="w-full"
              status={form.submit && !form.military_year && "error"}
              value={form.military_year}
              onChange={(value) => set_form({ ...form, military_year: value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Хаасан төрөл: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={form.submit && !form.military_type && "error"}
              value={form.military_type}
              onChange={(value) => set_form({ ...form, military_type: value })}
            >
              {_.map(reference_military_type, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">Тайлбар:</span>
            <Input.TextArea
              allowClear
              size="large"
              className="text-xs"
              value={form.description}
              onChange={(e) =>
                set_form({ ...form, description: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <SaveButton
            onClick={() => {
              set_form({ ...form, submit: true });

              var error = false;
              if (
                !form.military_no ||
                !form.military_year ||
                !form.military_type
              ) {
                error = true;
                message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
              }

              if (!error) {
                var data = {
                  cv_id: state.id,
                  military_no: form.military_no || null,
                  military_year: form.military_year || null,
                  military_type: form.military_type || null,
                  description: form.description || null,
                };
                if (!form.id) {
                  set_loading(true);
                  API.POST("cv/military", data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        set_refresh((prev) => prev + 1);
                        message("success", res.response);
                        clear_form();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Бүртгэж чадсангүй"))
                    .finally(() => set_loading(false));
                } else {
                  set_loading(true);
                  API.PUT("cv/military/" + form.id, data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        set_refresh((prev) => prev + 1);
                        message("success", res.response);
                        clear_form();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Засварлаж чадсангүй"))
                    .finally(() => set_loading(false));
                }
              }
            }}
          />
        </div>
      </Modal>

      <CreateButton
        className="mb-2"
        name="Цэргийн алба нэмэх"
        onClick={() => set_form({ ...form, modal: true })}
      />

      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={list}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Үнэмлэхний дугаар"
          align="center"
          dataIndex="military_no"
        />
        <Table.Column title="Он" align="center" dataIndex="military_year" />
        <Table.Column
          title="Хаасан төрөл"
          align="center"
          dataIndex="military_type_name"
        />
        <Table.Column title="Тайлбар" align="center" dataIndex="description" />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_form({
                    ...form,
                    ...record,
                    modal: true,
                    description: record.description || null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  set_loading(true);
                  API.DELETE("cv/military/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_refresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"))
                    .finally(() => set_loading(false));
                }}
              />
            </div>
          )}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Military);
