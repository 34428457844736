/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button } from 'antd';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { ShrinkOutlined } from '@ant-design/icons';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

export default function PDFViewer({ url, startPage, endPage }) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

    const screen1 = useFullScreenHandle();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [pages, setPages] = useState([]);
    const [rotationAngle, setRotationAngle] = useState(0);

    function onDocumentLoadSuccess({ numPages }) {
        setPageNumber(0)
        const url_array = url.split("page=");
        if (url_array.length > 1) {
            setPageNumber(parseInt(url_array[1]));
        } else {
            setNumPages(numPages);
        }
    }

    const customRenderPage = (props) => {
        return (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                Watermark Text
                <Page
                    {...props}
                    renderTextLayer={false} // Disable the text layer
                    renderAnnotationLayer={false} // Disable the annotation layer
                />

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pointerEvents: 'none',  // Ensure the watermark doesn't block interactions
                        zIndex: 10,
                        color: 'rgba(255, 0, 0, 0.3)',  // Semi-transparent red watermark
                        fontSize: '4rem',
                        fontWeight: 'bold',
                        transform: 'rotate(-45deg)',  // Rotate the watermark text
                        textAlign: 'center',
                    }}
                >
                    Watermark Text
                </div>
            </div>
        );
    };


    const reportChange = useCallback((state, handle) => {
        if (handle === screen1) {
            console.log('Screen 1 went to', state, handle);
        }
    }, [screen1]);

    useEffect(() => {
        const page_array = [];
        for (let i = 1; i <= numPages; i++) {
            page_array.push(
                <Page key={i} pageNumber={i}
                    renderFunction={customRenderPage}><div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pointerEvents: 'none',  
                            zIndex: 10,
                            color: 'rgba(33, 13, 85, 0.3)',  
                            fontSize: '4rem',
                            fontWeight: 'bold',
                            transform: 'rotate(-45deg)',  
                            textAlign: 'center',
                        }}
                    >
                        <img src="/public/logo.png" alt="Girl in a jacket" />
                    </div></Page>
            );
        }
        setPages(page_array);
    }, [pageNumber, numPages, url]);

    return (
        <>
            <div style={{ height: '100%', width: '100%', overflowY: "hidden" }}>
                <Button type='dashed' icon={<ShrinkOutlined />} onClick={screen1.enter} />
                <div style={{ height: '100%', width: '100%', overflowY: 'auto', display: 'flex', justifyContent: 'center' }}>
                    <FullScreen style={{ overflowY: 'auto' }} handle={screen1} onChange={reportChange}>
                        <div style={{ height: '100%', width: '100%', overflowY: 'auto', display: 'flex', justifyContent: 'center' }}>
                            <Document style={{ width: '100%', height: '100%', overflowY: 'auto', }} rotate={rotationAngle} file={url} onLoadSuccess={onDocumentLoadSuccess} >
                                {pages}
                            </Document>
                        </div>
                    </FullScreen>
                </div>
            </div>
        </>
    );
};