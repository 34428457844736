import React from "react";
import { useUserContext } from "src/context/userContext";

const Info = () => {
  const { user } = useUserContext();

  return (
    <div className="col-lg-4">
      <div className="card">
        <div className="card-header font-semibold uppercase">
          Хувийн мэдээлэл
        </div>
        <div className="card-body">
          <table className="table table-hover">
            <tbody>
              <tr>
                <td className="p-2">Регистерийн дугаар:</td>
                <td className="p-2">{user?.info?.ssnid}</td>
              </tr>
              <tr>
                <td className="p-2">Ургийн овог:</td>
                <td className="p-2">{user?.info?.family_name}</td>
              </tr>
              <tr>
                <td className="p-2">Эцэг (эх)-ийн нэр:</td>
                <td className="p-2">{user?.info?.last_name}</td>
              </tr>
              <tr>
                <td className="p-2">Өөрийн нэр:</td>
                <td className="p-2">{user?.info?.first_name}</td>
              </tr>
              <tr>
                <td className="p-2">Төрсөн он, сар, өдөр:</td>
                <td className="p-2">{user?.info?.birthday}</td>
              </tr>
              <tr>
                <td className="p-2">Хүйс:</td>
                <td className="p-2">{user?.info?.gender}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="card">
        <div className="card-header font-semibold uppercase">
          Иргэний харьяалал
        </div>
        <div className="card-body">
          <table className="table table-hover">
            <tbody>
              <tr>
                <td className="p-2">Иргэншил:</td>
                <td className="p-2">
                  {user?.info?.country_id && user?.info?.country_id[1]}
                </td>
              </tr>
              <tr>
                <td className="p-2">Үндэс, угсаа:</td>
                <td className="p-2">
                  {user?.info?.nationality_id && user?.info?.nationality_id[1]}
                </td>
              </tr>
              <tr>
                <td className="p-2">Төрсөн аймаг, хот, улс:</td>
                <td className="p-2">
                  {user?.info?.birth_city_id && user?.info?.birth_city_id[1]}
                </td>
              </tr>
              {user?.info?.gender === "male" ? (
                <tr>
                  <td className="p-2">Цэргийн алба хаасан эсэх:</td>

                  <td className="p-2">{user?.info?.military}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      <div className="card">
        <div className="card-header font-semibold uppercase">Холбоо барих</div>
        <div className="card-body">
          <table className="table table-hover">
            <tbody>
              <tr>
                <td className="p-2">Гар утас:</td>
                <td className="p-2">{user?.info?.private_phone}</td>
              </tr>
              <tr>
                <td className="p-2">Тоон гарын үсгийн дугаар:</td>
                <td className="p-2">{user?.info?.cert_mobile}</td>
              </tr>
              <tr>
                <td className="p-2">Ажлын утас:</td>
                <td className="p-2">{user?.info?.work_phone}</td>
              </tr>
              <tr>
                <td className="p-2">И-мэйл EMC:</td>
                <td className="p-2">{user?.info?.work_email}</td>
              </tr>
              <tr>
                <td className="p-2">И-мэйл:</td>
                <td className="p-2">{user?.info?.private_email}</td>
              </tr>
              <tr>
                <td className="p-2">Гэрийн хаяг:</td>
                <td className="p-2">{user?.info?.home_address}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Info);
