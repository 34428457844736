import { Select, Table, Spin, Modal, Tag } from "antd";
import React, { useEffect, useState } from "react";
import * as API from "src/api/request";
import { useUserContext } from "src/context/userContext";
import _ from "lodash";
import { AiOutlineQrcode } from "react-icons/ai";

import { CreateButton, SaveButton, CloseButton } from "../../components/button";

const Card = () => {
  const { message } = useUserContext();
  const [state, setState] = useState({
    refresh: 0,
    open: false,
    loading: false,
    id: null,
    qpay_invoice_id: null,
    qr: null,
    qPay_shortUrl: null,
    card_reason: null,
    card_reasons: [],
    cards: [],
  });

  const fetch_data = async () => {
    try {
      setState((old) => ({
        ...old,
        loading: true,
      }));

      var result_cards = [];
      const cards = await API.GET("/card");
      _.map(cards?.response, (item) => {
        return result_cards.push({
          ...item,
          employee_id: item?.employee_id[0],
          employee_name: item?.employee_id[1],
        });
      });

      const reasons = await API.GET("/reference/selection", {
        model_name: "emc.hr.card",
        field_name: "card_reason",
      });

      var result_reasons = _.map(
        _.filter(reasons?.response, (i) => ["2", "3", "4"].includes(i.value)),
        (item) => ({
          id: item.value,
          name: item.name,
        })
      );

      setState((old) => ({
        ...old,
        cards: result_cards,
        card_reasons: result_reasons,
        loading: false,
      }));
    } catch (error) {
      setState((old) => ({
        ...old,
        loading: false,
      }));
      message("error", "Алдаа", error?.message);
    }
  };

  useEffect(() => {
    fetch_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.refresh]);

  const save = async () => {
    try {
      if (state?.card_reason) {
        setState((old) => ({
          ...old,
          loading: true,
        }));
        const data = await API.POST("/card", {
          work_card_type: "1",
          card_reason: state?.card_reason,
        });
        if (data?.status_code) {
          if (state?.card_reason === "4") {
            message("success", "Амжилттай", data?.response);
            setState((old) => ({
              ...old,
              refresh: old?.refresh + 1,
              card_reason: null,
              loading: false,
              open: false,
            }));
          } else {
            const res = await API.GET(`/card/${data?.id}`);
            if (res?.status_code) {
              setState((old) => ({
                ...old,
                id: res?.response?.id,
                qr: res?.response?.payment_qr,
                qpay_invoice_id: res?.response?.qpay_invoice_id,
                qPay_shortUrl: res?.response?.qPay_shortUrl,
                refresh: old?.refresh + 1,
                card_reason: null,
                loading: false,
              }));
            }
          }
        } else {
          setState((old) => ({
            ...old,
            open: false,
            loading: false,
          }));
          message("error", "Алдаа", data?.response);
        }
      } else {
        message("error", "Алдаа", "Шалтгаан сонгоно уу!");
      }
    } catch (error) {
      setState((old) => ({
        ...old,
        open: false,
        loading: false,
      }));
      message("error", "Алдаа", error?.message);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <Spin spinning={state?.loading}>
          {state?.cards?.length > 0 && (
            <CreateButton
              onClick={() => {
                setState((old) => ({
                  ...old,
                  open: true,
                  loading: false,
                  qr: null,
                  id: null,
                  qPay_shortUrl: null,
                  qpay_invoice_id: null,
                  card_reason: null,
                }));
              }}
              name="Карт захиалах"
              className="mb-2"
            />
          )}

          <Table
            size="small"
            bordered
            pagination={false}
            dataSource={state?.cards}
            rowKey={"id"}
            className="w-[600px] overflow-auto sm:w-full"
          >
            <Table.Column
              title="№"
              width={50}
              align="center"
              render={(data, record, index) => index + 1}
            />
            <Table.Column
              title="Огноо"
              align="center"
              key="create_date"
              dataIndex="create_date"
            />
            <Table.Column
              title="Нэр"
              align="center"
              key="employee_name"
              dataIndex="employee_name"
            />
            <Table.Column
              title="Албан тушаал"
              align="center"
              key="worker_job"
              dataIndex="worker_job"
            />
            <Table.Column
              title="Төлөв"
              align="center"
              key="card_status"
              dataIndex="card_status"
              render={(item) => {
                if (item === "1") {
                  return <Tag color="green">Идэвхтэй</Tag>;
                } else if (item === "2") {
                  return <Tag color="gray">Карт гэмтсэн</Tag>;
                } else if (item === "3") {
                  return <Tag color="gray">Карт хаясан</Tag>;
                } else if (item === "4") {
                  return <Tag color="gray">Албан тушаал өөрчлөгдсөн</Tag>;
                } else if (item === "5") {
                  return <Tag color="gray">Хаагдсан</Tag>;
                } else if (item === "6") {
                  return <Tag color="gray">Халагдсан</Tag>;
                }
              }}
            />
            <Table.Column
              title="Төлөв"
              align="center"
              key="state"
              dataIndex="state"
              render={(item) => {
                if (["draft"].includes(item)) {
                  return <Tag color="gray">Ноорог</Tag>;
                } else if (["payment_pending"].includes(item)) {
                  return <Tag color="orange">Төлбөр хүлээгдэж буй</Tag>;
                } else if (["pending"].includes(item)) {
                  return <Tag color="orange">Хүлээгдэж буй</Tag>;
                } else if (["printed"].includes(item)) {
                  return <Tag color="green">Хэвлэгдсэн</Tag>;
                }
              }}
            />
            <Table.Column
              title="Qr"
              align="center"
              key="id"
              dataIndex="id"
              render={(id, row) => {
                if (row.state === "payment_pending") {
                  return (
                    <AiOutlineQrcode
                      className="w-6 h-6 cursor-pointer"
                      onClick={() => {
                        setState((old) => ({
                          ...old,
                          id: row?.id,
                          qr: row?.payment_qr,
                          qpay_invoice_id: row?.qpay_invoice_id,
                          qPay_shortUrl: row?.qPay_shortUrl,
                          card_reason: null,
                          open: true,
                        }));
                      }}
                    />
                  );
                }
              }}
            />
          </Table>
        </Spin>
      </div>
      <Modal
        title="Карт захиалах"
        centered
        open={state?.open}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setState((old) => ({
            ...old,
            open: false,
          }));
        }}
      >
        <Spin spinning={state?.loading}>
          {state?.id && state.qr && (
            <>
              <img
                width="500"
                height="200"
                alt=""
                src={`data:image/png;base64,${state.qr}`}
              />
              <div
                className="flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md mx-4 cursor-pointer hover:bg-green-900"
                onClick={() => {
                  if (state?.qPay_shortUrl) {
                    window.open(state?.qPay_shortUrl, "_blank");
                  } else {
                    message("error", "Алдаа", "QPay төлөх хаяг олдсонгүй");
                  }
                }}
              >
                APP төлөх
              </div>
            </>
          )}
          {!state?.id && (
            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-1/3">Шалтгаан</span>
              <Select
                showSearch
                placeholder="Сонгох"
                className="w-full md:w-60"
                filterOption={(input, option) => {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                value={state?.card_reason}
                onChange={(value) => {
                  setState((old) => ({
                    ...old,
                    card_reason: value,
                  }));
                }}
              >
                {_.map(state?.card_reasons, (item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}

          <div className="flex items-center justify-end gap-4 mt-4">
            <CloseButton
              onClick={() => {
                setState((old) => ({
                  ...old,
                  open: false,
                }));
              }}
            />
            {state?.id ? (
              <SaveButton
                onClick={async () => {
                  if (state?.qpay_invoice_id) {
                    setState((old) => ({
                      ...old,
                      loading: true,
                    }));
                    var res = await API.GET("/payment_check", {
                      invoice_id: state?.qpay_invoice_id,
                    });
                    if (
                      res?.response?.params?.message ===
                      "Төлбөр амжилттай төлөгдсөн байна!"
                    ) {
                      message(
                        "success",
                        "Амжилттай",
                        res?.response?.params?.message
                      );
                      setState((old) => ({
                        ...old,
                        open: false,
                        refresh: old.refresh + 1,
                      }));
                    } else {
                      message(
                        "warning",
                        "Амжилттай",
                        res?.response?.params?.message
                      );
                    }
                    setState((old) => ({
                      ...old,
                      loading: false,
                    }));
                  } else {
                    message("error", "Алдаа", "Төлбөр шалгах боломжгүй байна");
                  }
                }}
                name="Төлбөр шалгах"
              />
            ) : (
              <SaveButton
                onClick={() => {
                  save();
                }}
                name="Хүсэлт илгээх"
              />
            )}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default React.memo(Card);
