import axios from "axios";

export function ADMIN_API() {
    const headers = {
        "Content-Type": "application/json,",
    };
    const token =
        typeof window !== "undefined" ? localStorage.getItem("system_token") : null;

    const type =
        typeof window !== "undefined" ? localStorage.getItem("type") : null;

    if (token && type) {
        Object.assign(headers, {
            Authorization: "Bearer " + token,
        });
    }

    var api = axios.create({
        // baseURL: "http://localhost:8080/rest/",
        baseURL: "https://odoo.erdenetmc.mn/rest/",
        timeout: 200000,
        headers,
    });

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.status === 401) {
                console.log(error.status)
                localStorage.clear();
                window.location.replace("/login");
            }
            throw error;
        }
    );
    return api;
}

function randomText(length = 4) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; // Only alphabets
    let secretKey = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        secretKey += characters[randomIndex];
    }
    return secretKey;
}

export async function encryptNumber(number) {
    let id_list = String(number).split('');  // Convert number to string and split into an array of digits
    let ukid = '';
    id_list.forEach(element => {
        ukid += `${element}${randomText()}`;  // Append random text after each digit
    });
    console.log(ukid);  // Output the final encrypted string
    return ukid;
}


export async function PORTAL_AUTH(url, params) {

    const response = await ADMIN_API().post(url, { ...params });
    
    return response
}


export async function isLogin() {
    try {
        const response = await ADMIN_API().get("portal/login_check");
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}





export function USER_API() {
    const headers = {
        "Content-Type": "application/json,",
    };
    const token =
        typeof window !== "undefined" ? localStorage.getItem("user_token") : null;

    const type =
        typeof window !== "undefined" ? localStorage.getItem("type") : null;

    if (token && type) {
        Object.assign(headers, {
            Authorization: "Bearer " + token,
        });
    }

    var api = axios.create({
        // baseURL: "http://localhost:8080/rest/",
        baseURL: "https://odoo.erdenetmc.mn/rest/",
        timeout: 200000,
        headers,
    });

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.status === 401) {
                console.log(error.status)
                // localStorage.removeItem('company_registry')
                // localStorage.removeItem('company_name')
                // localStorage.removeItem('user_token')
                localStorage.clear();
                window.location.replace("/login");
            }
            throw error;
        }
    );
    return api;
}



export async function getСompanies(civil_id = 0) {
    const response = await USER_API().get('portal/companies/' + civil_id);
    return response?.data;
}


export async function getGroups() {
    const response = await USER_API().get('portal/groups');
    return response?.data;
}



export async function POST(url, params) {
    const response = await USER_API().post(url, { ...params });
    return response?.data;
}

export async function GET(url, params = null) {
    const response = await ADMIN_API().get(url, {
        params: {
            ...params,
        },
    });
    return response?.data;
}
