import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import * as API from "src/api/request";
import List from "./list";
import Header from "./header";
import RateModal from "./modal";
import FileModal from "./file_modal";
import { useUserContext } from "src/context/userContext";

const Rate = () => {
  const { message } = useUserContext();
  const [state, setState] = useState({
    row: {},
    employee_kpi: [],
    search_text: "",
    date: dayjs(new Date()),
    department_id: null,
    rate_list: [],
    department: [],
    loading: false,
    list: [],
    open: false,
    search: "",
    modal: false,
    modal_loading: false,
    modal_list_refresh: 0,
    list_refresh: 0,
    file_modal: false,
    file_url: null,
  });

  const Department = () => {
    setState((old) => ({
      ...old,
      loading: true,
    }));
    API.GET("/department")
      .then((res) => {
        if (res?.status_code) {
          var result_departments = _.map(res?.response, (item) => ({
            ...item,
            title: item.department_code + " | " + item.name,
            key: item.id,
            value: item.id,
            pId: item.parent_id,
          }));
          setState((old) => ({
            ...old,
            department: result_departments,
            department_id:
              result_departments?.length > 0 ? result_departments[0].id : null,
            loading: false,
          }));
        } else {
          setState((old) => ({
            ...old,
            department: [],
            department_id: null,
            loading: false,
          }));
        }
      })
      .catch((err) => {
        message("warning", "Анхааруулга", err?.message);
      });
  };

  useEffect(() => {
    Department();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.department_id && state?.date) {
      setState((old) => ({
        ...old,
        loading: true,
      }));
      API.GET("/kpi/employee/list", {
        department_id: state?.department_id,
        year: state?.date?.format("YYYY"),
        month: state?.date?.format("MM"),
      })
        .then((res) => {
          if (res?.status_code) {
            var result = [];
            _.map(res?.response, (item) => {
              result.push({
                ...item,
                id: item.tn,
                job_id: item.job_name?.split("|")[0],
                job_name: item.job_name?.split("|")[1],
                department_id: item?.department_id[0],
                department_name: item?.department_id[1],
                master_department_id: item?.master_department_id[0],
                master_department_name: item?.master_department_id[1],
                section_department_id: item?.section_department_id[0],
                section_department_name: item?.section_department_id[1],
              });
            });
            setState((old) => ({
              ...old,
              rate_list: _.orderBy(result, [
                "department_code",
                "tn",
                "job_name",
              ]),
              loading: false,
            }));
          } else {
            setState((old) => ({
              ...old,
              rate_list: [],
              loading: false,
            }));
          }
        })
        .catch((err) => {
          message("warning", "Анхааруулга", err?.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.department_id, state?.date, state?.list_refresh]);

  useEffect(() => {
    var result = state?.rate_list;
    if (state?.search) {
      result = _.filter(
        result,
        (a) =>
          _.includes(
            _.toLower(_.toString(a.tn)),
            _.toLower(_.toString(state?.search))
          ) ||
          _.includes(
            _.toLower(_.toString(a.name)),
            _.toLower(_.toString(state?.search))
          ) ||
          _.includes(
            _.toLower(_.toString(a.job_name)),
            _.toLower(_.toString(state?.search))
          )
      );
    }
    setState((old) => ({ ...old, list: result }));
  }, [state?.rate_list, state?.search]);

  return (
    <div className="card card-body">
      <RateModal state={state} setState={setState} />
      <FileModal state={state} setState={setState} />
      <Header state={state} setState={setState} />
      <Spin
        tip="Уншиж байна."
        className="min-h-full bg-opacity-80"
        spinning={state?.loading}
      >
        <List state={state} setState={setState} />
      </Spin>
    </div>
  );
};

export default Rate;
