
import { EyeIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { IoAttach } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const DirenctPurchase = () => {
    const products = [
        { name: "АВТОМАШИНЫ СЭЛБЭГ худалдан авах", description: "\"ЭРДЭНЭТ ҮЙЛДВЭР\" ТӨҮГ нь тендерийн баримт бичигт заасан шалгуур үзүүлэлт, шаардлагыг хангасан хуулийн этгээд, иргэнийг АВТОМАШИНЫ СЭЛБЭГ нийлүүлэх тухай үнийн...", startDate: "2025-01-03", endDate: "2025-01-03" },
        { name: "10 нэр төрлийн эмнэлгийн багаж, хэрэгсэл", description: "\"ЭРДЭНЭТ ҮЙЛДВЭР\" ТӨҮГ нь тендерийн баримт бичигт заасан шалгуур үзүүлэлт, шаардлагыг хангасан хуулийн этгээд, иргэнийг “10 нэр төрлийн эмнэлгийн багаж,..", startDate: "2024-12-31", endDate: "2025-01-08" },
        { name: "Magic Mouse 2", description: "Black", startDate: "Accessories", endDate: "$99" },
        { name: "Google Pixel Phone", description: "Gray", startDate: "Phone", endDate: "$799" },
        { name: "Apple Watch 5", description: "Red", startDate: "Wearables", endDate: "$999" }
    ];
    const [expandedRows, setExpandedRows] = useState({});
    const navigate = useNavigate();
    const toggleRow = (index) => {
        setExpandedRows((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    return (
        <div className="flex flex-col gap-8 py-4 items-center px-8">
            <div className="mx-auto w-full flex flex-col gap-2 justify-start">
                <h2 className="text-xl font-semibold text-slate-800 dark:text-slate-200">Шууд худалдан авалт</h2>
                <p className="mt-2 text-balance text-base/2 font-semibold tracking-tight text-slate-600 dark:text-slate-400">"Эрдэнэт үйлдвэр" ТӨҮГ -ын шууд худалдан авалтын шалгаруулалтын жагсаалт </p>
            </div>

            <table className="text-sm text-left text-gray-500 dark:text-gray-400 bg-opacity-50 rounded-xl">
                <thead className="rounded-xl bg-opacity-50 text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                    <tr>
                        <th scope="col" className="px-6 py-3 ">Шалгаруулалтын нэр</th>
                        <th scope="col" className="px-6 py-3">Товч тайлбар</th>
                        <th scope="col" className="px-6 py-3">Зарлсан огноо</th>
                        <th scope="col" className="px-6 py-3">Дуусах огноо</th>
                        <th scope="col" className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product, index) => (
                        <React.Fragment key={index}>
                            <tr
                                className={`${index % 2 === 0
                                    ? "bg-white dark:bg-gray-900"
                                    : "bg-gray-50 dark:bg-gray-800"
                                    } border-b dark:border-gray-700 border-gray-200 bg-opacity-50`}
                            >
                                <th scope="row" className="px-6 py-4  font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {product.name}
                                </th>
                                <td className="px-6 py-4">{product.description}</td>
                                <td className="px-6 py-4">{product.startDate}</td>
                                <td className="px-6 py-4">{product.endDate}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => toggleRow(index)}
                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                        {expandedRows[index] ? "Хураах" : "Дэлгэрэнгүй"}
                                    </button>
                                </td>
                            </tr>
                            {expandedRows[index] && (
                                <tr
                                    className={`${index % 2 === 0
                                        ? "bg-white dark:bg-gray-900 "
                                        : "bg-gray-50 dark:bg-gray-800"
                                        } border-b w-full dark:border-gray-700 border-gray-200 bg-opacity-35`}
                                >
                                    <td colSpan={5} className="px-6 py-4">
                                        <div className="flex flex-col gap-4">
                                            <div className="text-gray-900 dark:text-white">
                                                <strong>"ЭРДЭНЭТ ҮЙЛДВЭР" ТӨҮГ</strong>  нь тендерийн баримт бичигт заасан шалгуур үзүүлэлт, шаардлагыг хангасан хуулийн этгээд, иргэнийг <strong>{product.name}</strong> нийлүүлэх тухай үнийн санал ирүүлэхийг урьж байна.
                                            </div>
                                            <div className="text-gray-900 text-base dark:text-white">
                                                <strong>Гэрээний нөхцөл: </strong>
                                            </div>
                                            <div className="text-gray-900 dark:text-white">
                                                Сонирхогч этгээд 2025 оны 01 дүгээр сарын 03-ны өдөр 09 цаг, 00 минутаас өмнө системд нэвтрэн үнийн санал ирүүлснээр шууд худалдан авалтын шалгаруулалтад оролцох эрхтэй болно.
                                            </div>

                                            <div className="flex gap-2 w-full justify-end">
                                                <div className="flex gap-1 bg-green-800 p-2 text-white rounded-lg hover:bg-green-700 hover:cursor-pointer" onClick={() => {
                                                    navigate("/purchase/direct-purchase");
                                                }}>
                                                    <IoAttach className="w-5 h-5" />
                                                    <div>
                                                        Материал илгээх
                                                    </div>
                                                </div>
                                                <div className="flex gap-1 bg-yellow-800 p-2 text-white rounded-lg hover:bg-yellow-700 hover:cursor-pointer" onClick={() => {
                                                    window.open("https://www.erdenetmc.mn/storage/media/cK6KvVdVX9QYSxPdbSmFVNIXeXwh4IDcP344ed6K.pdf");
                                                }}>
                                                    <EyeIcon className="w-5 h-5" />
                                                    <div>
                                                        ТШББ харах
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>

        </div>



    );
};

export default DirenctPurchase;
