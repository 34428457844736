import React, { useLayoutEffect } from 'react';

import { useUserContext } from "src/context/userContext";
import { usePurchaseContext } from "src/purchase/context/purchaseContext"
import { Outlet } from "react-router-dom";

import FullPageLoader from 'src/components/fullPageLoader';
import AlertBox from "src/purchase/components/alertBox";
import Navbar from 'src/purchase/components/layout/navbar'
import Sidebar from 'src/purchase/components/layout/sidebar'
import ProfileDialog from 'src/components/profile_dialog';


const Layout = () => {
    const { user, userDispatch } = useUserContext();
    const { purchaseDispatch } = usePurchaseContext()

    useLayoutEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (/mobile/i.test(userAgent)) {
            purchaseDispatch({ 'deviceType': 'Mobile' })
        } else if (/tablet/i.test(userAgent)) {
            purchaseDispatch({ 'deviceType': 'Tablet' })
        } else {
            purchaseDispatch({ 'deviceType': 'Desktop' })

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>{
        user.pageLoader ? <FullPageLoader /> :
            <div className="flex bg-transparent dark:bg-slate-950 h-full">
                <div
                    className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl bg-white"
                    aria-hidden="true"
                >
                    <div className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#51b139] to-[#990b46] opacity-40"></div>
                </div>
                {user?.showAlert && (
                    <AlertBox
                        type={user?.showAlert?.type}
                        message={user?.showAlert?.message}
                        duration={5000} // Auto-close after 5 seconds
                        onClose={() => userDispatch({ showAlert: false })} // Reset the alert when closed
                    />
                )}
                <ProfileDialog />
                <Sidebar />

                {/* Main content */}
                <div className="my-2 mx-3 md:w-full w-screen ">
                    <div className="flex flex-col gap-2 bg-transparent">
                        <Navbar />
                        {<Outlet />}
                    </div>
                </div>
            </div>
    }
    </>



    );
};

export default React.memo(Layout);
