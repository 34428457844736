import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import * as API from "src/api/request";
import _ from "lodash";
import { Spin } from "antd";
import base64 from "base-64";
var utf8 = require("utf8");

const Check = () => {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetch_date = async () => {
    try {
      setLoading(true);
      const check = await API.GET(`/check/${params?.id}`);
      if (check?.status_code) {
        if (check?.response?.length > 0) {
          var bytes = base64.decode(check?.response[0]?.description);
          var text = utf8.decode(bytes);
          setData({
            ...check?.response[0],
            description: text,
          });
        }
      } else {
      }
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      fetch_date();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <div className="max-w-3xl mx-auto mt-10">
      <Spin spinning={loading}>
        <div className="flex items-center justify-center flex-col">
          <img src="/emc/CELogo_small.png" alt="emc logo" />
          <div className="text-3xl text-[#034ba4] pt-6 pb-4">
            ЭРДЭНЭТ ҮЙЛДВЭР ТӨҮГ
          </div>
          <div className="text-center font-light tracking-wide text-[#034ba4]">
            61027 Орхон аймаг, Баян-Өндөр сум, Найрамдал талбай,
            <br />
            Утас: 976 (01-352) 7 35 01, Факс: (976) 7035 3002,
            <br />
            Email:info@erdenetmc.mn, http://www.erdenetmc.mn
          </div>
          <div className="flex items-center gap-10 mt-2">
            <div>Огноо: {moment(data?.create_date).format("YYYY-MM-DD")}</div>
            <div>№ T - 2796</div>
          </div>
          <div className="text-2xl mt-5 mb-3 text-black">
            {_.upperCase(data?.organization_name)}
          </div>
        </div>
        <div className="px-4">
          {data?.description && (
            <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
          )}
        </div>
      </Spin>
      <div className="absolute bottom-0 left-0 py-2 border-t border-gray-300 w-full text-center">
        © {moment().format("YYYY")} - Холбоо мэдээлэл, техник, автоматжуулалтын
        цех
      </div>
    </div>
  );
};

export default Check;
