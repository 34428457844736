import React, { useState, useEffect } from "react";


function AlertBox({ type = "error", message, duration = 3000, onClose }) {
    const [isVisible, setIsVisible] = useState(true);

    const closeAlert = () => {
        setIsVisible(false);
        if (onClose) onClose();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            closeAlert();
        }, duration);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration]);

    if (!isVisible) return null;

    const alertClasses = {
        info: "text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800",
        success: "text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-800 dark:border-green-800",
        warning: "text-yellow-800 border-t-4 border-yellow-300 bg-yellow-50 dark:text-yellow-300 dark:bg-gray-800 dark:border-yellow-800",
        error: "text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-gray-800 dark:border-red-800",
        neutral: "text-gray-800 border-t-4 border-gray-300 bg-gray-50 dark:bg-gray-800 dark:border-gray-600"
    };

    const iconPaths = {
        info: "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z",
        error: "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z",
        success: "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z",
        warning: "M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
    };

    const iconColor = {
        info: "text-blue-500 focus:ring-blue-400 p-1.5 hover:bg-blue-200",
        error: "text-red-500 focus:ring-red-400 p-1.5 hover:bg-red-200",
        success: "text-green-500 focus:ring-green-400 p-1.5 hover:bg-green-200",
        warning: "text-orange-500 focus:ring-orange-400 p-1.5 hover:bg-orange-200"
    };

    return (
        <div
            className={`fixed top-4 right-4 z-50 flex items-center p-4 mb-4 border rounded-xl bg-opacity-60 dark:bg-opacity-80 ${alertClasses[type]}`}
            role="alert"
        >
            <svg className="shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d={iconPaths[type]} />
            </svg>
            <div className="ms-3 text-sm font-medium">
                {message}{" "}

            </div>
            <button
                type="button"
                className={`ms-auto -mx-1.5 -my-1.5 bg-transparent  rounded-lg focus:ring-2 ${iconColor[type]} inline-flex items-center justify-center h-8 w-8`}
                aria-label="Close"
                onClick={closeAlert}
            >
                <span className="sr-only">Dismiss</span>
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
            </button>
        </div>
    );
}

export default AlertBox;