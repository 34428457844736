import React, { useEffect, useState } from "react";
import * as API from "src/api/request";

import { Spin, Table } from "antd";

const Training = () => {
  const [loading, set_loading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    set_loading(true);
    API.GET("employee/speciality")
      .then((res) => {
        setList(res.response);
      })
      .finally(() => set_loading(false));
  }, []);

  return (
    <Spin spinning={loading}>
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        dataSource={list}
        className="w-[600px] overflow-auto sm:w-full"
      >
        <Table.Column
          title="№"
          align="center"
          render={(data, row, index) => index + 1}
        />
        <Table.Column
          title="Эхлэсэн огноо"
          align="center"
          dataIndex="start_date"
        />
        <Table.Column
          title="Дууссан огноо"
          align="center"
          dataIndex="end_date"
        />
        <Table.Column
          title="Сургалтын нэр"
          align="center"
          dataIndex="training"
          render={(data) => <div className="flex">{data}</div>}
        />
        <Table.Column
          title="Сургалтанд хамрагдсан газар"
          align="center"
          dataIndex="organization"
          render={(data) => <div className="flex">{data}</div>}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Training);
