import React, { useEffect, useState } from "react";
import * as API from "src/api/request";
import { useUserContext } from "src/context/userContext";
import _ from "lodash";
import axios from "axios";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import View from "./view";

const Definition = () => {
  const { user, message } = useUserContext();
  const [state, setState] = useState({
    step: 1,
    definition_type_id: null,
    definition_type_name: "",
    type_id: null,
    type: [],
    search: "",
    organization_id: null,
    organization_name: null,
    organization: [],
    organization_filter: [],
    fileUrl: null,
    loading: false,
  });

  const fetch_date = async () => {
    try {
      setState((old) => ({ ...old, loading: true }));
      const organization = await API.GET("/definition/organization");
      if (organization?.status_code) {
        var result = [];
        _.map(organization?.response, (el) => {
          return result.push({
            ...el,
            value: _.upperFirst(_.toLower(el?.name)),
          });
        });
        setState((old) => ({
          ...old,
          organization: result,
          organization_filter: result,
        }));
      } else {
        message("error", "Алдаа", organization?.response);
      }
    } catch (error) {
      message("error", "Алдаа", error?.message);
    } finally {
      setState((old) => ({ ...old, loading: false }));
    }
  };

  useEffect(() => {
    fetch_date();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const convertString = (id) => {
  //   if (id && id?.length > 1) {
  //     var a = _.split(id[1], "|");
  //     if (a?.length > 1) {
  //       return a[1];
  //     } else {
  //       return "";
  //     }
  //   } else {
  //     return "";
  //   }
  // };

  const fetch_pdf = async (type) => {
    try {
      setState((old) => ({ ...old, loading: true }));
      var url = "";
      var params = {
        organization: _.upperCase(state?.organization_name) || "",
      };
      if (state?.definition_type_id === 1) {
        url = "https://hrrodoo.erdenetmc.mn/Home/job";
        var job = await API.GET("/definition/job");
        if (job.status_code) {
          params.description = job?.response?.description;
          params.hash = job?.response?.hash;
        }
      } else if (state?.definition_type_id === 2) {
        url = "https://hrrodoo.erdenetmc.mn/Home/Salary";
        var salary = await API.GET("/definition/salary");
        if (salary.status_code) {
          params.description = salary?.response?.description;
          params.hash = salary?.response?.hash;
        }
      }
      const res = await axios.post(url, params, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });

      const fileUrl = URL.createObjectURL(res?.data);
      if (params?.organization && params?.hash && params?.description) {
        await API.POST("/definition", {
          organization_id: state?.organization_id
            ? state?.organization_id
            : null,
          organization_name: params?.organization ? params?.organization : "",
          kiosk_id: 3,
          type: "job",
          hash: params?.hash ? params?.hash : "",
          description: params?.description ? params?.description : "",
        });
      }

      if (type === 1) {
        setState((old) => ({ ...old, fileUrl: fileUrl, step: 4 }));
      } else if (type === 2) {
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute(
          "download",
          state?.definition_type_name + " - " + user?.info?.name + ".pdf"
        );
        document.body.appendChild(link);
        link.click();
      } else {
        message("warning", "Анхааруулга", "Төрөл тодорхойгүй байна.");
      }
    } catch (error) {
      message("error", "Алдаа", error?.message);
    } finally {
      setState((old) => ({ ...old, loading: false }));
    }
  };

  const renderStep = () => {
    switch (state.step) {
      case 1:
        return <Step1 state={state} setState={setState} />;
      case 2:
        return <Step2 state={state} setState={setState} />;
      case 3:
        return (
          <Step3 state={state} setState={setState} fetch_pdf={fetch_pdf} />
        );
      case 4:
        return <View state={state} setState={setState} fetch_pdf={fetch_pdf} />;
      default:
        return null;
    }
  };

  return (
    <div className="card h-[calc(100vh-150px)] overflow-y-auto">
      {renderStep()}
    </div>
  );
};

export default React.memo(Definition);
