import React from 'react';
import { useUserContext } from "src/context/userContext";
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid';
import { usePurchaseContext } from "src/purchase/context/purchaseContext"
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const { user, userDispatch } = useUserContext();
    // const [openDropdown, setOpenDropdown] = useState(false);
    // const [sidebarOpen, setSidebarOpen] = useState(false);
    // const toggleDropdown = () => {
    //     setOpenDropdown(!openDropdown);
    // };
    const type = localStorage.getItem("type");

    const handleLogout = () => {
        localStorage.clear();
        if (window.location.origin === "http://localhost:3000") {
            window.location = "http://localhost:3000/login";
        } else {
            window.location = "https://portal.erdenetmc.mn/login";
        }
    };
    const { purchaseState, purchaseDispatch } = usePurchaseContext();
    const navigate = useNavigate();
    return (
        <div className={` ${purchaseState.sidebarOpen ? '' : 'hidden'} fixed z-40 md:z-0 md:relative flex mt-2 ml-2 mb-12 md:min-h-svh md:max-h-svh md:w-full md:max-w-[18rem] flex-col transition-all duration-600`}>
            <div className="fixed top-2 z-40 left-2 bottom-2 flex w-full max-w-[18rem] flex-col border dark:border-slate-800 rounded-xl bg-white md:bg-opacity-60 dark:bg-slate-950 md:dark:bg-opacity-80 bg-clip-border p-4 text-gray-700 shadow-xl shadow-blue-gray-900/5">
                <div className="flex justify-center w-full gap-2 border-b dark:border-slate-700 px-2" >
                    <img className='w-8/12' src="/img/brand/purchase_logo.png" alt="brand" />
                    {/* <h5 className="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Sidebar
            </h5> */}
                </div>

                <div className="h-full px-3 py-2 overflow-y-auto">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <div className="flex flex-col items-center py-2 mx-6">
                                {/* User Info Section */}
                                <div className="flex flex-col items-center gap-x-4 p-2">
                                    <img
                                        alt="User Profile"
                                        src={type === 'SSO' ? `https://minio-action.erdenetmc.mn/emp/${user?.info?.tn}.jpg` : '/img/user.png'}
                                        className="w-10 h-10 rounded-full ring-2 ring-green-700"
                                    />
                                    <div className='flex flex-col items-center my-2'>
                                        {localStorage.getItem('type') === "SSO" ?
                                            <div className='flex flex-col gap-3 mt-2'>
                                                <p className="text-sm font-semibold dark:text-gray-200 text-gray-900 ">{user?.info?.name} ({user?.info?.tn})</p>
                                                <p className="text-xs text-gray-500 text-center">{user?.info?.work_email}</p>
                                            </div>
                                            : <div className='flex flex-col gap-1 mt-2'>
                                                <p className="text-xs font-semibold text-center dark:text-gray-400 text-gray-900 mb-1">{user?.info?.first_name}</p>
                                                <p className="text-sm text-gray-700 text-center dark:text-gray-300 rounded-lg shadow-sm bg-opacity-90">{localStorage.getItem('company_name')}</p>
                                            </div>}
                                    </div>
                                </div>
                                {
                                    user?.companies ? <div className="w-full flex justify-center">
                                        <div onClick={async () => {
                                            if (purchaseState.deviceType === "Mobile") {
                                                purchaseDispatch({ sidebarOpen: false })
                                            }
                                            userDispatch({ profileDialog: true })
                                        }} className="flex justify-center cursor-pointer gap-1 p-2 text-sm text-gray-900 group rounded-lg shadow-xl border dark:border-gray-800 transition-all duration-200 hover:scale-105 active:scale-95 hover:bg-gray-100  dark:hover:bg-gray-700 dark:text-white">
                                            <ChevronUpDownIcon className="w-5 h-5" />
                                            <span>Хэрэглэгч солих</span>
                                        </div>
                                    </div> : ''
                                }


                            </div>
                        </li>
                        {purchaseState?.menu_list?.length > 0 ? (
                            purchaseState.menu_list.map((item, index) => (
                                <li key={index}>
                                    <div
                                        onClick={() => {
                                            navigate(`/purchase${item?.path}`);
                                        }}
                                        className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-900 hover:cursor-pointer group transition-all duration-200 hover:scale-105 active:scale-95"
                                    >
                                        {item?.icon}
                                        <span className="ms-3">{item?.title}</span>
                                    </div>
                                </li>
                            ))
                        ) : (
                            ''
                        )}

                        {/* Dropdown (Example) */}
                        {/* <li>
                            <button
                                type="button"
                                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                onClick={toggleDropdown}
                            >
                                <DocumentTextIcon className="size-6" />
                                <span className="flex-1 ms-3 text-left">Contract</span>
                                <svg className="w-3 h-3" fill="none" viewBox="0 0 10 6">
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>

                            {openDropdown && (
                                <ul className="py-2 space-y-2">
                                    <li>
                                        <a
                                            href="purchase/contract"
                                            className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        >
                                            Contracts
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#/"
                                            className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        >
                                            Substitute product
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#/"
                                            className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        >
                                            Invoice
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li> */}

                        {/* Log out */}
                        <li>
                            <button
                                onClick={handleLogout}
                                className="w-full flex items-center gap-x-2 p-2 text-sm font-semibold text-red-600 hover:bg-gray-100 dark:hover:bg-gray-700 group rounded-lg"
                            >
                                <ArrowRightStartOnRectangleIcon className="w-5 h-5 text-red-500" />
                                <span>Logout</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default React.memo(Sidebar);
