import React from "react";
import { useUserContext } from "src/context/userContext";
const Breadcrumb = () => {
  const { user } = useUserContext();

  return (
    <div className="breadcrumb-header justify-content-between">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item active">Portal</li>
          <li className="breadcrumb-item active" aria-current="page">
            {user?.selected_menu?.name}
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default React.memo(Breadcrumb);
