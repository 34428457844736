import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h1>404</h1>
            </div>
            <h2>Хуудас олдсонгүй</h2>
            <p>Таны хайсан хуудас олдсонгүй эсвэл хандах эрхгүй байна.</p>
            <Link to="/">Буцах</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Page404);
