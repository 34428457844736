
import React, { useEffect, useState } from 'react'
import { MoonFilled, SunFilled } from '@ant-design/icons';
import { usePurchaseContext } from "src/purchase/context/purchaseContext"
import { useLocation } from 'react-router-dom';

export default function Navbar() {
    const { purchaseState, purchaseDispatch } = usePurchaseContext();
    const [theme, setTheme] = useState(localStorage.getItem('theme') ?? purchaseState?.theme ?? 'light')
    // const [breadcrumb, setBreadcrumb] = useState([])
    const location = useLocation();
    const changeTheme = () => {
        const value = theme === 'light' ? 'dark' : 'light'
        localStorage.setItem("theme", value)
        setTheme(value)
    }

    const toggleSidebar = () => {
        purchaseDispatch({ sidebarOpen: !purchaseState.sidebarOpen });
        console.log(purchaseState?.sidebarOpen)
    };

    useEffect(() => {
        if (theme === 'dark') {
            document.querySelector('html')?.classList.add('dark')
            // console.log('dark')
        } else {
            document.querySelector('html')?.classList.remove('dark')
            // console.log('dark')
        }
        // const paths = location.pathname.split('/').filter((path) => path);

        // paths.map((item) => {
        //     purchaseState?.menu_list.filter((menu) => menu.path === )

        // })
        // setBreadcrumb()
        // console.log(paths)
        // purchaseState?.menu_list.map((item)=>{
        //     setBreadcrumb()
        // })
    }, [theme, location])
    return (
        <div className="sticky gap-2 top-2 md:top-2 flex justify-between items-center flex-row-reverse md:flex-row border rounded-xl bg-white/60 dark:bg-slate-950 dark:border-slate-800 dark:bg-opacity-100 shadow-md p-4 w-full">
            <div className='flex gap-6 items-center '>
                {/* Hamburger Button for Mobile View */}
                <div className="h-full flex items-center">
                    <button className="text-gray-500 active:border-none" onClick={toggleSidebar}>
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </button>
                </div>

                <nav className="hidden md:flex " aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li className="inline-flex items-center">
                            <a href="/" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-slate-500 dark:text-gray-400 dark:hover:text-white">
                                <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                </svg>
                                <a href="/purchase" className="ms-1 text-sm font-medium text-gray-700 hover:text-slate-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Худалдан авалт</a>
                            </div>
                        </li>

                        <li aria-current="page">
                            <div className="flex items-center">
                                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                </svg>
                                <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Гэрээ</span>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
            <button className='w-6 h-6 rounded-full active:rounded-full active:border-1 transition-all duration-400 hover:scale-125' onClick={changeTheme}>
                {theme === 'light' ? <MoonFilled className='text-gray-700' /> : <SunFilled className='text-white' />}
            </button>
            <div className="md:hidden w-full">
                <img className='w-4/12' src="/img/brand/purchase_logo.png" alt="brand" />
            </div>
        </div>

    )
}
