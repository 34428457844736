import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";
import * as API from "src/api/request";

import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
  DownloadButton,
} from "../../components/button";
import { Spin, Table, Modal, Select, Input, DatePicker, Upload } from "antd";
import _ from "lodash";
import dayjs from "dayjs";

var _specialty = {
  modal: false,
  submit: false,
  loading: false,
  fileList: [],

  id: null,
  location_type: null,
  organization: null,
  training: null,
  start_date: null,
  end_date: null,
  certificate_date: null,
  certificate_no: null,
  attachments: [],
};

var _rank = {
  modal: false,
  submit: false,
  fileList: [],

  id: null,
  rank_type: null,
  organization: null,
  rank_date: null,
  rank_no: null,
  attachments: [],
};

const Specialty = () => {
  const { message } = useUserContext();
  const { state } = useCVContext();
  const [loading, set_loading] = useState(false);

  const [reference_location_type, set_reference_location_type] = useState([]);
  const [dic_location_type, set_dic_location_type] = useState({});
  const [reference_rank_type, set_reference_rank_type] = useState([]);

  const [specialty_list, set_specialty_list] = useState([]);
  const [specialty_refresh, set_specialty_refresh] = useState(0);
  const [specialty_loading, set_specialty_loading] = useState(false);
  const [specialty, set_specialty] = useState({
    ..._specialty,
  });

  const [rank_list, set_rank_list] = useState([]);
  const [rank_refresh, set_rank_refresh] = useState(0);
  const [rank_loading, set_rank_loading] = useState(false);
  const [rank, set_rank] = useState({
    ..._rank,
  });

  const clear_specialty = () => {
    set_specialty({
      ...specialty,
      ..._specialty,
    });
  };

  const clear_rank = () => {
    set_rank({
      ...rank,
      ..._rank,
    });
  };

  useEffect(() => {
    API.GET("reference/selection", {
      model_name: "emc.cv.specialty",
      field_name: "location_type",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_location_type(res.response);
        var dic = {};
        _.map(res.response, (item) => {
          dic[item.value] = item.name;
        });
        set_dic_location_type(dic);
      } else message("error", res.response);
    });

    API.GET("reference", {
      model_name: "emc.hr.rank.type",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_rank_type(res.response);
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/specialty", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_specialty_list(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, specialty_refresh]);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/rank", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_rank_list(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, rank_refresh]);

  const upload_file = (
    model_name,
    res_id,
    field_name,
    upload_type,
    fileList
  ) => {
    _.map(fileList, (item) => {
      const formData = new FormData();
      formData.append("model_name", model_name);
      formData.append("res_id", res_id);
      formData.append("field_name", field_name);
      formData.append("upload_type", upload_type);
      formData.append("files", item.originFileObj);
      API.UPLOAD_FILE("/file/upload", formData);
    });
  };

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        width={1000}
        title={<div className="text-center border-b pb-2">Мэргэшил</div>}
        open={specialty.modal}
        onCancel={() => clear_specialty()}
        footer={false}
      >
        <Spin spinning={specialty_loading}>
          <div className="mt-2 flex flex-col">
            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">Хаана:</span>
              <Select
                allowClear
                showSearch
                placeholder="Сонгох"
                className="w-full"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={specialty.location_type}
                onChange={(value) =>
                  set_specialty({ ...specialty, location_type: value })
                }
              >
                {_.map(reference_location_type, (item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">
                Байгууллага: <b className="text-red-500">*</b>
              </span>
              <Input
                allowClear
                size="large"
                className="text-xs"
                status={specialty.submit && !specialty.organization && "error"}
                value={specialty.organization}
                onChange={(e) =>
                  set_specialty({ ...specialty, organization: e.target.value })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">
                Ямар чиглэлээр: <b className="text-red-500">*</b>
              </span>
              <Input
                allowClear
                size="large"
                className="text-xs"
                status={specialty.submit && !specialty.training && "error"}
                value={specialty.training}
                onChange={(e) =>
                  set_specialty({ ...specialty, training: e.target.value })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">
                Эхлэх огноо: <b className="text-red-500">*</b>
              </span>
              <DatePicker
                allowClear
                className="w-full"
                status={specialty.submit && !specialty.start_date && "error"}
                value={specialty.start_date}
                onChange={(value) =>
                  set_specialty({ ...specialty, start_date: dayjs(value) })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">
                Дуусах огноо: <b className="text-red-500">*</b>
              </span>
              <DatePicker
                allowClear
                className="w-full"
                status={specialty.submit && !specialty.end_date && "error"}
                value={specialty.end_date}
                onChange={(value) =>
                  set_specialty({ ...specialty, end_date: dayjs(value) })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">Гэрчилгээний огноо:</span>
              <DatePicker
                allowClear
                className="w-full"
                value={specialty.certificate_date}
                onChange={(value) =>
                  set_specialty({
                    ...specialty,
                    certificate_date: dayjs(value),
                  })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">Гэрчилгээний дугаар:</span>
              <Input
                allowClear
                size="large"
                className="text-xs"
                value={specialty.certificate_no}
                onChange={(e) =>
                  set_specialty({
                    ...specialty,
                    certificate_no: e.target.value,
                  })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row gap-1">
              <span className="md:w-60">
                <Upload
                  className="w-full"
                  showUploadList={false}
                  beforeUpload={() => false}
                  multiple={true}
                  //   listType="picture"
                  accept=".pdf, .jpeg, .png, .jpg"
                  showRemoveIcon={true}
                  fileList={specialty.fileList}
                  onChange={(info) => {
                    set_specialty({
                      ...specialty,
                      fileList: info.fileList,
                    });
                  }}
                >
                  <div className="px-2 py-1 bg-gray-100 border rounded-lg cursor-pointer">
                    Файл оруулах
                  </div>
                </Upload>
              </span>
              <div className="w-full flex flex-wrap gap-2">
                {_.map(specialty?.attachments, (item, index) => {
                  return (
                    <div
                      key={index}
                      className="min-w-44 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    >
                      <span>{item.name}</span>
                      <div className="flex gap-2">
                        <DownloadButton
                          onClick={async () => {
                            const link = document.createElement("a");
                            link.href = item.minio_url;
                            link.target = "_blank";
                            link.rel = "noopener noreferrer";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        />
                        <DeleteButton
                          onClick={async () => {
                            await API.DELETE("/file/upload/" + item.id).then(
                              (res) => {
                                if (res.status_code === 1) {
                                  message("success", "Амжилттай устгалаа");
                                  set_specialty({
                                    ...specialty,
                                    attachments: _.without(
                                      specialty.attachments,
                                      item
                                    ),
                                  });
                                  set_specialty_refresh((prev) => prev + 1);
                                }
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                {_.map(specialty.fileList, (item, index) => {
                  return (
                    <div
                      className="p-2 flex items-center justify-between gap-2 border rounded-lg"
                      key={index}
                    >
                      <span>
                        {specialty.attachments.length + index + 1}. {item.name}
                      </span>
                      <DeleteButton
                        onClick={() => {
                          set_specialty({
                            ...specialty,
                            fileList: _.without(specialty.fileList, item),
                          });
                          message("success", "Амжилттай устгалаа");
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <hr className="my-2" />

            <SaveButton
              onClick={() => {
                set_specialty({ ...specialty, submit: true });

                var error = false;
                if (
                  !specialty.organization ||
                  !specialty.training ||
                  !specialty.start_date ||
                  !specialty.end_date
                ) {
                  error = true;
                  message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
                }

                if (!error) {
                  var data = {
                    cv_id: state.id,
                    location_type: specialty.location_type || null,
                    organization: specialty.organization || null,
                    training: specialty.training || null,
                    start_date: specialty.start_date
                      ? dayjs(specialty.start_date).format("YYYY-MM-DD")
                      : null,
                    end_date: specialty.end_date
                      ? dayjs(specialty.end_date).format("YYYY-MM-DD")
                      : null,
                    certificate_date: specialty.certificate_date
                      ? dayjs(specialty.certificate_date).format("YYYY-MM-DD")
                      : null,
                    certificate_no: specialty.certificate_no || null,
                  };
                  if (!specialty.id) {
                    set_specialty_loading(true);

                    API.POST("cv/specialty", data)
                      .then((res) => {
                        if (res.status_code === 1) {
                          upload_file(
                            "emc.cv.specialty",
                            res.id,
                            "attachments",
                            "many2many",
                            specialty.fileList
                          );

                          setTimeout(() => {
                            set_specialty_refresh((prev) => prev + 1);
                            message("success", res.response);
                            clear_specialty();
                          }, 500);
                        } else message("error", res.response);
                      })
                      .catch(() => message("error", "Бүртгэж чадсангүй"))
                      .finally(() => set_specialty_loading(false));
                  } else {
                    set_specialty_loading(true);

                    upload_file(
                      "emc.cv.specialty",
                      specialty.id,
                      "attachments",
                      "many2many",
                      specialty.fileList
                    );

                    setTimeout(() => {
                      API.PUT("cv/specialty/" + specialty.id, data)
                        .then((res) => {
                          if (res.status_code === 1) {
                            set_specialty_refresh((prev) => prev + 1);
                            message("success", res.response);
                            clear_specialty();
                          } else message("error", res.response);
                        })
                        .catch(() => message("error", "Засварлаж чадсангүй"))
                        .finally(() => set_specialty_loading(false));
                    }, 500);
                  }
                }
              }}
            />
          </div>
        </Spin>
      </Modal>

      <CreateButton
        className="mb-2"
        name="Мэргэшил нэмэх"
        onClick={() => set_specialty({ ...specialty, modal: true })}
      />
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={specialty_list}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Хаана"
          align="center"
          dataIndex="location_type"
          render={(data, record, index) =>
            record.location_type in dic_location_type
              ? dic_location_type[record.location_type]
              : record.location_type
          }
        />
        <Table.Column
          title="Байгууллага"
          align="center"
          dataIndex="organization"
        />
        <Table.Column
          title="Ямар чиглэлээр"
          align="center"
          dataIndex="training"
        />
        <Table.Column
          title="Эхлэх огноо"
          align="center"
          dataIndex="start_date"
        />
        <Table.Column
          title="Дуусах огноо"
          align="center"
          dataIndex="end_date"
        />
        <Table.Column
          title="Гэрчилгээний огноо"
          align="center"
          dataIndex="certificate_date"
        />
        <Table.Column
          title="Гэрчилгээний дугаар"
          align="center"
          dataIndex="certificate_no"
        />
        <Table.Column
          title="Файл"
          align="center"
          width={100}
          render={(data, record, index) => (
            <div className="flex flex-wrap gap-2">
              {_.map(record?.attachments, (item, index) => {
                return (
                  <div
                    className="min-w-40 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_specialty({
                    ...specialty,
                    ...record,
                    modal: true,
                    start_date: record.start_date
                      ? dayjs(record.start_date)
                      : null,
                    end_date: record.end_date ? dayjs(record.end_date) : null,
                    certificate_date: record.certificate_date
                      ? dayjs(record.certificate_date)
                      : null,
                    certificate_no: record.certificate_no || null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  set_loading(true);
                  API.DELETE("cv/specialty/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_specialty_refresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"))
                    .finally(() => set_loading(false));
                }}
              />
            </div>
          )}
        />
      </Table>

      <Modal
        centered
        width={1000}
        title={<div className="text-center border-b pb-2">Эрдмийн цол</div>}
        open={rank.modal}
        onCancel={() => clear_rank()}
        footer={false}
      >
        <Spin spinning={rank_loading}>
          <div className="mt-2 flex flex-col">
            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">
                Зэрэг: <b className="text-red-500">*</b>
              </span>
              <Select
                allowClear
                showSearch
                placeholder="Сонгох"
                className="w-full"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                status={rank.submit && !rank.rank_type && "error"}
                value={rank.rank_type}
                onChange={(value) => set_rank({ ...rank, rank_type: value })}
              >
                {_.map(reference_rank_type, (item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">
                Байгууллага: <b className="text-red-500">*</b>
              </span>
              <Input
                allowClear
                size="large"
                className="text-xs"
                status={rank.submit && !rank.organization && "error"}
                value={rank.organization}
                onChange={(e) =>
                  set_rank({ ...rank, organization: e.target.value })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">
                Огноо: <b className="text-red-500">*</b>
              </span>
              <DatePicker
                allowClear
                className="w-full"
                status={rank.submit && !rank.rank_date && "error"}
                value={rank.rank_date}
                onChange={(value) =>
                  set_rank({ ...rank, rank_date: dayjs(value) })
                }
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row md:items-center gap-1">
              <span className="md:w-60">Гэрчилгээний дугаар:</span>
              <Input
                allowClear
                size="large"
                className="text-xs"
                value={rank.rank_no}
                onChange={(e) => set_rank({ ...rank, rank_no: e.target.value })}
              />
            </div>

            <hr className="my-2" />

            <div className="flex flex-col md:flex-row gap-1">
              <span className="md:w-60">
                <Upload
                  className="w-full"
                  showUploadList={false}
                  beforeUpload={() => false}
                  multiple={true}
                  //   listType="picture"
                  accept=".pdf, .jpeg, .png, .jpg"
                  showRemoveIcon={true}
                  fileList={rank.fileList}
                  onChange={(info) => {
                    set_rank({
                      ...rank,
                      fileList: info.fileList,
                    });
                  }}
                >
                  <div className="px-2 py-1 bg-gray-100 border rounded-lg cursor-pointer">
                    Файл оруулах
                  </div>
                </Upload>
              </span>
              <div className="w-full flex flex-wrap gap-2">
                {_.map(rank?.attachments, (item, index) => {
                  return (
                    <div
                      key={index}
                      className="min-w-44 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    >
                      <span>{item.name}</span>
                      <div className="flex gap-2">
                        <DownloadButton
                          onClick={async () => {
                            const link = document.createElement("a");
                            link.href = item.minio_url;
                            link.target = "_blank";
                            link.rel = "noopener noreferrer";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        />
                        <DeleteButton
                          onClick={async () => {
                            await API.DELETE("/file/upload/" + item.id).then(
                              (res) => {
                                if (res.status_code === 1) {
                                  message("success", "Амжилттай устгалаа");
                                  set_rank({
                                    ...rank,
                                    attachments: _.without(
                                      rank.attachments,
                                      item
                                    ),
                                  });
                                  set_rank_refresh((prev) => prev + 1);
                                }
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                {_.map(rank.fileList, (item, index) => {
                  return (
                    <div
                      className="p-2 flex items-center justify-between gap-2 border rounded-lg"
                      key={index}
                    >
                      <span>
                        {rank.attachments.length + index + 1}. {item.name}
                      </span>
                      <DeleteButton
                        onClick={() => {
                          set_rank({
                            ...rank,
                            fileList: _.without(rank.fileList, item),
                          });
                          message("success", "Амжилттай устгалаа");
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <hr className="my-2" />

            <SaveButton
              onClick={() => {
                set_rank({ ...rank, submit: true });

                var error = false;
                if (!rank.rank_type || !rank.organization || !rank.rank_date) {
                  error = true;
                  message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
                }

                if (!error) {
                  var data = {
                    cv_id: state.id,
                    rank_type: rank.rank_type || null,
                    organization: rank.organization || null,
                    rank_date: rank.rank_date
                      ? dayjs(rank.rank_date).format("YYYY-MM-DD")
                      : null,
                    rank_no: rank.rank_no || null,
                  };
                  if (!rank.id) {
                    set_rank_loading(true);

                    API.POST("cv/rank", data)
                      .then((res) => {
                        if (res.status_code === 1) {
                          upload_file(
                            "emc.cv.rank",
                            res.id,
                            "attachments",
                            "many2many",
                            rank.fileList
                          );

                          setTimeout(() => {
                            set_rank_refresh((prev) => prev + 1);
                            message("success", res.response);
                            clear_rank();
                          }, 500);
                        } else message("error", res.response);
                      })
                      .catch(() => message("error", "Бүртгэж чадсангүй"))
                      .finally(() => set_rank_loading(false));
                  } else {
                    set_rank_loading(true);

                    upload_file(
                      "emc.cv.rank",
                      rank.id,
                      "attachments",
                      "many2many",
                      rank.fileList
                    );

                    setTimeout(() => {
                      API.PUT("cv/rank/" + rank.id, data)
                        .then((res) => {
                          if (res.status_code === 1) {
                            set_rank_refresh((prev) => prev + 1);
                            message("success", res.response);
                            clear_rank();
                          } else message("error", res.response);
                        })
                        .catch(() => message("error", "Засварлаж чадсангүй"))
                        .finally(() => set_rank_loading(false));
                    }, 500);
                  }
                }
              }}
            />
          </div>
        </Spin>
      </Modal>

      <hr className="my-3" />

      <div className="mb-2 flex items-center justify-between gap-2">
        <CreateButton
          name="Эрдмийн цол"
          onClick={() => set_rank({ ...rank, modal: true })}
        />
        <span className="italic text-xs">
          (дэд профессор, профессор, академийн гишүүний цолыг оролцуулан бичнэ)
        </span>
      </div>
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={rank_list}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Зэрэг"
          align="center"
          dataIndex="rank_type"
          render={(data) => data && data[1]}
        />
        <Table.Column
          title="Байгууллага"
          align="center"
          dataIndex="organization"
        />
        <Table.Column title="Огноо" align="center" dataIndex="rank_date" />
        <Table.Column
          title="Гэрчилгээний дугаар"
          align="center"
          dataIndex="rank_no"
        />
        <Table.Column
          title="Файл"
          align="center"
          width={100}
          render={(data, record, index) => (
            <div className="flex flex-wrap gap-2">
              {_.map(record?.attachments, (item, index) => {
                return (
                  <div
                    className="min-w-40 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_rank({
                    ...rank,
                    ...record,
                    modal: true,
                    rank_type: record.rank_type ? record.rank_type[0] : null,
                    rank_date: record.rank_date
                      ? dayjs(record.rank_date)
                      : null,
                    rank_no: record.rank_no || null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  set_loading(true);
                  API.DELETE("cv/rank/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_rank_refresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"))
                    .finally(() => set_loading(false));
                }}
              />
            </div>
          )}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Specialty);
