import React from "react";
import { DatePicker, TreeSelect } from "antd";
import dayjs from "dayjs";

const Header = ({ state, setState }) => {
  const previousMonthStart = dayjs().startOf("month").subtract(1, "month");
  const currentMonthEnd = dayjs().endOf("month");
  return (
    <div className="my-2 pb-2 flex items-center border-b flex-col sm:flex-row">
      <span className="mr-2 self-start sm:self-center">Жил:</span>
      <DatePicker
        allowClear={false}
        disabledDate={(d) =>
          !d || d.isBefore(previousMonthStart) || d.isAfter(currentMonthEnd)
        }
        className="w-full sm:w-[100px]"
        picker="year"
        format="YYYY"
        value={state?.date}
        onChange={(value) => {
          setState((old) => ({ ...old, date: dayjs(value) }));
        }}
      />
      <span className="mx-2 self-start sm:self-center">Сар:</span>
      <DatePicker
        allowClear={false}
        disabledDate={(d) =>
          !d || d.isBefore(previousMonthStart) || d.isAfter(currentMonthEnd)
        }
        className="w-full sm:w-[100px]"
        picker="month"
        format="MM"
        value={state?.date}
        onChange={(value) => {
          setState((old) => ({ ...old, date: dayjs(value) }));
        }}
      />
      <span className="mx-2 self-start sm:self-center">Бүтцийн нэгж:</span>
      <TreeSelect
        showSearch
        allowClear={true}
        className="w-full md:w-96"
        placeholder="Сонгох"
        treeDefaultExpandAll={true}
        treeDataSimpleMode={true}
        treeData={state?.department}
        treeLine={(true, { showLeafIcon: false })}
        filterTreeNode={(search, item) =>
          item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
        }
        listHeight={500}
        open={state?.open}
        onDropdownVisibleChange={(open) =>
          setState((old) => ({ ...old, open: open }))
        }
        value={state?.department_id}
        onChange={(value) => {
          setState((old) => ({
            ...old,
            department_id: value,
          }));
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            var find = state.department.filter(
              (el) => el.department_code === e.target.value
            );
            if (find.length > 0) {
              setState((old) => ({
                ...old,
                department_id: find[0].id,
              }));
              setState((old) => ({ ...old, open: false }));
            }
          }
        }}
      />
    </div>
  );
};

export default Header;
