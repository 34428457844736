import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "src/context/userContext";
import * as API from "src/api/request";
import _ from "lodash";

import { BackButton } from "../../components/button";

import { Spin, Steps } from "antd";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import { BiMailSend } from "react-icons/bi";

const ID = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { message } = useUserContext();
  const [loading, set_loading] = useState(false);

  const [is_active, set_is_active] = useState(false);

  const [dic_reference_type, set_dic_reference_type] = useState({});
  const [dic_reference_source, set_dic_reference_source] = useState({});
  const [cv, set_cv] = useState({ id: null });

  const [form, set_form] = useState({
    name: null,
    begin_date: null,
    end_date: null,
    purpose: null,
    education_level_id: null,
    profession: null,
    experience: null,
    specialist: null,
    special_requirement: null,
    description: null,
    is_applied: false,
  });

  useEffect(() => {
    API.GET("reference/selection", {
      model_name: "emc.hr.vacancy",
      field_name: "source",
    }).then((res) => {
      if (res.status_code === 1) {
        var dic = {};
        _.map(res.response, (item) => {
          dic[item.value] = item.name;
        });
        set_dic_reference_source(dic);
      } else message("error", res.response);
    });

    API.GET("reference/selection", {
      model_name: "emc.hr.vacancy",
      field_name: "type_id",
    }).then((res) => {
      if (res.status_code === 1) {
        var dic = {};
        _.map(res.response, (item) => {
          dic[item.value] = item.name;
        });
        set_dic_reference_type(dic);
      } else message("error", res.response);
    });

    API.GET("cv").then((res) => {
      if (res.status_code === 1) {
        set_cv({ ...cv, ...res.response });
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      set_loading(true);
      API.GET("vacancy/" + id)
        .then((res) => {
          if (res.status_code === 1) set_form(res.response);
          else message("error", res.response);
        })
        .catch((res) => message("error", "Алдаа гарлаа"))
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (form.begin_date && form.end_date) {
      var now = dayjs().format("YYYY-MM-DD");
      if (
        dayjs(form.begin_date) <= dayjs(now) &&
        dayjs(now) <= dayjs(form.end_date)
      )
        set_is_active(true);
    }
  }, [form.begin_date, form.end_date]);

  return (
    <Spin spinning={loading}>
      <div className="card">
        <div className="card-header border">
          <div className="flex items-center justify-between">
            <BackButton onClick={() => navigate(-1)} />
            <div className="text-teal-600 text-lg font-semibold text-center uppercase">
              {form.name}
            </div>
            <div>
              {form.is_applied && (
                <div className="px-2 py-1 text-center bg-green-500 text-white font-semibold italic rounded-lg">
                  Анкет илгээсэн
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="card-body">
          <Steps
            className="p-2 border rounded-lg bg-gray-100"
            current={1}
            items={[
              {
                title: "Анкет хүлээн авч байна",
              },
              {
                title: "Сонгон шалгаруулалт явагдаж байна",
              },
              {
                title: "Шалгаруулалт дууссан",
              },
            ]}
          />

          <div className="mt-3 text-teal-600 font-semibold uppercase">
            Ажлын байр
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex gap-2">
              <span className="font-semibold">Албан тушаал:</span>
              <span>{form.name}</span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Төрөл:</span>
              <span>
                {form.type_id in dic_reference_type
                  ? dic_reference_type[form.type_id]
                  : form.type_id}
              </span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Орон тоо:</span>
              <span>{form.count}</span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Шалгаруулалт:</span>
              <span>
                {form.source in dic_reference_source
                  ? dic_reference_source[form.source]
                  : form.source}
              </span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Бүртгэл эхлэх хугацаа:</span>
              <span>{dayjs(form.begin_date).format("YYYY-MM-DD")}</span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Бүртгэл дуусах хугацаа:</span>
              <span>{dayjs(form.end_date).format("YYYY-MM-DD")}</span>
            </div>
          </div>

          <hr className="my-3" />

          <div className="text-teal-600 font-semibold uppercase">
            Тавигдах шаардлага
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <div className="flex gap-2">
              <span className="font-semibold">Зорилго:</span>
              <span>{form.purpose}</span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Боловсрол:</span>
              <span>
                {form.education_level_id && form.education_level_id[1]}
              </span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Мэргэжил:</span>
              <span>{form.profession}</span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Мэргэшил:</span>
              <span>{form.specialist}</span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Туршлага:</span>
              <span>{form.experience}</span>
            </div>

            <div className="flex gap-2">
              <span className="font-semibold">Тусгай шаардлага:</span>
              <span>{form.special_requirement}</span>
            </div>
          </div>

          <hr className="my-3" />

          <div className="text-teal-600 font-semibold uppercase">
            Бүрдүүлэх материал
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <div>
              <Link className="text-blue-500" to={"/cv"}>
                1. Цахим анкет бөглөх
              </Link>
            </div>
            <div>2. Иргэний үнэмлэхийн хуулбар</div>
            <div>3. Мэргэжлийн диплом, үнэмлэх, гэрчилгээ</div>
            <div>
              4. Нийгмийн даатгалын шимтгэл төлөлтийн талаарх тодорхойлолт
            </div>
            <div className="font-semibold">
              Цахим анкетад мэдээллээ үнэн зөв бөглөлгүй, бүрдэл дутуу илгээсэн
              тохиолдолд баримт бичгийн шаардлагыг хангаагүйд тооцно.
            </div>
            <div className="font-semibold">
              Сонгон шалгаруулалтад оролцохдоо бичиг баримтаа эх хувиар нь авч
              ирнэ. Тухайлбал: иргэний үнэмлэх, диплом, мэргэжлийн үнэмлэх г.м
            </div>
            <div>Харилцах утас: 757-73573</div>
          </div>

          <div className="mt-4 flex items-center justify-center">
            {is_active && (
              <div
                className="w-48 h-10 flex items-center justify-center bg-gradient-to-r from-green-700 to-blue-600 text-white font-semibold rounded-lg cursor-pointer hover:scale-90 duration-300"
                onClick={() => {
                  if (!cv.id) {
                    message(
                      "info",
                      "Танд анкетийн мэдээлэл олдсонгүй. Эхлээд анкет бөглөнө үү."
                    );
                  } else {
                    Swal.fire({
                      title: "Итгэлтэй байна уу?",
                      text: "Та анкетаа бүрэн зөв, гүйцэт бөглөсөн эсэхээ шалгаарай.",
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonColor: "#1890ff",
                      cancelButtonColor: "rgb(244, 106, 106)",
                      confirmButtonText: "Тийм",
                      cancelButtonText: "Үгүй",
                      reverseButtons: true,
                      footer:
                        '<a href="/cv" className="text-red-500">Анкет руу очих</a>',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        API.POST("applicant", {
                          vacancy_id: id,
                        }).then((res) => {
                          if (res.status_code === 1) {
                            message(
                              "success",
                              "Таны анкетыг амжилттай илгээлээ."
                            );
                          } else message("error", res.response);
                        });
                      }
                    });
                  }
                }}
              >
                <BiMailSend size={20} />
                <span className="ml-2">Анкет илгээх</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default React.memo(ID);
