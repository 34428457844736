/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { useUserContext } from "src/context/userContext";
import * as ERP_API from "src/api/epr_request";

function CreateBox() {
  const [registry, setRegistry] = useState(false)
  const { user, userDispatch } = useUserContext();
  const [btnLoader, setBtnLoader] = useState(false)
  const fetch_companies = async (user) => {
    try {
      const civil_id = user?.info?.civil_id;
      if (!civil_id) {
        console.error("Civil ID is undefined");
        return;
      }
      const res = await ERP_API.getСompanies(civil_id);
      await userDispatch({ companies: res });
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };
  return (
    <div>
      <label htmlFor="add_profile" className="block text-sm/6 font-medium text-gray-700 dark:text-white">
        Шинээр нэмэх
      </label>
      <div className="mt-2 shadow-md rounded-xl">
        <div className="flex items-center rounded-xl bg-white dark:bg-slate-700 pl-3 outline-1 -outline-offset-1 outline-gray-300 has-[input:focus-within]:outline-2 has-[input:focus-within]:-outline-offset-2 has-[input:focus-within]:outline-indigo-600">
          <input
            id="price"
            name="price"
            type="text"
            placeholder="Байгууллагын регистер оруулна уу ...."
            onChange={(e) => {
              setRegistry(e.target.value)

            }}
            className="block min-w-0 grow py-1.5 pr-3 pl-1 rounded-xl text-base placeholder:text-gray-500 dark:placeholder:text-gray-400 bg-white dark:bg-slate-700 focus:outline-none sm:text-sm/6"
          />
          <div className="grid shrink-0 grid-cols-1 focus-within:relative">
            <button type="button" onClick={async () => {
              if (!btnLoader && registry) {
                setBtnLoader(true)
                const data = {
                  company_reg: btoa(unescape(encodeURIComponent(registry))),
                  user_num: btoa(unescape(encodeURIComponent(user?.info?.civil_id)))
                }
                const is_login = await ERP_API.isLogin()
                if (localStorage.getItem("user_token") && is_login) {
                  await ERP_API.PORTAL_AUTH('/portal_auth', data).then(async (res) => {
                    await fetch_companies(user)
                    userDispatch({
                      showAlert: {
                        type: "success",
                        message: "Амжилттай нэмэгдлээ.",
                      }
                    })
                  }).catch(async (res) => {
                    await userDispatch({
                      showAlert: {
                        type: "error",
                        message: res?.response?.data?.error,
                      }
                    })

                  });
                  userDispatch({ profileDialog: false })
                  setBtnLoader(false)
                }
              }

            }}
              className="text-white bg-green-700 rounded-br-xl rounded-tr-xl hover:bg-green-800 focus:ring-2 focus:ring-green-200 font-medium text-sm px-5 py-2.5  focus:outline-none">
              {btnLoader ? <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
              </svg> : 'Нэмэх'}
            </button>
          </div>
        </div>
      </div>
    </div >
  )
}


export default function ProfileDialog() {
  const { user, userDispatch } = useUserContext();

  return (
    <Dialog open={user.profileDialog ?? false} onClose={() => { userDispatch({ profileDialog: false }) }} className="relative z-10 ">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/60 dark:bg-white-400/20 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in "
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto ">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-xl text-left shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-lg data-closed:sm:translate-y-0 data-closed:sm:scale-95"
          >
            <div className="bg-white/90 rounded-xl dark:bg-slate-950 dark:border dark:border-slate-800 hove:bg-opacity-30 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
              <div className="w-full p-4 text-gray-900 sm:p-6 rounded-xl">
                <h5 className="mb-3 text-sm font-semibold text-gray-900 md:text-md dark:text-white">
                  ХЭРЭГЛЭГЧ СОЛИХ
                </h5>
                <p className="text-xs font-normal text-gray-500 dark:text-gray-400">Та доорх хэрэглэгчдээс сонгон системийг ашиглах боломжтой</p>
                <ul className="my-4 space-y-3">

                  {/* USER PROFILE */}
                  <label htmlFor="user" className="block text-sm/6 font-medium text-gray-700 dark:text-white">
                    Хувь хүн
                  </label>
                  <li onClick={async () => {
                    if (localStorage.getItem('company_registry')) {
                      const data = {
                        user_num: btoa(unescape(encodeURIComponent(user?.info?.civil_id)))
                      }
                      const is_login = await ERP_API.isLogin()
                      if (is_login) {
                        ERP_API.PORTAL_AUTH('/portal_auth', data).then(async (res) => {
                          console.log(res)
                          const res_data = res?.data
                          localStorage.setItem('user_token', res_data?.token)
                          localStorage.removeItem('company_name')
                          localStorage.removeItem('company_registry')
                          userDispatch({
                            showAlert: {
                              type: "success",
                              message: `${user?.info?.first_name} амжилттай нэвтэрлээ.`,
                            }
                          })
                          userDispatch({ profileDialog: false })
                        })
                          .catch((res) => {
                            console.log(res)
                            // localStorage.clear()
                            // window.location.replace("/login");
                          });
                      }

                    }

                  }}>
                    <div className="flex items-center p-3 text-base font-bold text-gray-900 rounded-xl bg-gray-100 shadow-md transition-all duration-100 hover:scale-97 active:scale-90 hover:text-gray-800 hover:bg-gray-100 group hover:shadow hover:cursor-pointer  dark:bg-slate-700 dark:hover:bg-slate-600 dark:text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                      </svg>

                      <span className="flex-1 ms-3 text-sm whitespace-nowrap">{user?.info?.last_name} {user?.info?.first_name} ({user?.info?.ssnid?.toUpperCase()})</span>
                      {localStorage.getItem('company_registry') ? '' : <span className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-xs  font-medium text-white opacity-80 bg-green-700 rounded-md">
                        Сонгогдсон
                      </span>}
                    </div>
                  </li>

                  {/* COMPANY PROFILE LIST */}
                  <label htmlFor="user" className="block text-sm/4 font-medium text-gray-700 dark:text-white">
                    Байгууллага
                  </label>
                  {!user?.companies ? <li>
                    <div className="flex justify-center text-xs text-red-300">
                      Одоогоор байгууллага бүртгэгдээгүй байна.
                    </div>
                  </li> : ''}

                  {user?.companies?.map((company) => {
                    return (<li key={company.key} onClick={async () => {
                      if (localStorage.getItem('company_registry') !== company.registry) {
                        const data = {
                          company_reg: btoa(unescape(encodeURIComponent(company?.registry))),
                          user_num: btoa(unescape(encodeURIComponent(user?.info?.civil_id.toUpperCase())))
                        }
                        const is_login = await ERP_API.isLogin()
                        if (localStorage.getItem("user_token") && is_login) {
                          ERP_API.PORTAL_AUTH('/portal_auth', data).then((res) => {
                            console.log(res)
                            const res_data = res?.data
                            localStorage.setItem('user_token', res_data?.token)
                            localStorage.setItem('company_name', company.name)
                            localStorage.setItem('company_registry', company.registry)
                            userDispatch({
                              showAlert: {
                                type: "success",
                                message: `Та байгууллагын эрхээр нэвтэрлээ. "${company.name}" `,
                              }
                            })
                            userDispatch({ profileDialog: false })
                          })
                            .catch((res) => {
                              console.log(res)
                              // localStorage.clear()
                              // window.location.replace("/login");
                            });
                        }

                      }

                    }}>
                      <div className="flex items-center p-3 text-base font-bold text-gray-900 rounded-xl bg-gray-100 shadow-md transition-all duration-100 hover:scale-97 active:scale-90 hover:text-gray-800 hover:bg-gray-100 group hover:shadow hover:cursor-pointer  dark:bg-slate-700 dark:hover:bg-slate-600 dark:text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                        </svg>
                        <span className="flex-1 ms-3 text-sm whitespace-nowrap">{company.name} ({company.registry})</span>
                        {localStorage.getItem('company_registry') === company.registry ? < span className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-xs  font-medium text-white opacity-80 bg-green-700 rounded-md">
                          Сонгогдсон
                        </span> : ""}
                      </div>
                    </li>)
                  })}

                </ul>
                {/* Add new company */}
                <CreateBox />
              </div>
            </div>

          </DialogPanel>
        </div>
      </div >
    </Dialog >
  )
}
