import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import * as API from "src/api/request";
import Loader from "src/components/layout_main/loader";

const Login = () => {
  const { search } = useLocation();
  let params = queryString.parse(search);

  if (params.error) {
    window.location = "https://digital.erdenetmc.mn";
  }

  useLayoutEffect(() => {
    if (params?.state && params.client_nonce) {
      API.POST("/dan/auth", {
        state: params?.state,
        client_nonce: params.client_nonce,
      })
        .then((res) => {
          localStorage.clear();
          localStorage.setItem("type", "Bearer");
          localStorage.setItem("token", res?.token);
          console.log(res?.system_token)
          localStorage.setItem('system_token', res?.system_token)
          window.location.replace("/");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params?.state, params.client_nonce]);

  return <Loader />;
};

export default React.memo(Login);
