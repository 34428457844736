import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";
import * as API from "src/api/request";
import {
  DeleteButton,
  SaveButton,
  DownloadButton,
} from "../../components/button";

import _ from "lodash";

import { Spin, Upload, Input, DatePicker, Select } from "antd";
import dayjs from "dayjs";

const Person = () => {
  const { message } = useUserContext();
  const { state, dispatch } = useCVContext();
  const [refresh, set_refresh] = useState(0);

  const [loading, set_loading] = useState(false);
  const [reference_city_all, set_reference_city_all] = useState([]);
  const [reference_country, set_reference_country] = useState([]);
  const [reference_city, set_reference_city] = useState([]);
  const [reference_birth_district, set_reference_birth_district] = useState([]);
  const [reference_district, set_reference_district] = useState([]);
  const [reference_bag, set_reference_bag] = useState([]);
  const [reference_gender, set_reference_gender] = useState([]);
  const [reference_nationality, set_reference_nationality] = useState([]);
  const [reference_married, set_reference_married] = useState([]);
  const [reference_house_type, set_reference_house_type] = useState([]);
  const [reference_house_owner, set_reference_house_owner] = useState([]);
  const [reference_room, set_reference_room] = useState([]);
  const [reference_emergency_person_type, set_reference_emergency_person_type] =
    useState([]);

  useEffect(() => {
    API.GET("reference", { model_name: "res.country" }).then((res) => {
      if (res.status_code === 1) {
        set_reference_country(res.response);
      } else message("error", res.response);
    });

    API.GET("reference", { model_name: "emc.hr.city" }).then((res) => {
      if (res.status_code === 1) {
        set_reference_city_all(res.response);
        // set_reference_country(
        //   _.filter(res.response, (item) => {
        //     if (_.startsWith(item.code, "99")) {
        //       return item;
        //     }
        //   })
        // );
        set_reference_city(_.filter(res.response, (a) => a.level === 2));
      } else message("error", res.response);
    });

    API.GET("reference/selection", {
      model_name: "emc.cv",
      field_name: "gender",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_gender(res.response);
      } else message("error", res.response);
    });

    API.GET("reference", { model_name: "emc.hr.nationality" }).then((res) => {
      if (res.status_code === 1) {
        set_reference_nationality(res.response);
      } else message("error", res.response);
    });

    API.GET("reference/selection", {
      model_name: "emc.cv",
      field_name: "married_id",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_married(res.response);
      } else message("error", res.response);
    });

    API.GET("reference/selection", {
      model_name: "emc.cv",
      field_name: "house_type_id",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_house_type(res.response);
      } else message("error", res.response);
    });

    API.GET("reference/selection", {
      model_name: "emc.cv",
      field_name: "house_owner_id",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_house_owner(res.response);
      } else message("error", res.response);
    });

    API.GET("reference/selection", {
      model_name: "emc.cv",
      field_name: "room_id",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_room(res.response);
      } else message("error", res.response);
    });

    API.GET("reference", {
      model_name: "emc.hr.family.type",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_emergency_person_type(res.response);
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    await API.GET("cv")
      .then((res) => {
        if (res.status_code === 1) {
          var data = res.response;
          _.mapKeys(data, (value, key) => {
            if (value === false) {
              data[key] = null;
            }
          });

          if (res.response.photo && res.response.photo.length > 0) {
            fetch(res.response.photo[0].minio_url).then((response) => {
              const blob = response.blob();
              const file = new File([blob], res.response.photo[0].name, {
                type: "image/png",
              });
              dispatch({
                photo: file,
                photo_url: res.response.photo[0].minio_url,
              });
            });
          }

          dispatch({
            ...data,
            photo: null,
            photo_url: null,
            civil_country_id: res.response.civil_country_id
              ? res.response.civil_country_id[0]
              : null,
            nation_id: res.response.nation_id
              ? res.response.nation_id[0]
              : null,
            birthday: res.response.birthday
              ? dayjs(res.response.birthday)
              : null,
            birth_city_id: res.response.birth_city_id
              ? res.response.birth_city_id[0]
              : null,
            birth_district_id: res.response.birth_district_id
              ? res.response.birth_district_id[0]
              : null,
            city_id: res.response.city_id ? res.response.city_id[0] : null,
            district_id: res.response.district_id
              ? res.response.district_id[0]
              : null,
            bag_id: res.response.bag_id ? res.response.bag_id[0] : null,
            emergency_person_type: res.response.emergency_person_type
              ? res.response.emergency_person_type[0]
              : null,
          });
        } else message("error", res.response);
      })
      .finally(() => {
        set_loading(false);
      });
  };

  useEffect(() => {
    set_loading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    var result = [];
    if (state.birth_city_id) {
      var item = _.find(reference_city, (a) => a.id === state.birth_city_id);
      if (item) {
        result = _.filter(
          reference_city_all,
          (a) => _.startsWith(a.code, item.code) && a.level === 4
        );
      }
    }
    set_reference_birth_district(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference_city_all, state.birth_city_id]);

  useEffect(() => {
    var result = [];
    if (state.city_id) {
      var item = _.find(reference_city_all, (a) => a.id === state.city_id);
      if (item) {
        result = _.filter(
          reference_city_all,
          (a) => _.startsWith(a.code, item.code) && a.level === 4
        );
      }
    }
    set_reference_district(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference_city_all, state.city_id]);

  useEffect(() => {
    var result = [];
    if (state.district_id) {
      var item = _.find(reference_city_all, (a) => a.id === state.district_id);
      if (item) {
        result = _.filter(
          reference_city_all,
          (a) => _.startsWith(a.code, item.code) && a.level === 6
        );
      }
    }
    set_reference_bag(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference_city_all, state.district_id]);

  const upload_file = async (
    model_name,
    res_id,
    field_name,
    upload_type,
    fileList
  ) => {
    _.map(fileList, (item) => {
      const formData = new FormData();
      formData.append("model_name", model_name);
      formData.append("res_id", res_id);
      formData.append("field_name", field_name);
      formData.append("upload_type", upload_type);
      formData.append("files", item?.originFileObj || item);
      API.UPLOAD_FILE("/file/upload", formData);
    });
  };

  return (
    <Spin spinning={loading}>
      <details className="border rounded-lg" open>
        <summary className="p-1 bg-gray-200 font-semibold border">
          Ерөнхий мэдээлэл
        </summary>

        <div className="p-2 flex flex-col gap-2">
          <div className="flex gap-5">
            <Upload
              showUploadList={false}
              beforeUpload={() => false}
              multiple={false}
              accept=".png, .jpeg, .jpg"
              file={state.photo}
              onChange={(info) => {
                const file = new Blob([info.file], {
                  type: "image/png",
                });
                const fileURL = URL.createObjectURL(file);
                dispatch({ photo: info.file, photo_url: fileURL });
              }}
            >
              <div className="w-24 h-52 md:h-36 flex items-center justify-center border rounded-lg cursor-pointer bg-gray-100">
                {state.photo_url ? (
                  <img
                    src={state.photo_url}
                    className="w-full h-full object-cover"
                    alt=""
                  />
                ) : (
                  <div className="font-semibold">
                    Цээж <br /> зураг
                  </div>
                )}
              </div>
            </Upload>

            <div className="flex-1 flex flex-col gap-2">
              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <span className="md:w-56">
                  Ургийн овог: <b className="text-red-500">*</b>
                </span>
                <Input
                  allowClear
                  status={state.submit && !state.family_name && "error"}
                  value={state.family_name}
                  onChange={(e) => dispatch({ family_name: e.target.value })}
                />
              </div>

              <hr />

              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <span className="md:w-56">
                  Эцэг (эх)-ийн нэр: <b className="text-red-500">*</b>
                </span>
                <Input
                  allowClear
                  status={state.submit && !state.last_name && "error"}
                  value={state.last_name}
                  onChange={(e) => dispatch({ last_name: e.target.value })}
                />
              </div>

              <hr />

              <div className="flex flex-col md:flex-row md:items-center gap-1">
                <span className="md:w-56">
                  Өөрийн нэр: <b className="text-red-500">*</b>
                </span>
                <Input
                  allowClear
                  status={state.submit && !state.name && "error"}
                  value={state.name}
                  onChange={(e) => dispatch({ name: e.target.value })}
                />
              </div>

              <hr />
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Регистрийн дугаар: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              status={state.submit && !state.ssnid && "error"}
              value={state.ssnid}
              onChange={(e) => dispatch({ ssnid: e.target.value })}
            />
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Төрсөн огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              className="w-full"
              allowClear
              status={state.submit && !state.birthday && "error"}
              value={state.birthday}
              onChange={(value) => dispatch({ birthday: dayjs(value) })}
            />
          </div>
          <hr />
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Хүйс: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.gender && "error"}
              value={state.gender}
              onChange={(value) => dispatch({ gender: value })}
            >
              {_.map(reference_gender, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">Иргэншил:</span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.civil_country_id && "error"}
              value={state.civil_country_id}
              onChange={(value) => dispatch({ civil_country_id: value })}
            >
              {_.map(reference_country, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Үндэс, угсаа: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.nation_id && "error"}
              value={state.nation_id}
              onChange={(value) => dispatch({ nation_id: value })}
            >
              {_.map(reference_nationality, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <hr />
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <div className="md:w-96">
              Гэрлэсэн эсэх: <b className="text-red-500">*</b>
            </div>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.married_id && "error"}
              value={state.married_id}
              onChange={(value) => dispatch({ married_id: value })}
            >
              {_.map(reference_married, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <hr />
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Утасны дугаар 1: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              status={state.submit && !state.mobile && "error"}
              value={state.mobile}
              onChange={(e) => dispatch({ mobile: e.target.value })}
            />
          </div>
          <hr />
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">Утасны дугаар 2:</span>
            <Input
              allowClear
              value={state.mobile_alt}
              onChange={(e) => dispatch({ mobile_alt: e.target.value })}
            />
          </div>
          <hr />
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Цахим хаяг: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              status={state.submit && !state.email && "error"}
              value={state.email}
              onChange={(e) => dispatch({ email: e.target.value })}
            />
          </div>
        </div>
      </details>

      <details className="mt-4 border rounded-lg" open>
        <summary className="p-1 bg-gray-200 font-semibold border">
          Төрсөн газрын мэдээлэл
        </summary>

        <div className="p-2 flex flex-col gap-2">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Аймаг, хот: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.birth_city_id && "error"}
              value={state.birth_city_id}
              onChange={(value) => {
                dispatch({
                  birth_city_id: value,
                  birth_district_id: null,
                });
              }}
            >
              {_.map(reference_city, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Сум, дүүрэг: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.birth_district_id && "error"}
              value={state.birth_district_id}
              onChange={(value) => dispatch({ birth_district_id: value })}
            >
              {_.map(reference_birth_district, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row gap-1">
            <span className="md:w-96">
              Газар: <b className="text-red-500">*</b>
            </span>
            <Input.TextArea
              allowClear
              status={state.submit && !state.birth_place && "error"}
              value={state.birth_place}
              onChange={(e) => dispatch({ birth_place: e.target.value })}
            />
          </div>
        </div>
      </details>

      <details className="mt-4 border rounded-lg" open>
        <summary className="p-1 bg-gray-200 font-semibold border">
          Оршин суугаа хаяг
        </summary>

        <div className="p-2 flex flex-col gap-2">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Аймаг, хот: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.city_id && "error"}
              value={state.city_id}
              onChange={(value) =>
                dispatch({
                  city_id: value,
                  district_id: null,
                  bag_id: null,
                })
              }
            >
              {_.map(reference_city, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Сум, дүүрэг: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.district_id && "error"}
              value={state.district_id}
              onChange={(value) => {
                dispatch({ district_id: value, bag_id: null });
              }}
            >
              {_.map(reference_district, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Баг, хороо: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.bag_id && "error"}
              value={state.bag_id}
              onChange={(value) => dispatch({ bag_id: value })}
            >
              {_.map(reference_bag, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row gap-1">
            <span className="md:w-96">
              Хаяг: <b className="text-red-500">*</b>
            </span>
            <Input.TextArea
              allowClear
              status={state.submit && !state.private_street && "error"}
              value={state.private_street}
              onChange={(e) => dispatch({ private_street: e.target.value })}
            />
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <div className="md:w-96">
              Орон сууцны нөхцөл: <b className="text-red-500">*</b>
            </div>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.house_type_id && "error"}
              value={state.house_type_id}
              onChange={(value) => dispatch({ house_type_id: value })}
            >
              {_.map(reference_house_type, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Өмчлөлийн хэлбэр: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.house_owner_id && "error"}
              value={state.house_owner_id}
              onChange={(value) => dispatch({ house_owner_id: value })}
            >
              {_.map(reference_house_owner, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Өрөөний тоо: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.room_id && "error"}
              value={state.room_id}
              onChange={(value) => dispatch({ room_id: value })}
            >
              {_.map(reference_room, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </details>

      <details className="mt-4 border rounded-lg" open>
        <summary className="p-1 bg-gray-200 font-semibold border">
          Зайлшгүй шаардлагатай үед холбоо барих хүн
        </summary>

        <div className="p-2 flex flex-col gap-2">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Хэн болох: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={state.submit && !state.emergency_person_type && "error"}
              value={state.emergency_person_type}
              onChange={(value) => dispatch({ emergency_person_type: value })}
            >
              {_.map(reference_emergency_person_type, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr />

          <div className="mt-2 flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Овог, нэр: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              status={state.submit && !state.emergency_person_name && "error"}
              value={state.emergency_person_name}
              onChange={(e) =>
                dispatch({
                  emergency_person_name: e.target.value,
                })
              }
            />
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              Утасны дугаар 1: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              status={state.submit && !state.emergency_person_mobile && "error"}
              value={state.emergency_person_mobile}
              onChange={(e) =>
                dispatch({
                  emergency_person_mobile: e.target.value,
                })
              }
            />
          </div>

          <hr />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">Утасны дугаар 2:</span>
            <Input
              allowClear
              value={state.emergency_person_mobile_alt}
              onChange={(e) =>
                dispatch({
                  emergency_person_mobile_alt: e.target.value,
                })
              }
            />
          </div>
        </div>
      </details>

      <details className="mt-4 border rounded-lg" open>
        <summary className="p-1 bg-gray-200 font-semibold border">
          Хавсралт
        </summary>

        <div className="mt-1 p-1 text-xs italic">
          Ажил эрхлэлттэй холбоотой бусад баримтууд оруулна уу. (Нийгмийн
          даатгалын дэвтрийн хуулбар, E-Mongolia нийгмийн даатгалын лавлагаа ...
          гэх мэт)
        </div>

        <div className="p-2 flex flex-col gap-2">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-96">
              <Upload
                className="w-full"
                showUploadList={false}
                beforeUpload={() => false}
                multiple={true}
                //   listType="picture"
                accept=".pdf, .jpeg, .png, .jpg"
                showRemoveIcon={true}
                fileList={state.fileList}
                onChange={(info) => {
                  dispatch({
                    fileList: info.fileList,
                  });
                }}
              >
                <div className="px-2 py-1 bg-gray-100 border rounded-lg cursor-pointer">
                  Файл оруулах
                </div>
              </Upload>
            </span>
            <div className="w-full flex flex-wrap gap-2">
              {_.map(state?.attachments, (item, index) => {
                return (
                  <div
                    className="min-w-44 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                      <DeleteButton
                        onClick={async () => {
                          await API.DELETE("/file/upload/" + item.id).then(
                            (res) => {
                              if (res.status_code === 1) {
                                message("success", "Амжилттай устгалаа");
                                dispatch({
                                  attachments: _.without(
                                    state.attachments,
                                    item
                                  ),
                                });
                                set_refresh((prev) => prev + 1);
                              }
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {_.map(state.fileList, (item, index) => {
                return (
                  <div
                    className="p-2 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>
                      {state.attachments.length + index + 1}. {item.name}
                    </span>
                    <DeleteButton
                      onClick={() => {
                        dispatch({
                          fileList: _.without(state.fileList, item),
                        });
                        message("success", "Амжилттай устгалаа");
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </details>

      <SaveButton
        className="mt-4 w-full"
        onClick={() => {
          dispatch({ submit: true });

          var error = false;
          if (
            !state.ssnid ||
            !state.civil_country_id ||
            !state.family_name ||
            !state.last_name ||
            !state.name ||
            !state.birthday ||
            !state.gender ||
            !state.nation_id ||
            !state.married_id ||
            !state.mobile ||
            !state.email ||
            !state.birth_city_id ||
            !state.birth_district_id ||
            !state.birth_place ||
            !state.city_id ||
            !state.district_id ||
            !state.bag_id ||
            !state.private_street ||
            !state.house_type_id ||
            !state.house_owner_id ||
            !state.room_id ||
            !state.emergency_person_name ||
            !state.emergency_person_type ||
            !state.emergency_person_mobile
          ) {
            error = true;
            message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
          }

          if (!error) {
            var data = {
              ssnid: state.ssnid ? state.ssnid : null,
              civil_country_id: state.civil_country_id
                ? state.civil_country_id
                : null,
              family_name: state.family_name || null,
              last_name: state.last_name || null,
              name: state.name || null,
              gender: state.gender || null,
              birthday: state.birthday
                ? dayjs(state.birthday).format("YYYY-MM-DD")
                : null,
              birth_city_id: state.birth_city_id || null,
              birth_district_id: state.birth_district_id || null,
              birth_place: state.birth_place || null,
              nation_id: state.nation_id || null,
              married_id: state.married_id || null,

              city_id: state.city_id || null,
              district_id: state.district_id || null,
              bag_id: state.bag_id || null,
              private_street: state.private_street || null,
              house_type_id: state.house_type_id || null,
              house_owner_id: state.house_owner_id || null,
              room_id: state.room_id || null,
              mobile: state.mobile || null,
              mobile_alt: state.mobile_alt || null,
              email: state.email || null,
              emergency_person_name: state.emergency_person_name || null,
              emergency_person_type: state.emergency_person_type || null,
              emergency_person_mobile: state.emergency_person_mobile || null,
              emergency_person_mobile_alt:
                state.emergency_person_mobile_alt || null,
            };

            if (!state.id) {
              set_loading(true);
              API.POST("cv", data)
                .then((res) => {
                  if (res.status_code === 1) {
                    if (state?.photo) {
                      //   const formData = new FormData();
                      //   formData.append("res_id", parseInt(res.id));
                      //   formData.append("model_name", "emc.cv");
                      //   formData.append("field_name", "photo");
                      //   formData.append("files", state?.photo);
                      //   formData.append("upload_type", "many2one");
                      //   API.UPLOAD_FILE("/file/upload", formData);

                      upload_file(
                        "emc.cv",
                        parseInt(res.id),
                        "photo",
                        "many2one",
                        [state.photo]
                      );
                    }

                    upload_file(
                      "emc.cv",
                      state.id,
                      "attachments",
                      "many2many",
                      state.fileList
                    );

                    setTimeout(() => {
                      set_refresh((prev) => prev + 1);
                      dispatch({ fileList: [] });
                      message("success", res.response);
                    }, 1000);
                  } else {
                    message("error", res.response);
                  }
                })
                .finally(() => set_loading(false));
            } else {
              set_loading(true);
              API.PUT("cv/" + state.id, data)
                .then((res) => {
                  if (res.status_code === 1) {
                    if (state?.photo) {
                      //   const formData = new FormData();
                      //   formData.append("res_id", state.id);
                      //   formData.append("model_name", "emc.cv");
                      //   formData.append("field_name", "photo");
                      //   formData.append("files", state?.photo);
                      //   formData.append("upload_type", "many2one");
                      //   API.UPLOAD_FILE("/file/upload", formData);

                      upload_file("emc.cv", state.id, "photo", "many2one", [
                        state.photo,
                      ]);
                    }

                    upload_file(
                      "emc.cv",
                      state.id,
                      "attachments",
                      "many2many",
                      state.fileList
                    );

                    setTimeout(() => {
                      set_refresh((prev) => prev + 1);
                      dispatch({ fileList: [] });
                      message("success", res.response);
                    }, 1000);
                  } else message("error", res.response);
                })
                .finally(() => set_loading(false));
            }
          }
        }}
      />
    </Spin>
  );
};

export default React.memo(Person);
