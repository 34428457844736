import React, { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import _ from "lodash";
import { InputNumber, Modal, Spin } from "antd";
import * as API from "src/api/request";
import { useUserContext } from "src/context/userContext";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import axios from "axios";

const RateModal = ({ state, setState }) => {
  const ref_first = useRef({});
  const ref_second = useRef({});
  const { message } = useUserContext();

  const onChange = (type, kpi_id, value) => {
    var result = state?.employee_kpi;
    var index = _.findIndex(result, { kpi_id: kpi_id });
    if (type === 1) {
      result[index].worker_percent = value;
    } else if (type === 2) {
      result[index].master_percent = value;
    }
    setState((old) => ({ ...old, employee_kpi: result }));
  };

  const save = async (type, data, e) => {
    var percent = e.target.value;
    if (percent) {
      if (percent > 100) percent = 100;
      if (percent < 0) percent = 0;

      if (data?.id) {
        await put(type, data, percent);
      } else {
        await post(type, data, percent);
      }
    }
  };

  const next_focus = async (type, data) => {
    const ref = type === 1 ? ref_first.current : ref_second.current;
    if (!ref) {
      return;
    }

    const keys = Object.keys(ref);
    const prefix = type === 1 ? "1_" : "2_";
    const currentKey = prefix + data?.kpi_id;
    const index = keys.indexOf(currentKey);

    if (index === -1) {
      return;
    }

    const nextKey = keys[index + 1];
    if (nextKey && ref[nextKey]) {
      await ref[nextKey].focus();
    } else if (keys[0] && ref[keys[0]]) {
      await ref[keys[0]].focus();
    }
  };

  const fetch_list = async () => {
    try {
      await setState((old) => ({
        ...old,
        modal_loading: true,
      }));
      if (state?.row?.employee_id) {
        var res = await API.GET("/kpi/employee", {
          employee_id: state?.row?.employee_id,
          year: state?.date?.format("YYYY"),
          month: state?.date?.format("MM"),
        });

        if (res?.status_code) {
          await setState((old) => ({
            ...old,
            employee_kpi: res?.response,
          }));
        }
      }
    } catch (error) {
      message("warning", "Анхааруулга", error?.message);
    } finally {
      await setState((old) => ({
        ...old,
        modal_loading: false,
      }));
    }
  };

  const post = async (type, data, percent) => {
    try {
      if (!state?.row?.employee_id) {
        message("warning", "Анхааруулга", "Ажилтан олдсонгүй.");
        return false;
      }
      if (!data?.kpi_id) {
        message("warning", "Анхааруулга", "Үнэлгээний ID олдсонгүй.");
        return false;
      }
      setState((old) => ({
        ...old,
        modal_loading: true,
      }));
      var res = await API.POST("/kpi/employee", {
        employee_id: state?.row?.employee_id,
        kpi_id: data?.kpi_id,
        master_percent:
          type === 2 ? parseInt(percent) : parseInt(data?.master_percent),
        worker_percent:
          type === 1 ? parseInt(percent) : parseInt(data?.worker_percent),
        year: state?.date?.format("YYYY"),
        month: state?.date?.format("MM"),
      });
      if (res?.status_code) {
        await fetch_list();
        message("success", "Амжилттай", "", 1);
      } else {
        message("warning", "Амжилтгүй", "");
      }
    } catch (err) {
      message("warning", "Анхааруулга", err?.message);
    } finally {
      await setState((old) => ({
        ...old,
        modal_loading: false,
      }));
    }
  };

  const put = async (type, data, percent) => {
    try {
      if (!state?.row?.employee_id) {
        message("warning", "Анхааруулга", "Ажилтан олдсонгүй.");
        return false;
      }
      if (!data?.kpi_id) {
        message("warning", "Анхааруулга", "Үнэлгээний ID олдсонгүй.");
        return false;
      }
      setState((old) => ({
        ...old,
        modal_loading: true,
      }));
      var res = await API.PUT("/kpi/employee/" + data?.id, {
        master_percent:
          type === 2 ? parseInt(percent) : parseInt(data?.master_percent),
        worker_percent:
          type === 1 ? parseInt(percent) : parseInt(data?.worker_percent),
      });
      if (res?.status_code) {
        await fetch_list();
        message("success", "Амжилттай", "", 1);
      } else {
        message("warning", "Амжилтгүй", "");
      }
    } catch (err) {
      message("warning", "Анхааруулга", err?.message);
    } finally {
      await setState((old) => ({
        ...old,
        modal_loading: false,
      }));
    }
  };

  const remove = async (type, id) => {
    Swal.fire({
      title: "Анхааруулга!",
      text: "Та устгахдаа итгэлтэй байна уу?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "Үгүй!",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Тийм!",
      reverseButtons: true,
    }).then(async (confirm) => {
      if (confirm.isConfirmed) {
        try {
          await setState((old) => ({
            ...old,
            modal_loading: true,
          }));
          var res = await API.DELETE("/kpi/employee/" + id, {
            delete_type: type,
          });
          if (res?.status_code) {
            await fetch_list();
            message("success", "Амжилттай", "", 1);
          } else {
            message("warning", "Амжилтгүй", "");
          }
        } catch (err) {
          message("warning", "Анхааруулга", err?.message);
        } finally {
          await setState((old) => ({
            ...old,
            modal_loading: false,
          }));
        }
      }
    });
  };

  useEffect(() => {
    fetch_list();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.row?.employee_id]);

  const exportToExcel = () => {
    let Heading = [
      [
        "№",
        "Гүйцэтгэлийн гол үзүүлэлт",
        "Тооцох аргачлал",
        "Ач холбогдол %",
        "Ажилтны оноо",
        "Удирдлагын оноо",
        "Нийт оноо",
      ],
    ];
    var result = _.map(state?.employee_kpi, (a, i) => {
      return {
        i: i + 1,
        name: a.name,
        method: a.method,
        percent: a.percent,
        worker_value: a.worker_value,
        master_value: a.master_value,
        total_value: a.total_value,
      };
    });
    result.push({
      i: "",
      name: "Нийт",
      method: "",
      percent: _.sumBy(state.employee_kpi, (a) => a.percent),
      worker_value: _.sumBy(state.employee_kpi, (a) => a.worker_value),
      master_value: _.sumBy(state.employee_kpi, (a) => a.master_value),
      total_value: _.sumBy(state.employee_kpi, (a) => a.total_value),
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: "A1" });
    XLSX.utils.sheet_add_json(worksheet, result, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      "Ажилтны ГГҮ БД_" +
        state?.row?.tn +
        " " +
        dayjs().format("YYYY_MM_сар") +
        ".xlsx",
      {
        compression: true,
      }
    );
  };

  const exportToPdf = async () => {
    var list = _.map(state?.employee_kpi, (el) => {
      return {
        name: el.name,
        method_name: el.method,
        percent: el.percent,
        value: el.total_value,
      };
    });

    var json = {
      type: "one",
      export_type: "pdf",
      text:
        state?.date?.format("YYYY") +
        " ОНЫ " +
        state?.date?.format("MM") +
        " САРЫН АЖИЛТНЫ ГҮЙЦЭТГЭЛИЙН ГОЛ ҮЗҮҮЛЭЛТИЙН ХҮСНЭГТ",
      list: list,
      department_name: state?.row?.master_department_name,
      section_name: state?.row?.section_department_name,
      employee_name: state?.row?.name,
      job_name: state?.row?.job_name,
      sum_percent: _.sumBy(state?.employee_kpi, (a) => a.percent),
      sum_value: _.sumBy(state?.employee_kpi, (a) => a.total_value),
    };
    const res = await axios.post(
      "https://portal.erdenetmc.mn/api/kpi/employee/report",
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      }
    );

    const fileUrl = URL.createObjectURL(res?.data);
    setState((old) => ({ ...old, file_url: fileUrl, file_modal: true }));
  };

  const cellRender = (type, data) => {
    if (type === 1) {
      if (data?.state === "draft") {
        if (
          (data?.is_owner && data?.can_conclude) ||
          (!data?.is_owner && data?.can_conclude)
        ) {
          return (
            <div className="flex flex-row items-center justify-center gap-2">
              <InputNumber
                key={`input1_${data?.kpi_id}`}
                ref={(a) => {
                  if (a) {
                    ref_first.current["1_" + data?.kpi_id] = a;
                  } else {
                    delete ref_first.current["1_" + data?.kpi_id];
                  }
                }}
                step={10}
                min={0}
                max={100}
                type="number"
                style={{ textAlign: "center" }}
                value={data?.worker_percent || ""}
                onChange={(value) => {
                  if (!state?.modal_loading) {
                    onChange(1, data?.kpi_id, value);
                  }
                }}
                onPressEnter={async (e) => {
                  if (e.key === "Enter" && !state?.modal_loading) {
                    await next_focus(1, data);
                  }
                }}
                onBlur={async (e) => {
                  await save(1, data, e);
                }}
              />

              <button
                tabIndex={-1}
                className={`p-1 ${
                  data?.worker_percent ? "flex" : "hidden"
                } items-center justify-center font-semibold text-red-500 rounded-full border-2 border-red-500 hover:bg-red-500 hover:scale-125 hover:text-white focus:outline-none duration-300`}
                onClick={() => {
                  remove("worker_percent", data?.id);
                }}
              >
                <i className="fe fe-trash-2" />
              </button>
            </div>
          );
        } else {
          return data?.worker_percent;
        }
      } else {
        return data?.worker_percent;
      }
    } else if (type === 2) {
      if (data?.state === "draft") {
        if (
          (!data?.is_owner && data?.can_conclude) ||
          (!data?.is_owner && !data?.can_conclude)
        ) {
          return (
            <div className="flex flex-row items-center justify-center gap-2">
              <InputNumber
                key={`input2_${data?.kpi_id}`}
                ref={(a) => {
                  if (a) {
                    ref_second.current["2_" + data?.kpi_id] = a;
                  } else {
                    delete ref_second.current["2_" + data?.kpi_id];
                  }
                }}
                step={10}
                min={0}
                max={100}
                type="number"
                style={{ textAlign: "center" }}
                value={data?.master_percent || ""}
                onChange={(value) => {
                  if (!state?.modal_loading) {
                    onChange(2, data?.kpi_id, value);
                  }
                }}
                onPressEnter={async (e) => {
                  if (e.key === "Enter" && !state?.modal_loading) {
                    await next_focus(2, data);
                  }
                }}
                onBlur={async (e) => {
                  await save(2, data, e);
                }}
              />

              <button
                tabIndex={-1}
                className={`p-1 ${
                  data?.master_percent ? "flex" : "hidden"
                } items-center justify-center font-semibold text-red-500 rounded-full border-2 border-red-500 hover:bg-red-500 hover:scale-125 hover:text-white focus:outline-none duration-300`}
                onClick={() => {
                  remove("master_percent", data?.id);
                }}
              >
                <i className="fe fe-trash-2" />
              </button>
            </div>
          );
        } else {
          return data?.master_percent;
        }
      } else {
        return data?.master_percent;
      }
    }
  };

  return (
    <Modal
      title={
        <div className="text-center">
          {state?.date?.format("YYYY") +
            " оны " +
            state?.date?.format("MM") +
            " -р сар " +
            state?.row?.job_name +
            " " +
            state?.row?.name +
            "/" +
            state?.row?.tn +
            "/"}
        </div>
      }
      centered
      open={state?.modal}
      maskClosable={false}
      footer={false}
      onCancel={() => {
        if (!state.modal) return;
        ref_first.current = [];
        ref_second.current = [];
        setState((old) => ({
          ...old,
          modal: false,
          row: {},
          employee_kpi: [],
          list_refresh: old?.list_refresh + 1,
        }));
      }}
      className="w-full sm:w-[80%]"
    >
      <Spin spinning={state?.modal_loading}>
        <div className="flex items-center justify-between pb-4 text-sm">
          <div className="text-blue-400 italic">
            <span className="">Санамж: </span>
            <span className="font-medium">
              Үзүүлэлт бүрийг 0-с 100%-р үнэлнэ.
            </span>
          </div>

          <div className="flex items-center gap-4">
            <img
              alt=""
              title="Excel татах"
              src="/emc/excel.png"
              className="h-6 object-cover cursor-pointer hover:scale-125 duration-300"
              onClick={() => {
                exportToExcel();
              }}
            />
            <img
              alt=""
              title="Pdf татах"
              src="/emc/pdf.png"
              className="h-6 object-cover cursor-pointer hover:scale-125 duration-300"
              onClick={() => {
                exportToPdf();
              }}
            />
          </div>
        </div>
        <table className="table table-bordered text-center text-sm">
          <thead>
            <tr className="bg-gray-200">
              <th className="align-middle" rowSpan="2">
                №
              </th>
              <th className="align-middle" rowSpan="2">
                Гүйцэтгэлийн гол үзүүлэлт
              </th>
              <th className="align-middle" rowSpan="2">
                Тооцох аргачлал
              </th>
              <th className="align-middle" rowSpan="2">
                Ач холбогдол (%)
              </th>
              <th className="align-middle" colSpan="2">
                Ажилтны үнэлгээ
              </th>
              <th className="align-middle" colSpan="2">
                Удирдлагын үнэлгээ
              </th>
              <th className="align-middle" rowSpan="2">
                Нийт үнэлгээ
              </th>
            </tr>
            <tr className="bg-gray-200">
              <th className="align-middle">Хувь</th>
              <th className="align-middle">Оноо</th>
              <th className="align-middle">Хувь</th>
              <th className="align-middle">Оноо</th>
            </tr>
          </thead>
          <tbody>
            {_.map(state?.employee_kpi, (el, index) => {
              return (
                <tr key={"modal_key_" + index}>
                  <td className="align-middle">{index + 1}</td>
                  <td className="text-start align-middle">{el?.name}</td>
                  <td className="text-start align-middle">{el?.method}</td>
                  <td className="align-middle">{el?.percent}</td>
                  <td className="align-middle">{cellRender(1, el)}</td>
                  <td className="align-middle">
                    {_.round(el?.worker_value, 2)}
                  </td>
                  <td className="align-middle">{cellRender(2, el)}</td>
                  <td className="align-middle">
                    {_.round(el?.master_value, 2)}
                  </td>
                  <td className="align-middle">
                    {_.round(el?.total_value, 2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="bg-gray-200">
              <th className="align-middle self-start" colSpan={3}>
                Нийт:
              </th>
              <th className="align-middle">
                {_.sumBy(state?.employee_kpi, function (o) {
                  if (o.percent) {
                    return parseFloat(o?.percent);
                  }
                })}
              </th>
              <th className="align-middle"></th>
              <th className="align-middle">
                {_.round(
                  _.sumBy(state?.employee_kpi, function (o) {
                    if (o.percent) {
                      return parseFloat(o?.worker_value);
                    }
                  }),
                  2
                )}
              </th>
              <th className="align-middle"></th>
              <th className="align-middle">
                {_.round(
                  _.sumBy(state?.employee_kpi, function (o) {
                    if (o.percent) {
                      return parseFloat(o?.master_value);
                    }
                  }),
                  2
                )}
              </th>
              <th className="align-middle">
                {_.round(
                  _.sumBy(state?.employee_kpi, function (o) {
                    if (o.percent) {
                      return parseFloat(o?.total_value);
                    }
                  }),
                  2
                )}
              </th>
            </tr>
          </tfoot>
        </table>
      </Spin>

      <div className="border mt-6" />
      <div className="text-xs bg-yellow-50 mt-4">
        <div className="overflow-auto mt-2">
          <table className="w-full text-xs">
            <thead className="">
              <tr>
                <th className="w-36 p-1 text-center border">Үнэлгээний оноо</th>
                <th className="p-1 text-center border">Урамшууллын хэмжээ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-3 py-1 border">100 оноо</td>
                <td className="px-3 py-1 border">
                  Ажилтны урамшууллын сарын төлөвлөгөөт мөнгөн дүнг 10%-аар
                  нэмэгдүүлж олгох
                </td>
              </tr>
              <tr>
                <td className="px-3 py-1 border">90-99 оноо</td>
                <td className="px-3 py-1 border">
                  Ажилтны урамшууллын сарын төлөвлөгөөт мөнгөн дүнг олгох.
                </td>
              </tr>
              <tr>
                <td className="px-3 py-1 border">70-89 оноо</td>
                <td className="px-3 py-1 border">
                  Ажилтны урамшууллын сарын төлөвлөгөөт мөнгөн дүнг үнэлгээнд
                  харгалзах хувиар бууруулж олгоно.
                </td>
              </tr>
              <tr>
                <td className="px-3 py-1 border">69-с доош бол</td>
                <td className="px-3 py-1 border">
                  Ажилтанд ажлын үр дүнгийн сарын урамшууллыг олгохгүй.
                </td>
              </tr>
            </tbody>
          </table>
          <div className="text-blue-400 text-right italic mt-2 p-2">
            Ерөнхий захирлын 2022.08.24-ны А/518 тушаалтын 2-р хавсралт.
            <span className="font-medium ml-1">
              “Эрдэнэт үйлдвэр ТӨҮГ-ын ажилтнуудыг ажлын үр дүнгээр урамшуулах,
              шагнах тухай журам”
            </span>
            . 5-р зүйл <br />
            <span className="font-medium">
              "Ажилтны ажлын үр дүнг сар бүр ГГҮ-ээр үнэлэх, урамшуулах"
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RateModal;
