import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { LuSend, LuDownload } from "react-icons/lu";

const View = ({ state, setState, fetch_pdf }) => {
  return (
    <div className="card-body relative pt-0">
      <div className="flex pt-2 pb-2 cursor-pointer group sticky top-0 bg-white border-b">
        <div
          className="flex items-center"
          onClick={() => {
            setState((old) => ({
              ...old,
              step: 1,
              fileUrl: null,
              search: "",
              organization_id: null,
              organization_name: null,
            }));
          }}
        >
          <IoIosArrowBack
            size={26}
            className="duration-300 group-hover:text-blue-800"
          />
          <div className="text-xs sm:text-sm duration-300 group-hover:text-blue-800 group-hover:font-semibold">
            Хаах
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-2">
        <iframe
          title="PDF"
          frameBorder={0}
          src={state?.fileUrl}
          style={{
            width: "100%",
            height: window.innerWidth < 500 ? "450px" : "740px",
          }}
        />
        <div className="absolute bottom-0 left-0 mx-auto w-full">
          <div className="flex items-center justify-between gap-4 p-4">
            <div
              className="w-full flex items-center justify-center gap-2 rounded-3xl py-2 px-4 border border-blue-500 text-blue-500 shadow-xl cursor-pointer duration-300 hover:font-semibold hover:bg-blue-50"
              onClick={() => {
                // fetch_pdf(1);
              }}
            >
              <LuSend size={18} />
              <div>Илгээх</div>
            </div>
            <div
              className="w-full flex items-center justify-center gap-2 rounded-3xl py-2 px-4 bg-blue-500 text-white shadow-xl cursor-pointer duration-300 hover:bg-blue-700"
              onClick={() => {
                fetch_pdf(2);
              }}
            >
              <LuDownload size={18} />
              <div>Татах</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
