import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import * as API from "src/api/request";
import _ from "lodash";

import { Table } from "antd";

const Job = () => {
  const { user, message } = useUserContext();
  const [list, setList] = useState([]);

  useEffect(() => {
    API.GET("employee/job").then((res) => {
      if (res.status_code === 1)
        setList(_.sortBy(res.response, ["document_in_date"]).reverse());
      else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <table className="table">
        <thead>
          <tr>
            <th className="w-30 sm:w-15"></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border p-2 font-semibold">Улсад ажилласан жил:</td>
            <td className="border p-2 ">{user?.info?.sum_days}</td>
          </tr>
          <tr>
            <td className="border p-2 font-semibold">
              Үйлдвэрт ажилласан жил:
            </td>
            <td className="border p-2 ">{user?.info?.current_days}</td>
          </tr>
          <tr>
            <td className="border p-2 font-semibold">Хэвийн нөхцөлд:</td>
            <td className="border p-2 ">{user?.info?.normal_days}</td>
          </tr>
          <tr>
            <td className="border p-2 font-semibold">Хэвийн бус нөхцөлд:</td>
            <td className="border p-2">{user?.info?.abnormal_days}</td>
          </tr>
        </tbody>
      </table>

      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        dataSource={list}
        className="w-[600px] overflow-auto sm:w-full"
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          dataIndex="id"
          render={(value, record, index) => index + 1}
        />
        <Table.Column
          title="Ажилласан байгууллагын нэр"
          align="center"
          dataIndex="department_name"
        />
        <Table.Column
          title="Эрхэлсэн албан тушаал"
          align="center"
          dataIndex="job_name"
        />
        <Table.Column
          title="Ажилд орсон огноо /тушаалын дугаар/"
          align="center"
          render={(value, record, index) => {
            var result = "";
            if (record.begin_date) result += record.begin_date;
            if (record.begin_date && record.document_in_no) result += " " + record.document_in_no;
            return result;
          }}
        />
        <Table.Column
          title="Ажлаас гарсан огноо /тушаалын дугаар/"
          align="center"
          dataIndex="document_out_date"
          render={(value, record, index) => {
            var result = "";
            if (record.end_date) result += record.end_date;
            if (record.end_date && record.document_out_no)
              result += " " + record.document_out_no;
            return result;
          }}
        />
      </Table>
    </div>
  );
};

export default React.memo(Job);
