import React from "react";
import { HrContext } from "src/hr/context/hrContext";
import { Route, Routes } from "react-router-dom";
import { CVContext } from "src/hr/context/cvContext";
import Layout from "src/hr/components/layout/layout";
import Me from "src/hr/pages/employee/index";
import CV from "src/hr/pages/cv";
import Vacancy from "src/hr/pages/vacancy";
import VacancyID from "src/hr/pages/vacancy/id";
import Leave from "src/hr/pages/timeoff/leave";
import Card from "src/hr/pages/card/card";
import Rate from "src/hr/pages/rate/index";
import Definition from "src/hr/pages/definition/index";

const HR = () => {
  return (
    <HrContext>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/me" element={<Me />} />
          <Route path="/definition" element={<Definition />} />
          <Route
            path="/cv"
            element={
              <CVContext>
                <CV />
              </CVContext>
            }
          />
          <Route path="/vacancy" element={<Vacancy />} />
          <Route path="/vacancy/:id" element={<VacancyID />} />
          <Route path="/leave" element={<Leave />} />
          <Route path="/card" element={<Card />} />
          <Route path="/rate" element={<Rate />} />
        </Route>
      </Routes>
    </HrContext>
  );
};

export default React.memo(HR);
