import React from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { TbCurrencyTugrik } from "react-icons/tb";

const Step1 = ({ state, setState }) => {
  return (
    <div className="card-body flex items-center flex-col gap-4">
      <div
        className="rounded-3xl border w-full flex items-center gap-2 px-4 py-2 sm:py-4 cursor-pointer duration-300 hover:bg-gray-100 overflow-hidden"
        onClick={() => {
          setState((old) => ({
            ...old,
            step: 2,
            definition_type_id: 1,
            definition_type_name: "Ажлын газрын лавлагаа",
          }));
        }}
      >
        <IoDocumentTextOutline
          size={18}
          className="min-h-[18px] min-w-[18px]"
        />
        <div>Ажлын газрын лавлагаа</div>
      </div>
      <div
        className="rounded-3xl border w-full flex items-center gap-2 px-4 py-2 sm:py-4 cursor-pointer duration-300 hover:bg-gray-100 overflow-hidden"
        onClick={() => {
          setState((old) => ({
            ...old,
            step: 2,
            definition_type_id: 2,
            definition_type_name: "Цалингийн лавлагаа",
          }));
        }}
      >
        <TbCurrencyTugrik size={18} className="min-h-[18px] min-w-[18px]" />
        <div>Цалингийн лавлагаа</div>
      </div>
    </div>
  );
};

export default Step1;
