import React, { useContext, useReducer, useEffect } from "react";
import { reducer } from "src/hr/context/reducer";
import { notification } from "antd";
import _ from "lodash";

const context = React.createContext();

export const useHrContext = () => {
  const ctx = useContext(context);
  if (ctx === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return ctx;
};

export const HrContext = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const [state, dispatch] = useReducer(reducer, {
    tn: 0,
    info: {},
    usermenu: [
      {
        id: 1,
        name: "Миний мэдээлэл",
        pathname: "/hr/me",
      },
      {
        id: 2,
        name: "Лавлагаа",
        pathname: "/hr/definition",
      },
      {
        id: 3,
        name: "Анкет",
        pathname: "/hr/cv",
      },
      {
        id: 4,
        name: "Нээлттэй ажлын байр",
        pathname: "/hr/vacancy",
      },
      {
        id: 5,
        name: "Амралт чөлөө",
        pathname: "/hr/leave",
      },
      {
        id: 6,
        name: "Үнэмлэх",
        pathname: "/hr/card",
      },
      {
        id: 7,
        name: "ГГҮ Үнэлгээ",
        pathname: "/hr/rate",
      },
    ],
    selected_menu: {},
  });

  useEffect(() => {
    if (state?.usermenu) {
      var find_menu = _.find(state?.usermenu, {
        pathname: window?.location?.pathname,
      });
      dispatch({
        selected_menu: find_menu,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.usermenu]);

  const message = (type, title, description = null) => {
    if (type === "error") {
      api.error({
        message: title,
        description: description,
        placement: "topRight",
        duration: 5,
        showProgress: true,
      });
    }
    if (type === "info") {
      api.info({
        message: title,
        description: description,
        placement: "topRight",
        duration: 5,
        showProgress: true,
      });
    }
    if (type === "success") {
      api.success({
        message: title,
        description: description,
        placement: "topRight",
        duration: 5,
        showProgress: true,
      });
    }
    if (type === "warning") {
      api.warning({
        message: title,
        description: description,
        placement: "topRight",
        duration: 5,
        showProgress: true,
      });
    }
  };

  return (
    <context.Provider
      value={{
        hr: state,
        hrDispatch: dispatch,
        message,
      }}
    >
      {contextHolder}
      {children}
    </context.Provider>
  );
};

export default React.memo(HrContext);
