import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "src/hr/context/cvContext";
import * as API from "src/api/request";

import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
  DownloadButton,
} from "../../components/button";
import {
  Spin,
  Table,
  Modal,
  Input,
  DatePicker,
  Upload,
  TreeSelect,
} from "antd";
import _ from "lodash";
import dayjs from "dayjs";

const _form = {
  modal: false,
  submit: false,
  loading: false,
  fileList: [],

  id: null,
  name: null,
  award_date: null,
  award_type: null,
  award_no: null,
  description: null,
  attachments: [],
};

const Award = () => {
  const { message } = useUserContext();
  const { state } = useCVContext();

  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);
  const [refresh, set_refresh] = useState(1);

  const [reference_award_type, set_reference_award_type] = useState([]);

  const [form, set_form] = useState({ ..._form });

  const clear_form = () => {
    set_form({ ...form, ..._form });
  };

  useEffect(() => {
    API.GET("reference", {
      model_name: "emc.hr.award.type",
    }).then((res) => {
      if (res.status_code === 1) {
        var result = _.map(res.response, (item) => ({
          ...item,
          title: item.display_name,
          key: item.id,
          value: item.id,
          pId: item.parent_id ? item.parent_id[0] : null,
        }));

        result = _.map(result, (item) => ({
          ...item,
          selectable: _.filter(result, { pId: item.id }).length === 0,
        }));
        set_reference_award_type(result);
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/award", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_list(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, refresh]);

  const upload_file = (
    model_name,
    res_id,
    field_name,
    upload_type,
    fileList
  ) => {
    _.map(fileList, (item) => {
      const formData = new FormData();
      formData.append("model_name", model_name);
      formData.append("res_id", res_id);
      formData.append("field_name", field_name);
      formData.append("upload_type", upload_type);
      formData.append("files", item.originFileObj);
      API.UPLOAD_FILE("/file/upload", formData);
    });
  };

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        width={1000}
        title={<div className="text-center border-b pb-2">Шагнал</div>}
        open={form.modal}
        onCancel={() => clear_form()}
        footer={false}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Шагналын төрөл: <b className="text-red-500">*</b>
            </span>
            <TreeSelect
              showSearch
              allowClear={true}
              className="w-full"
              placeholder="Сонгох"
              treeDataSimpleMode={true}
              treeDefaultExpandAll={true}
              treeData={reference_award_type}
              treeLine={(true, { showLeafIcon: false })}
              filterTreeNode={(search, item) =>
                item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
              }
              status={form.submit && !form.award_type && "error"}
              value={form.award_type}
              onChange={(value) => set_form({ ...form, award_type: value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Нэр: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.name && "error"}
              value={form.name}
              onChange={(e) => set_form({ ...form, name: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              allowClear
              className="w-full"
              status={form.submit && !form.award_date && "error"}
              value={form.award_date}
              onChange={(value) =>
                set_form({ ...form, award_date: dayjs(value) })
              }
            />
          </div>

          <hr className="my-2" />
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">Шагналын дугаар:</span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              value={form.award_no}
              onChange={(e) => set_form({ ...form, award_no: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">Тайлбар:</span>
            <Input.TextArea
              allowClear
              size="large"
              className="text-xs"
              value={form.description}
              onChange={(e) =>
                set_form({ ...form, description: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row gap-1">
            <span className="md:w-60">
              <Upload
                className="w-full"
                showUploadList={false}
                beforeUpload={() => false}
                multiple={true}
                //   listType="picture"
                accept=".pdf, .jpeg, .png, .jpg"
                showRemoveIcon={true}
                fileList={form.fileList}
                onChange={(info) => {
                  set_form({
                    ...form,
                    fileList: info.fileList,
                  });
                }}
              >
                <div className="px-2 py-1 bg-gray-100 border rounded-lg cursor-pointer">
                  Файл оруулах
                </div>
              </Upload>
            </span>
            <div className="w-full flex flex-wrap gap-2">
              {_.map(form?.attachments, (item, index) => {
                return (
                  <div
                    key={index}
                    className="min-w-44 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                      <DeleteButton
                        onClick={async () => {
                          await API.DELETE("/file/upload/" + item.id).then(
                            (res) => {
                              if (res.status_code === 1) {
                                message("success", "Амжилттай устгалаа");
                                set_form({
                                  ...form,
                                  attachments: _.without(
                                    form.attachments,
                                    item
                                  ),
                                });
                                set_refresh((prev) => prev + 1);
                              }
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {_.map(form.fileList, (item, index) => {
                return (
                  <div
                    className="p-2 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>
                      {form.attachments.length + index + 1}. {item.name}
                    </span>
                    <DeleteButton
                      onClick={() => {
                        set_form({
                          ...form,
                          fileList: _.without(form.fileList, item),
                        });
                        message("success", "Амжилттай устгалаа");
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <hr className="my-2" />

          <SaveButton
            onClick={() => {
              set_form({ ...form, submit: true });

              var error = false;
              if (!form.award_type || !form.name || !form.award_date) {
                error = true;
                message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
              }

              if (!error) {
                var data = {
                  cv_id: state.id,
                  award_type: form.award_type || null,
                  name: form.name || null,
                  award_date: form.award_date
                    ? form.award_date.format("YYYY-MM-DD")
                    : null,
                  award_no: form.award_no || null,
                  description: form.description || null,
                };
                if (!form.id) {
                  set_loading(true);
                  API.POST("cv/award", data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        upload_file(
                          "emc.cv.award",
                          res.id,
                          "attachments",
                          "many2many",
                          form.fileList
                        );

                        setTimeout(() => {
                          set_refresh((prev) => prev + 1);
                          message("success", res.response);
                          clear_form();
                        }, 500);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Бүртгэж чадсангүй"))
                    .finally(() => set_loading(false));
                } else {
                  set_loading(true);

                  upload_file(
                    "emc.cv.award",
                    form.id,
                    "attachments",
                    "many2many",
                    form.fileList
                  );

                  setTimeout(() => {
                    API.PUT("cv/award/" + form.id, data)
                      .then((res) => {
                        if (res.status_code === 1) {
                          set_refresh((prev) => prev + 1);
                          message("success", res.response);
                          clear_form();
                        } else message("error", res.response);
                      })
                      .catch(() => message("error", "Засварлаж чадсангүй"))
                      .finally(() => set_loading(false));
                  }, 500);
                }
              }
            }}
          />
        </div>
      </Modal>

      <CreateButton
        className="mb-2"
        name="Шагнал нэмэх"
        onClick={() => set_form({ ...form, modal: true })}
      />

      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={list}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Шагналын төрөл"
          align="center"
          dataIndex="award_type"
          render={(data) => data && data[1]}
        />
        <Table.Column title="Нэр" align="center" dataIndex="name" />
        <Table.Column title="Огноо" align="center" dataIndex="award_date" />
        <Table.Column
          title="Шагналын дугаар"
          align="center"
          dataIndex="award_no"
        />
        <Table.Column title="Тайлбар" align="center" dataIndex="description" />
        <Table.Column
          title="Файл"
          align="center"
          width={100}
          render={(data, record, index) => (
            <div className="flex flex-wrap gap-2">
              {_.map(record?.attachments, (item, index) => {
                return (
                  <div
                    className="min-w-40 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_form({
                    ...form,
                    ...record,
                    modal: true,
                    award_type: record.award_type ? record.award_type[0] : null,
                    award_date: record.award_date
                      ? dayjs(record.award_date)
                      : null,
                    award_no: record.award_no || null,
                    description: record.description || null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  set_loading(true);
                  API.DELETE("cv/award/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_refresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"))
                    .finally(() => set_loading(false));
                }}
              />
            </div>
          )}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Award);
