import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";
import * as API from "src/api/request";

import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
} from "../../components/button";
import { Spin, Select, Table, Modal, Input, DatePicker } from "antd";
import _ from "lodash";
import dayjs from "dayjs";

const _form = {
  modal: false,
  submit: false,

  id: null,
  patent_type: null,
  name: null,
  patent_date: null,
  description: null,
};

const Patent = () => {
  const { message } = useUserContext();
  const { state } = useCVContext();

  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);
  const [refresh, set_refresh] = useState(1);

  const [reference_patent_type, set_reference_patent_type] = useState([]);
  const [dic_patent_type, set_dic_patent_type] = useState({});

  const [form, set_form] = useState({ ..._form });

  const clear_form = () => {
    set_form({ ...form, ..._form });
  };

  useEffect(() => {
    API.GET("reference/selection", {
      model_name: "emc.cv.patent",
      field_name: "patent_type",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_patent_type(res.response);
        var dic = {};
        _.map(res.response, (item) => {
          dic[item.value] = item.name;
        });
        set_dic_patent_type(dic);
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/patent", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_list(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, refresh]);

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        width={1000}
        title={<div className="text-center border-b pb-2">Бүтээл</div>}
        open={form.modal}
        onCancel={() => clear_form()}
        footer={false}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Бүтээлийн төрөл: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={form.submit && !form.patent_type && "error"}
              value={form.patent_type}
              onChange={(value) => set_form({ ...form, patent_type: value })}
            >
              {_.map(reference_patent_type, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Бүтээл: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={form.submit && !form.name && "error"}
              value={form.name}
              onChange={(e) => set_form({ ...form, name: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">
              Огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              allowClear
              className="w-full"
              status={form.submit && !form.patent_date && "error"}
              value={form.patent_date}
              onChange={(value) =>
                set_form({ ...form, patent_date: dayjs(value) })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-1/3">Тайлбар:</span>
            <Input.TextArea
              allowClear
              size="large"
              className="text-xs"
              value={form.description}
              onChange={(e) =>
                set_form({ ...form, description: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <SaveButton
            onClick={() => {
              set_form({ ...form, submit: true });

              var error = false;
              if (!form.patent_type || !form.name || !form.patent_date) {
                error = true;
                message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
              }

              if (!error) {
                var data = {
                  cv_id: state.id,
                  patent_type: form.patent_type || null,
                  name: form.name || null,
                  patent_date: form.patent_date
                    ? form.patent_date.format("YYYY-MM-DD")
                    : null,
                  description: form.description || null,
                };
                if (!form.id) {
                  set_loading(true);
                  API.POST("cv/patent", data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        set_refresh((prev) => prev + 1);
                        message("success", res.response);
                        clear_form();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Бүртгэж чадсангүй"))
                    .finally(() => set_loading(false));
                } else {
                  set_loading(true);
                  API.PUT("cv/patent/" + form.id, data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        set_refresh((prev) => prev + 1);
                        message("success", res.response);
                        clear_form();
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Засварлаж чадсангүй"))
                    .finally(() => set_loading(false));
                }
              }
            }}
          />
        </div>
      </Modal>

      <CreateButton
        className="mb-2"
        name="Бүтээл нэмэх"
        onClick={() => set_form({ ...form, modal: true })}
      />

      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={list}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Бүтээлийн төрөл"
          align="center"
          dataIndex="patent_type"
          render={(data) =>
            data in dic_patent_type ? dic_patent_type[data] : data
          }
        />
        <Table.Column title="Бүтээл" align="center" dataIndex="name" />
        <Table.Column title="Огноо" align="center" dataIndex="patent_date" />
        <Table.Column title="Тайлбар" align="center" dataIndex="description" />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_form({
                    ...form,
                    ...record,
                    modal: true,
                    patent_date: record.patent_date
                      ? dayjs(record.patent_date)
                      : null,
                    description: record.description || null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  set_loading(true);
                  API.DELETE("cv/patent/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_refresh((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"))
                    .finally(() => set_loading(false));
                }}
              />
            </div>
          )}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Patent);
