"use client";

import { useUserContext } from "src/context/userContext";

import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { ChevronDownIcon, KeyIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HeaderMain() {
  const { user } = useUserContext();
  const [logintype, setLoginType] = useState("SSO");
  const type = localStorage.getItem("type");
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    if (window.location.origin === "http://localhost:3000") {
      window.location = "http://localhost:3000/login";
    } else {
      window.location = "https://portal.erdenetmc.mn/login";
    }
  };

  useEffect(() => {
    setLoginType(type === "SSO" ? "SSO" : "Baerer");
  }, [type]);
  const token = localStorage.getItem("token");

  return (
    <header className="bg-white">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img alt="logo" src="/img/etlogo.png" className="h-8 w-auto z-10" />
          </a>
        </div>

        <div className="lg:flex lg:flex-1 lg:justify-end">
          {token ? <PopoverGroup className="lg:flex lg:gap-x-12">
            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900 hover:border">
                <div className="flex size-12 flex-none z-10 items-center justify-center rounded-full group-hover:bg-white ">
                  <img
                    alt=""
                    src={
                      logintype === "SSO"
                        ? `https://minio-action.erdenetmc.mn/emp/${user?.info?.tn}.jpg`
                        : "/img/user.png"
                    }
                    className="inline-block size-8 rounded-full ring-2 ring-blue"
                  />
                </div>
                <ChevronDownIcon
                  aria-hidden="true"
                  className="size-5 flex-none text-gray-400"
                />
              </PopoverButton>

              <PopoverPanel
                transition
                className="absolute right-0 z-10 mt-3 max-w-md overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-2 mx-6">
                  {/* User Info Section */}
                  <div className="flex flex-col items-center gap-x-4 p-2">
                    <img
                      alt="User Profile"
                      src={
                        logintype === "SSO"
                          ? `https://minio-action.erdenetmc.mn/emp/${user?.info?.tn}.jpg`
                          : "/img/user.png"
                      }
                      className="w-10 h-10 rounded-full ring-2 ring-indigo-600"
                    />
                    <div className="flex flex-col items-center my-2">
                      <p className="text-sm font-semibold text-gray-900 mb-1">
                        {user?.info?.name
                          ? user?.info?.name
                          : user?.info?.first_name}
                      </p>
                      <p className="text-xs text-gray-500">
                        {user?.info?.work_email}
                      </p>
                    </div>
                  </div>

                  {/* <div className="">
                    <a href="#/" className="flex items-center gap-x-2 p-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">
                      <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v12m6-6H6" />
                      </svg>
                      <span>Profile Settings</span>
                    </a>
                  </div> */}
                  {/* Logout Button */}
                  <div className="mb-2">
                    <button
                      onClick={handleLogout}
                      className="w-full flex items-center gap-x-2 p-2 text-sm font-semibold text-red-600 hover:bg-gray-50 rounded-lg"
                    >
                      <svg
                        className="w-5 h-5 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 16l4-4m0 0l-4-4m4 4H3"
                        />
                      </svg>
                      <span>Logout</span>
                    </button>
                  </div>
                </div>
              </PopoverPanel>
            </Popover>
          </PopoverGroup> : <div className="flex gap-1 bg-green-800 p-2 text-white rounded-lg hover:bg-green-700 hover:cursor-pointer" onClick={() => {
            navigate("/login")
          }}>
            <KeyIcon className="w-5 h-5" />
            <div>
              Нэвтрэх
            </div>
          </div>}
        </div>
      </nav>
    </header>
  );
}
