import React, { useMemo } from "react";
import { Input } from "antd";
import _ from "lodash";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import * as XLSX from "xlsx";
import axios from "axios";
import { useUserContext } from "src/context/userContext";
import dayjs from "dayjs";

const List = ({ state, setState }) => {
  const { user, message } = useUserContext();

  const exportToExcel = () => {
    let Heading = [
      [
        "№",
        "Бүтцийн нэгж",
        "Бүртгэлийн дугаар",
        "Овог нэр",
        "Албан тушаал",
        "Ажилтны үнэлгээ",
        "Удирдлагын үнэлгээ",
        "Нийт үнэлгээ",
      ],
    ];
    var result = _.map(state?.list, (a, i) => {
      return {
        i: i + 1,
        department: a.master_department_name,
        tn: a.tn,
        shortname: a.name,
        positionname: a.job_name,
        resultvalue2: a.worker_value,
        resultvalue1: a.master_value,
        resultvalue: a.worker_value + a.master_value,
      };
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: "A1" });
    XLSX.utils.sheet_add_json(worksheet, result, {
      origin: "A2",
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    var department = _.find(state?.department, { id: state.department_id });

    XLSX.writeFile(
      workbook,
      department.title +
        " - Ажилтны ГГҮ жагсаалт - " +
        dayjs(state.date).format("YYYY MM сар") +
        ".xlsx",
      {
        compression: true,
      }
    );
  };

  const exportToPdf = async () => {
    const job_name =
      Array.isArray(user?.info?.job_id) &&
      typeof user?.info?.job_id[1] === "string"
        ? user.info.job_id[1].split("|")[1] || ""
        : "";
    try {
      var json = {
        export_type: "pdf",
        title:
          dayjs(state.date).format("YYYY") +
          " оны " +
          dayjs(state.date).format("MM") +
          " сарын гүйцэтгэлийн гол үзүүлэлтийн тайлан",
        department_id: state?.department_id,
        list: state?.list,
        avg_worker_value: _.round(
          _.meanBy(
            _.filter(state?.list, (a) => a.worker_value),
            "worker_value"
          ),
          2
        ),
        avg_master_value: _.round(
          _.meanBy(
            _.filter(state?.list, (a) => a.master_value),
            "master_value"
          ),
          2
        ),
        avg_value: _.round(
          _.meanBy(
            _.filter(state?.list, (a) => a.value),
            "value"
          ),
          2
        ),
        department_name: _.find(state?.department, {
          id: state?.department_id,
        })?.name,
        user_name: user?.info?.name,
        job_name: job_name,
      };

      const res = await axios.post(
        "https://portal.erdenetmc.mn/api/kpi/employee/report",
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );

      const fileUrl = URL.createObjectURL(res?.data);
      setState((old) => ({ ...old, file_url: fileUrl, file_modal: true }));
    } catch (error) {
      message("warning", "Анхааруулга", error?.message);
    }
  };

  const table = useMemo(() => {
    return (
      <DataTable
        scrollable
        dataKey="tn"
        size="small"
        stripedRows
        showGridlines
        removableSort
        sortMode="multiple"
        className="text-xs"
        scrollHeight={window.innerHeight - 280}
        responsiveLayout="scroll"
        filters={state?.search}
        filterDisplay="menu"
        value={state?.list}
        globalFilterFields={["tn", "name", "job_name"]}
        emptyMessage={
          <div className="text-xs text-orange-500 italic font-semibold">
            Мэдээлэл олдсонгүй...
          </div>
        }
        rowClassName={(data) => {
          if (data.worker_value === null && data?.value === null) {
            return "bg-gray-200";
          } else if (
            data.worker_value !== null &&
            data?.value !== null &&
            data.worker_value + data.value < 80
          ) {
            return "bg-red-200";
          }
          return "";
        }}
        header={
          <div className="flex items-center justify-between">
            <Input.Search
              className="w-80 font-normal text-xs"
              placeholder="Хайх..."
              value={state?.search}
              onChange={(e) =>
                setState((old) => ({ ...old, search: e.target.value }))
              }
            />

            <div className="flex items-center gap-4">
              <img
                alt=""
                title="Excel татах"
                src="/emc/excel.png"
                className="h-6 object-cover cursor-pointer hover:scale-125 duration-300"
                onClick={exportToExcel}
              />
              <img
                alt=""
                title="Pdf татах"
                src="/emc/pdf.png"
                className="h-6 object-cover cursor-pointer hover:scale-125 duration-300"
                onClick={exportToPdf}
              />
            </div>
          </div>
        }
        rowGroupMode="subheader"
        groupRowsBy="section_department_name"
        rowGroupHeaderTemplate={(data) => (
          <span className="text-xs font-semibold px-2">
            {data.section_department_name}
          </span>
        )}
        footerColumnGroup={
          <ColumnGroup>
            <Row>
              <Column className="w-[50px] text-center" />
              <Column className="w-[100px] text-center" />
              <Column className="w-[200px] text-center" />
              <Column className="w-min-[200px] w-full" />
              <Column
                className="w-[100px] text-center"
                footer={() => {
                  const notNull = _.filter(state?.list, (a) => a.worker_value);
                  return notNull.length > 0
                    ? _.round(_.meanBy(notNull, "worker_value"), 2)
                    : "";
                }}
              />
              <Column
                className="w-[100px] text-center"
                footer={() => {
                  const notNull = _.filter(state?.list, (a) => a.master_value);
                  return notNull.length > 0
                    ? _.round(_.meanBy(notNull, "master_value"), 2)
                    : "";
                }}
              />
              <Column
                className="w-[100px] text-center"
                footer={() => {
                  const notNull = _.filter(
                    state?.list,
                    (a) => a.value && a.value
                  );
                  return notNull.length > 0
                    ? _.round(_.meanBy(notNull, "value"), 2)
                    : "";
                }}
              />
            </Row>
          </ColumnGroup>
        }
      >
        <Column
          header="№"
          align="center"
          body={(data, { rowIndex }) => rowIndex + 1}
        />
        <Column sortable header="БД" field="tn" />
        <Column
          sortable
          header="Овог нэр"
          field="name"
          body={(data) => (
            <span
              className="underline cursor-pointer"
              onClick={() =>
                setState((old) => ({
                  ...old,
                  row: data,
                  modal: true,
                }))
              }
            >
              {data.name}
            </span>
          )}
        />
        <Column sortable header="Албан тушаал" field="job_name" />
        <Column
          sortable
          align="center"
          header="Ажилтны үнэлгээ"
          field="worker_value"
          body={(data) => data.worker_value && _.round(data.worker_value, 2)}
        />
        <Column
          sortable
          align="center"
          header="Удирдлагын үнэлгээ"
          field="master_value"
          body={(data) => data.master_value && _.round(data.master_value, 2)}
        />
        <Column
          sortable
          align="center"
          header="Нийт үнэлгээ"
          body={(data) => data.value && _.round(data.value, 2)}
        />
      </DataTable>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.list, state?.search]);

  return table;
};

export default List;
