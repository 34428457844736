/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useSearchParams } from "react-router-dom";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export default function PDFViewer({ url }) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

    const screen1 = useFullScreenHandle();
    const [numPages, setNumPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [searchParams] = useSearchParams();
    const pdfUrl = searchParams.get("url"); // Get PDF URL from query params
    const username = searchParams.get("user");

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const customRenderPage = (props) => (
        <Page {...props} renderTextLayer={false} renderAnnotationLayer={false} />
    );

    const reportChange = useCallback((state, handle) => {
        if (handle === screen1) {
            console.log("Screen 1 went to", state, handle);
        }
    }, [screen1]);

    useEffect(() => {
        const pageArray = [];
        for (let i = 1; i <= numPages; i++) {
            pageArray.push(
                <Page key={i} pageNumber={i} renderFunction={customRenderPage}>
                    <div
                        className="absolute top-[-150px] left-[-150px] w-screen h-full flex flex-wrap justify-center items-center pointer-events-none z-10 text-[rgba(33,13,85,0.3)] text-2xl font-bold rotate-[-30deg] text-center gap-[50px] p-[50px]"
                    >
                        {Array(50)
                            .fill(username)
                            .map((text, i) => (
                                <div key={i} className="mx-5">
                                    {text}
                                </div>
                            ))}
                    </div>
                </Page>
            );
        }
        setPages(pageArray);
    }, [numPages, url]);

    return (
        // <div className="h-full w-full overflow-hidden">
        //     <div className="h-full w-full overflow-auto flex justify-center">
        //         <FullScreen className="overflow-auto w-full" handle={screen1} onChange={reportChange}>

        //         </FullScreen>
        //     </div>
        // </div>
        <div className="overflow-auto flex justify-center mx-60">
            <Document
                className="overflow-auto"
                rotate={rotationAngle}
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {pages}
            </Document>
        </div>
    );
}
