import React from "react";
import * as API from "src/api/request";

const Login = () => {
  const handleEmployeeLogin = () => {
    API.GET("/auth")
      .then((res) => {
        let url = res.url;
        if (window.location.origin === "http://localhost:3000") {
          url = url.replace(
            "https://portal.erdenetmc.mn/sso/callback",
            "http://localhost:3000/sso/callback"
          );
        }
        window.location.replace(url);
      })
      .catch(() => {
        alert("Failed to redirect to the authentication URL.");
      });
  };

  const handleCitizenLogin = () => {
    API.GET("/dan/auth")
      .then((res) => {
        let url = res;
        if (window.location.origin === "http://localhost:3000") {
          url = url.replace(
            "https://portal.erdenetmc.mn/dan/callback",
            "http://localhost:3000/dan/callback"
          );
        }
        window.location.replace(url);
      })
      .catch(() => {
        alert("Failed to redirect to the citizen authentication URL.");
      });
  };

  return (
    <section className="bg-gray-50 w-screen h-screen flex items-center justify-center">
      <div className="fixed w-screen h-screen object-cover">
        <div className="w-full h-full fixed bg-black opacity-50 z-10" />
        <img
          src="/img/login_background.jpg"
          alt="login background"
          className="w-full h-full object-cover z-20"
        />
      </div>
      <div className="w-full bg-gradient-to-br from-gray-50 to-white rounded-lg md:mt-0 sm:max-w-md xl:p-0 mx-4 z-20 shadow-xl">
        <div className="p-6 space-y-6 md:space-y-8">
          <h1 className="text-2xl font-extrabold tracking-wide md:text-3xl text-[#6f3c83]">
            Сайн байна уу?
          </h1>
          <div className="flex items-center justify-between gap-8 flex-col">
            {/* Employee Section */}
            <div className="text-center w-full flex items-center justify-center flex-col">
              <span className="block mb-2 text-gray-600 font-medium text-base sm:text-xl">
                Ажилтан бол{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={handleEmployeeLogin}
                >
                  энд
                </span>{" "}
                дарж нэвтрэнэ үү.
              </span>
              <img
                src="/img/etlogo.png"
                alt=""
                className="h-20 border px-4 py-2 rounded-lg transition duration-300 cursor-pointer hover:bg-gradient-to-r hover:from-[#874B9E50] hover:to-[#874B9E80] hover:text-white hover:shadow-lg"
                onClick={handleEmployeeLogin}
              />
            </div>
            {/* Citizen Section */}
            <div className="text-center w-full flex items-center justify-center flex-col">
              <span className="block mb-2 text-gray-600 font-medium text-base sm:text-xl">
                Иргэн бол{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={handleEmployeeLogin}
                >
                  энд
                </span>{" "}
                дарж нэвтрэнэ үү.
              </span>
              <img
                src="/img/light-logo.webp"
                alt=""
                className="h-20 border px-4 py-2 rounded-lg transition duration-300 cursor-pointer hover:bg-gradient-to-r hover:from-[#874B9E50] hover:to-[#874B9E80] hover:text-white hover:shadow-lg"
                onClick={handleCitizenLogin}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Login);
