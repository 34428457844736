import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserContext } from "src/context/userContext";
import { ConfigProvider } from "antd";
import mn from "antd/lib/locale/mn_MN";
import { PrimeReactProvider } from "primereact/api";

import Loader from "src/components/layout_main/loader";
import PageSSO from "src/pages/pageSSO";
import PageDAN from "src/pages/pageDAN";
import Page404 from "src/pages/page404";
import Login from "src/pages/login";
import ModulePage from "src/pages/module_page";
import LayoutMain from "src/components/layout_main/layout";

import Hr from "src/hr";
import Purchase from "src/purchase";
import { PurchaseContext } from "src/purchase/context/purchaseContext";
import Check from "src/hr/check";
import DirectPurchase from "src/pages/public/purchase/directPurchase";
import PDFViewer from "src/pages/public/pdf_viewer";

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ConfigProvider locale={mn}>
        <PrimeReactProvider>
          <BrowserRouter>
            <UserContext >
              <Routes>
                {/* <Route path="/public/" element={<LayoutMain />}>
                  <Route path="direct-purchase" element={<DirectPurchase />} />
                </Route> */}
                <Route element={<LayoutMain />}>
                  <Route path="/" element={<ModulePage />} />
                  <Route path="/public/">
                    <Route path="direct-purchase" element={<DirectPurchase />} />
                  </Route>
                </Route>
                <Route path="/pdf" element={<PDFViewer />} />
                <Route path="/login" element={<Login />} />
                <Route path="/sso/callback" element={<PageSSO />} />
                <Route path="/dan/callback" element={<PageDAN />} />

                <Route
                  path="/purchase/*"
                  element={
                    <PurchaseContext>
                      <Purchase />
                    </PurchaseContext>
                  }
                />
                <Route path="/hr/*" element={<Hr />} />
                <Route path="/check/:id" element={<Check />} />

                <Route path="*" element={<Page404 />} />
              </Routes></UserContext>

          </BrowserRouter>
        </PrimeReactProvider>
      </ConfigProvider>
    </Suspense>
  );
};

export default React.memo(App);
