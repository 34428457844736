import React from "react";
import { Outlet } from "react-router-dom";
import FullPageLoader from "src/components/fullPageLoader";
import { useUserContext } from "src/context/userContext";
import HeaderMain from "./header";

const LayoutMain = () => {
  const { user } = useUserContext();

  return (
    <div className="page fadeIn animated">
      {
        user.pageLoader ? <FullPageLoader /> :
          <>
            <HeaderMain />
            <div className="relative isolate bg-white w-full h-screen ">
              <div
                className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-6 blur-3xl bg-white"
                aria-hidden="true"
              >
                <div className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#51b139] to-[#990b46] opacity-40"></div>
              </div>
              <div className="lg:max-w-7xl mx-auto">
                {<Outlet />}
              </div>
            </div>
          </>
      }
    </div>
  );
};

export default React.memo(LayoutMain);
