import React from 'react';
import { Spin } from 'antd';

const FullPageLoader = () => {


    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div
                className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl bg-white"
                aria-hidden="true"
            >
                <div className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#51b139] to-[#990b46] opacity-40"></div>
            </div>

            <Spin size="large" />
            <style>
                {`
                .ant-spin-dot-item {
                    background-color: rgb(91, 52, 81);
                }
                `}
            </style>
        </div>
    );
};

export default FullPageLoader;