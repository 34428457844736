/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/layout";
import PurchaseHome from "./pages/home";
import PurchaseContract from "./pages/contract"
import { usePurchaseContext } from "src/purchase/context/purchaseContext"
import { useUserContext } from "src/context/userContext"
import UserDirenctPurchase from "src/purchase/pages/direct_purchase"

import * as ERP_API from "src/api/epr_request";
import { DocumentTextIcon, HomeIcon } from "@heroicons/react/24/solid";
import { BiCart } from "react-icons/bi";


const Purchase = () => {
    const { purchaseState, purchaseDispatch } = usePurchaseContext();
    const { user, userDispatch } = useUserContext();


    const route_menu_list = [{
        title: "Нүүр",
        path: "/",
        is_parent: false,
        icon: <HomeIcon className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />,
        page: <PurchaseHome />,
        groups: ["base.group_portal"]
    },
    {
        title: "Гэрээ",
        path: "/contract",
        is_parent: false,
        icon: <DocumentTextIcon className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />,
        page: <PurchaseContract />,
        groups: ["emc_purchase.group_contract_viewer", "base.group_system"]
    },
    {
        title: "Шууд худалдан авалт",
        path: "/direct-purchase",
        is_parent: false,
        icon: <BiCart className="w-5 h-5 text-gray-500 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />,
        page: <UserDirenctPurchase />,
        groups: ["base.group_portal"]
    },
    ]

    const get_menu_data = async () => {
        try {
            const res_group = await ERP_API.getGroups();

            if (res_group?.length === 0) {
                window.location.replace("/");
            }
            purchaseDispatch({ groups: res_group });
            const user_menu = await route_menu_list.filter((item) => {
                const menu_groups = item.groups;
                const exist = menu_groups.some(group => res_group.includes(group));
                return exist;
            });
            console.log(user_menu)
            await purchaseDispatch({ menu_list: user_menu });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetch_data = async () => {
        try {
            userDispatch({ pageLoader: true });
            await get_menu_data()
            const civil_id = user?.info?.civil_id;
            let companies
            if (civil_id && localStorage.getItem('type') !== 'SSO') {
                companies = await ERP_API.getСompanies(civil_id);
                await userDispatch({ companies: companies });
                console.log("Fetching companies for civil_id:", civil_id);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            userDispatch({ pageLoader: false });
        }
    };
    useLayoutEffect(() => {
        fetch_data()
    }, []);

    return (
        <Routes>
            <Route element={<Layout />}>
                {purchaseState?.menu_list?.length > 0 ? (
                    purchaseState.menu_list.map((item, index) => (
                        <Route key={index} path={item.path} element={item.page} />
                    ))
                ) : (
                    <Route path="/" element={<UserDirenctPurchase />} />
                )}
                <Route path="/direct-purchase" element={<UserDirenctPurchase />} />
                {/* <Route path="/" element={<PurchaseHome />} />
                <Route path="/contract" element={<PurchaseContract />} /> */}
            </Route>

        </Routes>
    );
};

export default React.memo(Purchase);
