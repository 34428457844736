import React, { useEffect, useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";
import * as API from "src/api/request";

import {
  CreateButton,
  EditButton,
  DeleteButton,
  SaveButton,
  DownloadButton,
} from "../../components/button";
import { Spin, Table, Modal, Select, Input, DatePicker, Upload } from "antd";
import _ from "lodash";
import dayjs from "dayjs";

var _education = {
  modal: false,
  submit: false,
  fileList: [],

  id: null,
  school: null,
  profession: null,
  start_date: null,
  end_date: null,
  edu_level_id: null,
  diplom_no: null,
  attachments: [],
};

var _degree = {
  modal: false,
  submit: false,
  fileList: [],

  id: null,
  degree_type: null,
  location: null,
  name: null,
  subject: null,
  degree_date: null,
  degree_no: null,
  attachments: [],
};

const Education = () => {
  const { message } = useUserContext();
  const { state } = useCVContext();
  const [loading, set_loading] = useState(false);

  const [reference_education_level, set_reference_education_level] = useState(
    []
  );
  const [reference_degree_type, set_reference_degree_type] = useState([]);
  const [dic_degree_type, set_dic_degree_type] = useState({});

  const [list_education, set_list_education] = useState([]);
  const [refresh_education, set_refresh_education] = useState(0);

  const [education, set_education] = useState({
    ..._education,
  });

  const [list_degree, set_list_degree] = useState([]);
  const [refresh_degree, set_refresh_degree] = useState(0);
  const [degree, set_degree] = useState({
    ..._degree,
  });

  const clear_education = () => {
    set_education({
      ...education,
      ..._education,
    });
  };

  const clear_degree = () => {
    set_degree({
      ...degree,
      ..._degree,
    });
  };

  useEffect(() => {
    API.GET("reference", {
      model_name: "emc.hr.edu.level",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_education_level(
          _.filter(res.response, (a) => a.id !== 75)
        );
      } else message("error", res.response);
    });

    API.GET("reference/selection", {
      model_name: "emc.cv.degree",
      field_name: "degree_type",
    }).then((res) => {
      if (res.status_code === 1) {
        set_reference_degree_type(res.response);
        var dic = {};
        _.map(res.response, (item) => {
          dic[item.value] = item.name;
        });
        set_dic_degree_type(dic);
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/education", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_list_education(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, refresh_education]);

  useEffect(() => {
    if (state.id) {
      set_loading(true);
      API.GET("cv/degree", { cv_id: state.id })
        .then((res) => {
          if (res.status_code === 1) {
            set_list_degree(res.response);
          } else message("error", res.response);
        })
        .finally(() => set_loading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.id, refresh_degree]);

  const upload_file = async (
    model_name,
    res_id,
    field_name,
    upload_type,
    fileList
  ) => {
    _.map(fileList, (item) => {
      const formData = new FormData();
      formData.append("model_name", model_name);
      formData.append("res_id", res_id);
      formData.append("field_name", field_name);
      formData.append("upload_type", upload_type);
      formData.append("files", item.originFileObj);
      API.UPLOAD_FILE("/file/upload", formData);
    });
  };

  return (
    <Spin spinning={loading}>
      <Modal
        centered
        width={1000}
        title={<div className="text-center border-b pb-2">Боловсрол</div>}
        open={education.modal}
        onCancel={() => clear_education()}
        footer={false}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Сургууль: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={education.submit && !education.school && "error"}
              value={education.school}
              onChange={(e) =>
                set_education({ ...education, school: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Мэргэжил: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={education.submit && !education.profession && "error"}
              value={education.profession}
              onChange={(e) =>
                set_education({ ...education, profession: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Эхлэх огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              allowClear
              className="w-full"
              status={education.submit && !education.start_date && "error"}
              value={education.start_date}
              onChange={(value) =>
                set_education({ ...education, start_date: dayjs(value) })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Дуусах огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              allowClear
              className="w-full"
              status={education.submit && !education.end_date && "error"}
              value={education.end_date}
              onChange={(value) =>
                set_education({ ...education, end_date: dayjs(value) })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Боловсролын түвшин: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={education.submit && !education.edu_level_id && "error"}
              value={education.edu_level_id}
              onChange={(value) =>
                set_education({ ...education, edu_level_id: value })
              }
            >
              {_.map(reference_education_level, (item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">Дипломын дугаар:</span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              value={education.diplom_no}
              onChange={(e) =>
                set_education({ ...education, diplom_no: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row gap-1">
            <span className="md:w-60">
              <Upload
                className="w-full"
                showUploadList={false}
                beforeUpload={() => false}
                multiple={true}
                //   listType="picture"
                accept=".pdf, .jpeg, .png, .jpg"
                showRemoveIcon={true}
                fileList={education.fileList}
                onChange={(info) => {
                  set_education({
                    ...education,
                    fileList: info.fileList,
                  });
                }}
              >
                <div className="px-2 py-1 bg-gray-100 border rounded-lg cursor-pointer">
                  Файл оруулах
                </div>
              </Upload>
            </span>
            <div className="w-full flex flex-wrap gap-2">
              {_.map(education?.attachments, (item, index) => {
                return (
                  <div
                    className="min-w-44 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                      <DeleteButton
                        onClick={async () => {
                          await API.DELETE("/file/upload/" + item.id).then(
                            (res) => {
                              if (res.status_code === 1) {
                                message("success", "Амжилттай устгалаа");
                                set_education({
                                  ...education,
                                  attachments: _.without(
                                    education.attachments,
                                    item
                                  ),
                                });
                                set_refresh_education((prev) => prev + 1);
                              }
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {_.map(education.fileList, (item, index) => {
                return (
                  <div
                    className="p-2 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>
                      {education.attachments.length + index + 1}. {item.name}
                    </span>
                    <DeleteButton
                      onClick={() => {
                        set_education({
                          ...education,
                          fileList: _.without(education.fileList, item),
                        });
                        message("success", "Амжилттай устгалаа");
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <hr className="my-2" />

          <SaveButton
            onClick={() => {
              set_education({ ...education, submit: true });

              var error = false;
              if (
                !education.school ||
                !education.profession ||
                !education.start_date ||
                !education.end_date ||
                !education.edu_level_id
              ) {
                error = true;
                message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
              }

              if (!error) {
                var data = {
                  cv_id: state.id,
                  school: education.school || null,
                  profession: education.profession || null,
                  start_date: education.start_date
                    ? dayjs(education.start_date).format("YYYY-MM-DD")
                    : null,
                  end_date: education.end_date
                    ? dayjs(education.end_date).format("YYYY-MM-DD")
                    : null,
                  edu_level_id: education.edu_level_id || null,
                  diplom_no: education.diplom_no || null,
                };
                if (!education.id) {
                  API.POST("cv/education", data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        upload_file(
                          "emc.cv.edu",
                          res.id,
                          "attachments",
                          "many2many",
                          education.fileList
                        );

                        setTimeout(() => {
                          set_refresh_education((prev) => prev + 1);
                          message("success", res.response);
                          clear_education();
                        }, 500);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Бүртгэж чадсангүй"));
                } else {
                  upload_file(
                    "emc.cv.edu",
                    education.id,
                    "attachments",
                    "many2many",
                    education.fileList
                  );

                  setTimeout(() => {
                    API.PUT("cv/education/" + education.id, data)
                      .then((res) => {
                        if (res.status_code === 1) {
                          set_refresh_education((prev) => prev + 1);
                          message("success", res.response);
                          clear_education();
                        } else message("error", res.response);
                      })
                      .catch(() => message("error", "Засварлаж чадсангүй"));
                  }, 500);
                }
              }
            }}
          />
        </div>
      </Modal>

      <div className="mb-2 flex items-center justify-between gap-2">
        <CreateButton
          name="Боловсрол нэмэх"
          onClick={() => set_education({ ...education, modal: true })}
        />
        <span className="italic text-xs">
          (бүрэн дунд боловсрол, дипломын дээд боловсрол, бакалавр, магистрын
          зэргийг оролцуулан бичнэ)
        </span>
      </div>
      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={list_education}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column title="Сургууль" align="center" dataIndex="school" />
        <Table.Column title="Мэргэжил" align="center" dataIndex="profession" />
        <Table.Column
          title="Эхлэсэн огноо"
          align="center"
          dataIndex="start_date"
        />
        <Table.Column
          title="Дууссан огноо"
          align="center"
          dataIndex="end_date"
        />
        <Table.Column
          title="Дипломын дугаар"
          align="center"
          dataIndex="diplom_no"
        />
        <Table.Column
          title="Файл"
          align="center"
          width={100}
          render={(data, record, index) => (
            <div className="flex flex-wrap gap-2">
              {_.map(record?.attachments, (item, index) => {
                return (
                  <div
                    className="min-w-40 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_education({
                    ...education,
                    ...record,
                    modal: true,
                    school: record.school || null,
                    profession: record.profession || null,
                    start_date: record.start_date
                      ? dayjs(record.start_date)
                      : null,
                    end_date: record.end_date ? dayjs(record.end_date) : null,
                    edu_level_id: record.edu_level_id
                      ? record.edu_level_id[0]
                      : null,
                    diplom_no: record.diplom_no || null,
                    fileList: [],
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  API.DELETE("cv/education/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_refresh_education((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"));
                }}
              />
            </div>
          )}
        />
      </Table>

      <hr className="my-3" />

      <Modal
        centered
        width={1000}
        title={
          <div className="text-center border-b pb-2">
            Боловсролын болон шинжлэх ухааны докторын зэрэг
          </div>
        }
        open={degree.modal}
        onCancel={() => clear_degree()}
        footer={false}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Зэрэг: <b className="text-red-500">*</b>
            </span>
            <Select
              allowClear
              showSearch
              placeholder="Сонгох"
              className="w-full"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              status={degree.submit && !degree.degree_type && "error"}
              value={degree.degree_type}
              onChange={(value) =>
                set_degree({ ...degree, degree_type: value })
              }
            >
              {_.map(reference_degree_type, (item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Хамгаалсан газар: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={degree.submit && !degree.location && "error"}
              value={degree.location}
              onChange={(e) =>
                set_degree({ ...degree, location: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Нэр: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={degree.submit && !degree.name && "error"}
              value={degree.name}
              onChange={(e) => set_degree({ ...degree, name: e.target.value })}
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Сэдэв: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={degree.submit && !degree.subject && "error"}
              value={degree.subject}
              onChange={(e) =>
                set_degree({ ...degree, subject: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Огноо: <b className="text-red-500">*</b>
            </span>
            <DatePicker
              allowClear
              className="w-full"
              status={degree.submit && !degree.degree_date && "error"}
              value={degree.degree_date}
              onChange={(value) =>
                set_degree({ ...degree, degree_date: dayjs(value) })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row md:items-center gap-1">
            <span className="md:w-60">
              Дипломын дугаар: <b className="text-red-500">*</b>
            </span>
            <Input
              allowClear
              size="large"
              className="text-xs"
              status={degree.submit && !degree.degree_no && "error"}
              value={degree.degree_no}
              onChange={(e) =>
                set_degree({ ...degree, degree_no: e.target.value })
              }
            />
          </div>

          <hr className="my-2" />

          <div className="flex flex-col md:flex-row gap-1">
            <span className="md:w-60">
              <Upload
                className="w-full"
                showUploadList={false}
                beforeUpload={() => false}
                multiple={true}
                //   listType="picture"
                accept=".pdf, .jpeg, .png, .jpg"
                showRemoveIcon={true}
                fileList={degree.fileList}
                onChange={(info) => {
                  set_degree({
                    ...degree,
                    fileList: info.fileList,
                  });
                }}
              >
                <div className="px-2 py-1 bg-gray-100 border rounded-lg cursor-pointer">
                  Файл оруулах
                </div>
              </Upload>
            </span>
            <div className="w-full flex flex-wrap gap-2">
              {_.map(degree?.attachments, (item, index) => {
                return (
                  <div
                    key={index}
                    className="min-w-44 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                      <DeleteButton
                        onClick={async () => {
                          await API.DELETE("/file/upload/" + item.id).then(
                            (res) => {
                              if (res.status_code === 1) {
                                message("success", "Амжилттай устгалаа");
                                set_degree({
                                  ...degree,
                                  attachments: _.without(
                                    degree.attachments,
                                    item
                                  ),
                                });
                                set_refresh_degree((prev) => prev + 1);
                              }
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              {_.map(degree.fileList, (item, index) => {
                return (
                  <div
                    className="p-2 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>
                      {degree.attachments.length + index + 1}. {item.name}
                    </span>
                    <DeleteButton
                      onClick={() => {
                        set_degree({
                          ...degree,
                          fileList: _.without(degree.fileList, item),
                        });
                        message("success", "Амжилттай устгалаа");
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <hr className="my-2" />

          <SaveButton
            onClick={() => {
              set_degree({ ...degree, submit: true });

              var error = false;
              if (
                !degree.degree_type ||
                !degree.location ||
                !degree.name ||
                !degree.subject ||
                !degree.degree_date ||
                !degree.degree_no
              ) {
                error = true;
                message("warning", "Шаардлагатай талбарыг бөглөнө үү!");
              }

              if (!error) {
                var data = {
                  cv_id: state.id,
                  degree_type: degree.degree_type || null,
                  location: degree.location || null,
                  name: degree.name || null,
                  subject: degree.subject || null,
                  degree_date: degree.degree_date
                    ? dayjs(degree.degree_date).format("YYYY-MM-DD")
                    : null,
                  degree_no: degree.degree_no || null,
                };
                if (!degree.id) {
                  API.POST("cv/degree", data)
                    .then((res) => {
                      if (res.status_code === 1) {
                        upload_file(
                          "emc.cv.degree",
                          res.id,
                          "attachments",
                          "many2many",
                          degree.fileList
                        );

                        setTimeout(() => {
                          set_refresh_degree((prev) => prev + 1);
                          message("success", res.response);
                          clear_degree();
                        }, 500);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Бүртгэж чадсангүй"));
                } else {
                  upload_file(
                    "emc.cv.degree",
                    degree.id,
                    "attachments",
                    "many2many",
                    degree.fileList
                  );

                  setTimeout(() => {
                    API.PUT("cv/degree/" + degree.id, data)
                      .then((res) => {
                        if (res.status_code === 1) {
                          set_refresh_degree((prev) => prev + 1);
                          message("success", res.response);
                          clear_degree();
                        } else message("error", res.response);
                      })
                      .catch(() => message("error", "Засварлаж чадсангүй"));
                  }, 500);
                }
              }
            }}
          />
        </div>
      </Modal>
      <CreateButton
        className="mb-2"
        name="Боловсролын болон шинжлэх ухааны докторын зэрэг нэмэх"
        onClick={() => set_degree({ ...degree, modal: true })}
      />

      <Table
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        className="overflow-auto"
        dataSource={list_degree}
      >
        <Table.Column
          title="№"
          width={50}
          align="center"
          render={(data, record, index) => index + 1}
        />
        <Table.Column
          title="Зэрэг"
          align="center"
          dataIndex="degree_type"
          render={(data) =>
            data in dic_degree_type ? dic_degree_type[data] : data
          }
        />
        <Table.Column
          title="Хамгаалсан газар"
          align="center"
          dataIndex="location"
        />
        <Table.Column title="Нэр" align="center" dataIndex="name" />
        <Table.Column title="Сэдэв" align="center" dataIndex="subject" />
        <Table.Column title="Огноо" align="center" dataIndex="degree_date" />
        <Table.Column
          title="Дипломын дугаар"
          align="center"
          dataIndex="degree_no"
        />
        <Table.Column
          title="Файл"
          align="center"
          width={100}
          render={(data, record, index) => (
            <div className="flex flex-wrap gap-2">
              {_.map(record?.attachments, (item, index) => {
                return (
                  <div
                    className="min-w-40 p-1.5 flex items-center justify-between gap-2 border rounded-lg"
                    key={index}
                  >
                    <span>{item.name}</span>
                    <div className="flex gap-2">
                      <DownloadButton
                        onClick={async () => {
                          const link = document.createElement("a");
                          link.href = item.minio_url;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        />
        <Table.Column
          width={100}
          title=""
          align="center"
          render={(data, record, index) => (
            <div className="flex items-center justify-center gap-3">
              <EditButton
                onClick={() =>
                  set_degree({
                    ...degree,
                    ...record,
                    modal: true,
                    degree_date: record.degree_date
                      ? dayjs(record.degree_date)
                      : null,
                  })
                }
              />

              <DeleteButton
                onClick={() => {
                  API.DELETE("cv/degree/" + record.id)
                    .then((res) => {
                      if (res.status_code === 1) {
                        message("success", res.response);
                        set_refresh_degree((prev) => prev + 1);
                      } else message("error", res.response);
                    })
                    .catch(() => message("error", "Устгаж чадсангүй"));
                }}
              />
            </div>
          )}
        />
      </Table>
    </Spin>
  );
};

export default React.memo(Education);
