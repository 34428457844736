/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";

const ModulePage = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto py-24 sm:py-32 px-6 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-7xl lg:grid-cols-3">
      <div
        className="rounded-3xl rounded-t-3xl bg-white/60 p-4 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-3xl sm:p-10  lg:rounded-3xl lg:mx-8 h-100 hover:bg-green-50 hover:scale-105 hover:shadow-lg transition-all duration-200 cursor-pointer active:scale-95 active:bg-gray-100"
        onClick={() => navigate("/hr/me")}
      >
        <div className="flex justify-start items-center w-full h-full">
          <img
            src="/img/module_icons/employee.png"
            alt="Icon"
            className="w-20 h-20"
          />
          <div className="mx-3 flex flex-col justify-start h-full">
            <h3 className="text-base/7 font-semibold text-indigo-600">
              Хүний нөөц
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              Ажилтны хүний нөөцийн бүртгэлийн санд оруулсан хувийн мэдээлэл
            </p>
          </div>
        </div>
      </div>
      <div
        className="rounded-3xl rounded-t-3xl bg-white/60 p-4 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-3xl sm:p-10  lg:rounded-3xl lg:mx-8 h-100 hover:bg-indigo-50 hover:scale-105 hover:shadow-lg transition-all duration-200 cursor-pointer active:scale-95 active:bg-gray-100"
        onClick={() => navigate("/purchase")}
      >
        <div className="flex justify-start items-center w-full h-full">
          <img
            src="/img/module_icons/purchase.png"
            alt="Icon"
            className="w-20 h-20"
          />
          <div className="mx-3 flex flex-col justify-start h-full">
            <h3 className="text-base/7 font-semibold text-indigo-600">
              Худалдан авалт
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              "Эрдэнэт үйлдвэр" ТӨҮГ-ын худалдан авалтын төлөвлөгөө, тендер шалгаруулалт
            </p>
          </div>
        </div>
      </div>
      <div
        className="rounded-3xl rounded-t-3xl bg-white/60 p-4 ring-1 ring-gray-900/10 sm:mx-8 sm:rounded-3xl sm:p-10  lg:rounded-3xl lg:mx-8 h-100 hover:bg-red-50 hover:scale-105 hover:shadow-lg transition-all duration-200 cursor-pointer active:scale-95 active:bg-gray-100"
        onClick={() => navigate("/")}
      >
        <div className="flex justify-start items-center w-full h-full">
          <img
            src="/img/module_icons/inventory.png"
            alt="Icon"
            className="w-20 h-20"
          />
          <div className="mx-3 flex flex-col justify-start h-full">
            <h3 className="text-base/7 font-semibold text-indigo-600">
              Агуулах
            </h3>
            <p className="mt-2 text-sm text-gray-600">Тун удахгүй ... </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ModulePage);
