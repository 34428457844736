import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHrContext } from "src/hr/context/hrContext";
import { useUserContext } from "src/context/userContext";
import { FaUser } from "react-icons/fa";

import _ from "lodash";
import { AiOutlineLogout } from "react-icons/ai";

const LeftSidebar = () => {
  const { hr, hrDispatch } = useHrContext();
  const { user } = useUserContext();

  const location = useLocation();
  const [list, set_list] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    const type = localStorage.getItem("type");
    var result = hr.usermenu;
    if (type === "Bearer")
      result = _.filter(hr?.usermenu, (el) => _.includes([2, 3], el.id));
    set_list(result);
  }, [hr.usermenu]);

  return (
    <div>
      <div className="app-sidebar__overlay" data-toggle="sidebar" />
      <aside className="main-sidebar app-sidebar sidebar-scroll">
        <div className="main-sidebar-header flex items-center justify-center">
          <Link to="/">
            <img src="/emc/odoo.png" alt="logo" className="object-cover h-10" />
          </Link>
        </div>
        <div className="mt-16 py-4 flex items-center justify-center gap-2 text-gray-800 font-semibold">
          <FaUser size={20} />
          {user?.info?.name}
        </div>

        <div className="main-sidebar-body">
          <ul className="side-menu">
            {_.map(list, (el) => {
              return (
                <li
                  key={el.id}
                  className={
                    "slide " +
                    (el.pathname === location.pathname ? " active" : "")
                  }
                  data-toggle={width < 768 ? "sidebar" : ""}
                  onClick={(e) => {
                    hrDispatch({
                      selected_menu: el,
                    });
                    e.preventDefault();
                  }}
                >
                  <Link
                    className="side-menu__item items-center justify-center"
                    to={el.pathname}
                    replace
                  >
                    <img
                      src={
                        el.pathname === "/hr/me"
                          ? "/emc/me.png"
                          : el.pathname === "/hr/definition"
                          ? "/emc/definition.png"
                          : el.pathname === "/hr/cv"
                          ? "/emc/cv.png"
                          : el.pathname === "/hr/vacancy"
                          ? "/emc/vacancy.png"
                          : el.pathname === "/hr/leave"
                          ? "/emc/leave.png"
                          : el.pathname === "/hr/card"
                          ? "/emc/card.png"
                          : el.pathname === "/hr/rate"
                          ? "/emc/rateme.png"
                          : ""
                      }
                      alt=""
                      className="w-8 h-8 object-cover mr-2"
                    />

                    <span className="side-menu__label">{el.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        
        <div className="flex items-end justify-center absolute bottom-0 w-full mb-4 sm:mb-10">
          <div
            className="flex items-center gap-2 text-center px-4 py-2 rounded-md bg-red-500 text-white cursor-pointer duration-300 hover:bg-red-600 z-50"
            onClick={() => {
              localStorage.clear();
              if (window.location.origin === "http://localhost:3000") {
                window.location = "http://localhost:3000/login";
              } else {
                window.location = "https://portal.erdenetmc.mn/login";
              }
            }}
          >
            <AiOutlineLogout size={24} />
            <div>Системээс гарах</div>
          </div>
        </div>
        <span className="items-center justify-center absolute bottom-0 rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset ">v1.0.0.2</span>
      </aside>
    </div>
  );
};

export default React.memo(LeftSidebar);
