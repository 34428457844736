import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import * as API from "src/api/request";
import Loader from "src/components/layout_main/loader";

const Login = () => {
  const { search } = useLocation();
  let params = queryString.parse(search);

  if (params.error) {
    window.location = "https://digital.erdenetmc.mn";
  }

  useLayoutEffect(() => {
    API.POST("/auth", {
      code: params.code,
    })
      .then((res) => {
        localStorage.clear();
        localStorage.setItem("type", "SSO");
        localStorage.setItem("token", res.token);
        console.log(res?.system_token)
        localStorage.setItem('system_token', res?.system_token)
        window.location.replace("/");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.code]);

  return <Loader />;
};

export default React.memo(Login);
