/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
const PurchaseHome = () => {
    return (
        <div className=" w-full h-screen dark:text-white/80 bg-white  border p-4  bg-opacity-60  rounded-xl shadow-sm  dark:bg-slate-950 dark:border-slate-800">
            <p className="font-semibold text-gray-700 dark:text-gray-300">Welcome to home page in purchase module</p>
        </div>
    );
};

export default React.memo(PurchaseHome);
