import React, { useLayoutEffect, useContext, useReducer } from "react";
import { reducer } from "src/context/reducer";
import * as API from "src/api/request";
import { notification } from "antd";
import * as ERP_API from "src/api/epr_request";

const context = React.createContext();

export const useUserContext = () => {
  const ctx = useContext(context);
  if (ctx === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return ctx;
};

const initialState = {
  tn: 0,
  info: {},
  profile_dialog: false,
  user_type: "internal",
  pageLoader: false,
  theme: "light",
};

export const UserContext = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();
  const [state, dispatch] = useReducer(reducer, initialState);

  useLayoutEffect(() => {
    const token = localStorage.getItem("token");
    const type = localStorage.getItem("type");
    dispatch({ pageLoader: true });
    const fetchData = async () => {
      var url = type === "SSO" ? "employee/info" : "user/info";
      try {
        
        if (token) {
          const res = await API.GET(url);
          let name = res.response.name ? res.response.name : `${res.response.last_name} ${res.response.first_name}`;
          await dispatch({ info: { ...res.response }, name });

          let data = {};
          if (type === "SSO") {
            data['user_num'] = btoa(res.response.tn);
            data['is_employee'] = true;
          } else {
            let user_num = (res.response.civil_id && res.response.civil_id !== "false") ? res.response.civil_id : "РС99112217";
            user_num = user_num.toUpperCase();
            data['user_num'] = btoa(unescape(encodeURIComponent(user_num)));
          }

          const is_login = await ERP_API.isLogin();
          if (!localStorage.getItem("user_token") || !is_login) {
            const authResponse = await ERP_API.PORTAL_AUTH('/portal_auth', data);
            await localStorage.setItem('user_token', authResponse?.data?.token);
          }
        } else {
          localStorage.clear();
          dispatch({ pageLoader: false });
        }

      } catch (error) {
        console.error(error);
        localStorage.clear();
        dispatch({ pageLoader: false });
        // window.location.replace("/login");
      } finally {
        dispatch({ pageLoader: false });
      }
    };
    fetchData();
  }, []);

  const message = (type, title, description = null, duration = 5) => {
    if (type === "error") {
      api.error({
        message: title,
        description: description,
        placement: "topRight",
        duration: duration,
        showProgress: true,
      });
    }
    if (type === "info") {
      api.info({
        message: title,
        description: description,
        placement: "topRight",
        duration: duration,
        showProgress: true,
      });
    }
    if (type === "success") {
      api.success({
        message: title,
        description: description,
        placement: "topRight",
        duration: duration,
        showProgress: true,
      });
    }
    if (type === "warning") {
      api.warning({
        message: title,
        description: description,
        placement: "topRight",
        duration: duration,
        showProgress: true,
      });
    }
  };

  return (
    <context.Provider
      value={{
        user: state,
        userDispatch: dispatch,
        message,
      }}
    >
      {contextHolder}
      {children}
    </context.Provider>
  );
};

export default React.memo(UserContext);
