import React, { useContext, useReducer } from "react";
import { reducer } from "src/context/reducer";

const context = React.createContext();

export const useCVContext = () => {
  const ctx = useContext(context);
  if (ctx === undefined) {
    throw new Error("Context error");
  }
  return ctx;
};

export const CVContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    id: null,
    ssnid: null,
    civil_country_id: null,
    family_name: null,
    last_name: null,
    name: null,
    birthday: null,
    gender: null,
    nation_id: null,
    married_id: null,
    mobile: null,
    mobile_alt: null,
    email: null,

    birth_country_id: null,
    birth_city_id: null,
    birth_district_id: null,
    birth_place: null,

    city_id: null,
    district_id: null,
    bag_id: null,
    private_street: null,
    house_type_id: null,
    house_owner_id: null,
    room_id: null,

    emergency_person_name: null,
    emergency_person_type: null,
    emergency_person_mobile: null,
    emergency_person_mobile_alt: null,

    facebook_profile: null,
    photo: null,
    photo_url: null,
    attachments: null,
    fileList: [],

    submit: false,
  });

  return (
    <context.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default React.memo(CVContext);
