import { Button } from "antd";
import {
  AiOutlinePlus,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineArrowLeft,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDownload,
} from "react-icons/ai";

import Swal from "sweetalert2";

export const CreateButton = (props) => {
  return (
    <Button
      {...props}
      type="primary"
      className={
        "bg-gradient-to-r from-[#2e2b4c] to-blue-700 flex items-center justify-center font-semibold text-xs" +
        (" " + props?.className)
      }
      icon={<AiOutlinePlus size={14} />}
    >
      <div>{props?.name ? props?.name : "Нэмэх"}</div>
    </Button>
  );
};

export const SaveButton = (props) => {
  return (
    <Button
      {...props}
      type="primary"
      className={
        "bg-gradient-to-r from-[#2e2b4c] to-blue-700 flex items-center justify-center font-semibold text-xs" +
        (" " + props?.className)
      }
      icon={<AiOutlineCheck className="text-bold" size={14} />}
    >
      <div>{props?.name ? props?.name : "Хадгалах"}</div>
    </Button>
  );
};

export const EditButton = (props) => {
  return (
    <div
      {...props}
      className="p-1.5 flex items-center justify-center bg-gradient-to-r from-[#2e2b4c] to-blue-400 text-white rounded-full cursor-pointer hover:scale-110 duration-300"
    >
      <AiOutlineEdit size={14} title="Засах" />
    </div>
  );
};

export const DeleteButton = (props) => {
  return (
    <div
      {...props}
      className="p-1.5 flex items-center justify-center bg-red-500 text-white rounded-full cursor-pointer hover:scale-110 duration-300"
      onClick={() => {
        Swal.fire({
          title: "",
          text: "Итгэлтэй байна уу?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#1890ff",
          cancelButtonColor: "rgb(244, 106, 106)",
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            props.onClick();
          }
        });
      }}
    >
      <AiOutlineDelete size={14} title="Устгах" />
    </div>
  );
};

export const DownloadButton = (props) => {
  return (
    <div
      {...props}
      className="p-1.5 flex items-center justify-center bg-green-500 text-white rounded-full cursor-pointer hover:scale-110 duration-300"
      onClick={() => {
        props.onClick();
      }}
    >
      <AiOutlineDownload size={14} title="Татах" />
    </div>
  );
};

export const BackButton = (props) => {
  return (
    <div
      {...props}
      className={
        "p-2 flex items-center justify-center bg-gradient-to-r from-[#2e2b4c] to-blue-400 text-white rounded-full cursor-pointer hover:scale-110 duration-300 " +
        props.className
      }
      onClick={() => props.onClick()}
    >
      <AiOutlineArrowLeft
        size={14}
        title="Буцах"
        onClick={() => props.onClick()}
      />
    </div>
  );
};

export const CloseButton = (props) => {
  return (
    <Button
      {...props}
      type="primary"
      className={
        "bg-gradient-to-r from-gray-700 to-gray-600 flex items-center justify-center font-semibold text-xs" +
        (" " + props?.className)
      }
      icon={
        <AiOutlineClose
          size={14}
          title="Буцах"
          onClick={() => props.onClick()}
        />
      }
    >
      <div>{props?.name ? props?.name : "Хаах"}</div>
    </Button>
  );
};
