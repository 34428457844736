/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Button, Modal } from 'antd';
import { FileTextFilled } from '@ant-design/icons';
import { usePurchaseContext } from "src/purchase/context/purchaseContext"

import PDFViewer from "../components/pdfviewer";

const people = [
    {
        name: 'Michael Foster',
        email: 'michael.foster@example.com',
        role: 'Гэрээ  / 01',
        imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: '3h ago',
        lastSeenDateTime: '2023-01-23T13:23Z',
    },
    {
        name: 'Dries Vincent',
        email: 'dries.vincent@example.com',
        role: 'Гэрээ  / 02',
        imageUrl:
            'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        lastSeen: null,
    }
]

const PurchaseContract = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [startPage, setStartPage] = useState(1);
    // const [endPage, setEndPage] = useState(2);
    // const [url, setUrl] = useState(null);
    const { purchaseState } = usePurchaseContext();
    const [style, setStyle] = useState();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (purchaseState?.theme === 'light') {
            setStyle({
                backgroundColor: '#ffffff', // Light mode background
                color: '#000000', // Light mode text color
            });
        } else if (purchaseState?.theme === 'dark') {
            setStyle({
                backgroundColor: '#333333', // Dark mode background
                color: '#ffffff', // Dark mode text color
            });
        }
        console.log(style)
    }, [purchaseState?.theme]);

    return (
        <div className=" w-full h-screen bg-white  text-gray-500   border p-4  bg-opacity-60  rounded-xl shadow-sm dark:text-gray-200 dark:bg-slate-950 dark:border-slate-800">
            <ul className="divide-y divide-gray-400">
                {people.map((person) => (
                    <li key={person.email} className="flex justify-between gap-x-6 py-5">
                        <div className="flex min-w-0 gap-x-4">
                            <img alt="" src={person.imageUrl} className="size-12 flex-none rounded-full bg-gray-50" />
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm/6 font-semibold">{person.name}</p>
                                <p className="mt-1 truncate ">
                                    <Row className="text-xs/5 font-semibold">
                                        <p className="text-sm/6 text-gray-900">{person.role}</p>
                                    </Row></p>
                            </div>
                        </div>
                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                            <Button type="primary" shape="circle" icon={<FileTextFilled />} onClick={showModal} />
                            {person.lastSeen ? (
                                <p className="mt-1 text-xs/5 text-gray-500">
                                    {/* Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time> */}
                                </p>

                            ) : (
                                <div className="mt-1 flex items-center gap-x-1.5">


                                </div>
                            )}

                        </div>
                    </li>
                ))}

            </ul>
            <Modal title="Contract" open={isModalOpen} onOk={handleOk} width={{
                xs: '90%',
                sm: '80%',
                md: '70%',
                lg: '60%',
                xl: '50%',
                xxl: '40%',
            }}>
                <div>
                    <PDFViewer url='https://spfiles.erdenetmc.mn/odoo17ee/emc.decree/20240817/46qsy2ehww1iwkjhnx758cz3k2cdss.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=VPGD5JHW1VO352YZZLL8%2F20250218%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250218T002629Z&X-Amz-Expires=604799&X-Amz-Security-Token=eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NLZXkiOiJWUEdENUpIVzFWTzM1MllaWkxMOCIsImV4cCI6MTczOTg4MTQ1MCwicGFyZW50IjoibWluaW9hZG1pbiJ9.S7D99fPx9TFLdvsDT64rD7sRzoHsdrPoYhE5fQgX6FPIzi14znMO52h51mnlRAJPC0StgVyS3bkaxH_TjJZnyQ&X-Amz-SignedHeaders=host&versionId=null&X-Amz-Signature=abba08da3e7c4aa33bed9e8eb0d1d0a60c3e449fb1d103175170e1843efa495b' />
                </div>
            </Modal>
        </div>
    );
};

export default React.memo(PurchaseContract);
