import { Spin } from "antd";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";

const Step3 = ({ state, setState, fetch_pdf }) => {
  return (
    <div className="card-body relative pt-0">
      <div className="pt-2 pb-2 cursor-pointer group sticky top-0 bg-white border-b">
        <div className="flex items-center">
          <div
            className="flex items-center"
            onClick={() => {
              setState((old) => ({ ...old, step: 2 }));
            }}
          >
            <IoIosArrowBack
              size={26}
              className="duration-300 group-hover:text-blue-800"
            />
            <div className="text-xs sm:text-sm duration-300 group-hover:text-blue-800 group-hover:font-semibold">
              Буцах
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col pt-2">
        <div className="text-xs sm:text-base">Тодорхойлолт</div>
        <div className="font-medium  sm:text-xl mb-4">
          {state?.organization_name}
        </div>
        <Spin spinning={state?.loading}>
          <div
            className="flex items-center justify-center rounded-3xl py-2 px-4 bg-blue-500 text-white shadow-xl cursor-pointer duration-300 hover:bg-blue-700"
            onClick={() => {
              fetch_pdf(1);
            }}
          >
            Тодорхойлолт авах
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Step3;
