import React, { useState } from "react";

import Info from "./info";
import Job from "./job";
import Family from "./family";
import Training from "./training";
import Award from "./award";
import Help from "./help";
import _ from "lodash";

const tabs = [
  { id: 1, text: "Ажил эрхлэлт" },
  { id: 2, text: "Гэр бүлийн байдал" },
  { id: 3, text: "Сургалт" },
  { id: 4, text: "Шагнал" },
  { id: 5, text: "Тусламж" },
];

const Index = () => {
  const [tab, setTab] = useState(1);

  return (
    <div className="row row-sm main-content-mail">
      <Info />
      <div className="col-lg-8">
        <div className="main-content-body main-content-body-profile">
          <div className="card">
            <div className="card-header">
              <nav className="nav main-nav-line card">
                {_.map(tabs, (item, index) => {
                  return (
                    <a
                      key={index}
                      href="/"
                      data-toggle="tab"
                      className={
                        "nav-link" + (item.id === tab ? " active" : "")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setTab(item.id);
                      }}
                    >
                      {item.text}
                    </a>
                  );
                })}
              </nav>
            </div>
            <div className="px-5 pb-5 ">
              {tab === 1 && <Job />}
              {tab === 2 && <Family />}
              {tab === 3 && <Training />}
              {tab === 4 && <Award />}
              {tab === 5 && <Help />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Index);
