import React from "react";
import { Modal, Spin } from "antd";
import dayjs from "dayjs";

const FileModal = ({ state, setState }) => {
  return (
    <Modal
      title={
        <div className="text-center">
          {dayjs(state.date).format("YYYY") +
            " оны " +
            dayjs(state.date).format("MM") +
            " сарын гүйцэтгэлийн гол үзүүлэлтийн тайлан"}
        </div>
      }
      centered
      open={state.file_modal}
      maskClosable={false}
      onCancel={() => {
        setState((old) => ({
          ...old,
          file_modal: false,
          file_url: null,
        }));
      }}
      footer={null}
      width={1280}
    >
      <Spin spinning={state.modal_loading}>
        <iframe
          title="PDF"
          frameBorder={0}
          src={state?.file_url}
          style={{
            width: "100%",
            height: window.innerWidth < 500 ? "450px" : "740px",
          }}
        />
      </Spin>
    </Modal>
  );
};

export default FileModal;
