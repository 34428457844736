import { useState } from "react";
import { useUserContext } from "src/context/userContext";
import { useCVContext } from "../../context/cvContext";

import Info from "./info";
import Family from "./family";
import Education from "./education";
import Specialty from "./specialty";
import Military from "./military";
import Award from "./award";
import Job from "./job";
import Patent from "./patent";

import { Steps } from "antd";

export default function Index() {
  const { message } = useUserContext();
  const { state } = useCVContext();
  const [list] = useState([
    {
      title: "Хувийн мэдээлэл",
      content: <Info />,
    },
    {
      title: "Гэр бүл",
      content: <Family />,
    },
    {
      title: "Боловсрол",
      content: <Education />,
    },
    {
      title: "Мэргэшил",
      content: <Specialty />,
    },
    {
      title: "Цэргийн алба",
      content: <Military />,
    },
    {
      title: "Шагнал",
      content: <Award />,
    },
    {
      title: "Туршлага",
      content: <Job />,
    },
    {
      title: "Бүтээл",
      content: <Patent />,
    },
  ]);
  const [step, setStep] = useState(0);

  return (
    <div className="card">
      <div className="card-header text-center border">
        <span className="text-lg uppercase font-bold">
          “ЭРДЭНЭТ ҮЙЛДВЭР” ТӨҮГ-ЫН АНКЕТ
        </span>
      </div>
      <div className="card-body">
        <Steps
          current={step}
          size="small"
          onChange={(value) => {
            if (!state.id && value !== 0)
              message("warning", "Эхлээд хувийн мэдээлэл бүртгэл хадгална уу.");
            else setStep(value);
          }}
          items={list}
        />
        <hr className="my-3" />
        {list[step].content}
      </div>
    </div>
  );
}
