import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";
import Breadcrumb from "./breadcrumb";

const Layout = () => {
  const inpref = useRef();
  const [isvisible, setIsvisible] = useState(false);

  document.onkeydown = function (e) {
    e = e || window.event;
    if (e.ctrlKey && (e.key === "`" || e.key === "=")) {
      e.preventDefault();
      e.stopPropagation();
      setIsvisible(true);
    }
  };

  useEffect(() => {
    if (isvisible) {
      inpref.current.focus();
    }
  }, [isvisible]);

  return (
    <div className="page fadeIn animated">
      {/* <Modal
        title=""
        centered
        visible={isvisible}
        onCancel={() => setIsvisible(false)}
        footer={false}
        width={200}
      >
        <Input
          className="w-[90%] flex items-center justify-center"
          ref={inpref}
          onKeyDown={async (e) => {
            if (e?.key === "Enter") {
              const res = await axios.post(
                `${
                  window.location.origin === "http://localhost:3000"
                    ? "https://testoffice.erdenetmc.mn"
                    : window.location.origin
                }/api/eoffice/user/change`,
                { tn: e.target.value },
                {
                  headers: {
                    Authorization: `SSO ${
                      localStorage.getItem("dev_token")
                        ? localStorage.getItem("dev_token")
                        : localStorage.getItem("token")
                    }`,
                  },
                }
              );
              if (res.status === 200) {
                if (!localStorage.getItem("dev_token")) {
                  localStorage.setItem(
                    "dev_token",
                    localStorage.getItem("token")
                  );
                }
                localStorage.setItem("token", res?.data);
                window.location.reload();
              }
            }
          }}
        />
      </Modal> */}

      <Sidebar />

      <div className="main-content h-100">
        <Header />
        <div className="container-fluid">
          <Breadcrumb />
          {<Outlet />}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Layout);
