import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "src/context/userContext";
import * as API from "src/api/request";
import _ from "lodash";

import { Spin } from "antd";
import { FaFilter } from "react-icons/fa";
import dayjs from "dayjs";

const Index = () => {
  const navigate = useNavigate();
  const { message } = useUserContext();
  const [loading, set_loading] = useState(false);
  const [list, set_list] = useState([]);
  const [list_filtered, set_list_filtered] = useState([]);

  const [reference_source, set_reference_source] = useState([]);
  const [dic_reference_source, set_dic_reference_source] = useState({});

  const [filter_source, set_filter_source] = useState([]);
  const [source, set_source] = useState(null);
  const [filter_active, set_filter_active] = useState([]);
  const [active, set_active] = useState(null);
  const [filter_applied, set_filter_applied] = useState([]);
  const [applied, set_applied] = useState(null);

  useEffect(() => {
    API.GET("reference/selection", {
      model_name: "emc.hr.vacancy",
      field_name: "source",
    }).then((res) => {
      if (res.status_code === 1) {
        var dic = {};
        _.map(res.response, (item) => {
          dic[item.value] = item.name;
        });
        set_reference_source(res.response);
        set_dic_reference_source(dic);
      } else message("error", res.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    set_loading(true);
    API.GET("vacancy")
      .then((res) => {
        if (res.status_code === 1) {
          set_list(_.orderBy(res.response, "end_date", "desc"));
        } else message("error", res.response);
      })
      .finally(() => set_loading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var count_source = _.countBy(list, "source");
    var count_applied = _.countBy(list, "is_applied");

    var result = [{ value: null, name: "Бүгд", count: list.length }];
    _.map(reference_source, (item) => {
      result.push({ ...item, count: count_source[item.value] || 0 });
    });
    set_filter_source(result);

    set_filter_active([
      { value: null, name: "Бүгд", count: list.length },
      {
        value: 1,
        name: "Идэвхтэй",
        count: _.filter(
          list,
          (item) =>
            dayjs(item.begin_date) <= dayjs() && dayjs(item.end_date) >= dayjs()
        ).length,
      },
      {
        value: 2,
        name: "Идэвхгүй",
        count: _.filter(
          list,
          (item) =>
            dayjs(item.begin_date) > dayjs() || dayjs(item.end_date) < dayjs()
        ).length,
      },
    ]);

    result = [
      { value: null, name: "Бүгд", count: list.length },
      { value: true, name: "Илгээсэн", count: count_applied[true] || 0 },
      { value: false, name: "Илгээгээгүй", count: count_applied[false] || 0 },
    ];
    set_filter_applied(result);
  }, [list, reference_source]);

  useEffect(() => {
    set_loading(true);
    setTimeout(() => {
      var result = list;

      if (source) result = _.filter(result, (item) => item.source === source);

      if (active) {
        if (active === 1)
          result = _.filter(
            result,
            (item) =>
              dayjs(item.begin_date) <= dayjs() &&
              dayjs(item.end_date) >= dayjs()
          );
        if (active === 2)
          result = _.filter(
            result,
            (item) =>
              dayjs(item.begin_date) > dayjs() || dayjs(item.end_date) < dayjs()
          );
      }

      if (applied !== null)
        result = _.filter(result, (item) => item.is_applied === applied);

      set_list_filtered(result);
      set_loading(false);
    }, 200);
  }, [list, source, active, applied]);

  return (
    <Spin spinning={loading}>
      <div className="flex flex-col xl:flex-row xl:gap-5">
        <div className="xl:w-1/5 card">
          <div className="card-body text-xs">
            <div className="flex flex-col">
              <div className="mb-1 font-semibold">Эх үүсвэр</div>
              {_.map(filter_source, (item) => (
                <div
                  key={"key_source_" + item.value}
                  className={
                    "p-2 flex items-center justify-between border-y rounded-lg cursor-pointer hover:bg-gray-100 duration-500" +
                    (source === item.value
                      ? " bg-gradient-to-r from-green-700 to-blue-600 text-white font-semibold"
                      : "")
                  }
                  onClick={() => set_source(item.value)}
                >
                  <div className="flex items-center justify-center gap-1">
                    <div className="p-1.5 border rounded-full">
                      <FaFilter size={12} />
                    </div>
                    <span>{item.name}</span>
                  </div>
                  <div className="font-semibold">{item.count}</div>
                </div>
              ))}

              <div className="mt-4 mb-1 font-semibold">Идэвхтэй эсэх</div>
              {_.map(filter_active, (item) => (
                <div
                  key={"key_active_" + item.value}
                  className={
                    "p-2 flex items-center justify-between border-y rounded-lg cursor-pointer hover:bg-gray-100 duration-500" +
                    (active === item.value
                      ? " bg-gradient-to-r from-green-700 to-blue-600 text-white font-semibold"
                      : "")
                  }
                  onClick={() => set_active(item.value)}
                >
                  <div className="flex items-center justify-center gap-1">
                    <div className="p-1.5 border rounded-full">
                      <FaFilter size={12} />
                    </div>
                    <span>{item.name}</span>
                  </div>
                  <div className="font-semibold">{item.count}</div>
                </div>
              ))}

              <div className="mt-4 mb-1 font-semibold">Миний горилсон</div>
              {_.map(filter_applied, (item) => (
                <div
                  key={"key_applied_" + item.value}
                  className={
                    "p-2 flex items-center justify-between border-y rounded-lg cursor-pointer hover:bg-gray-100 duration-500" +
                    (applied === item.value
                      ? " bg-gradient-to-r from-green-700 to-blue-600 text-white font-semibold"
                      : "")
                  }
                  onClick={() => set_applied(item.value)}
                >
                  <div className="flex items-center justify-center gap-1">
                    <div className="p-1.5 border rounded-full">
                      <FaFilter size={12} />
                    </div>
                    <span>{item.name}</span>
                  </div>
                  <div className="font-semibold">{item.count}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex-1 card">
          <div className="card-body text-xs">
            <div className="flex flex-col gap-3">
              {_.map(list_filtered, (item) => {
                return (
                  <div
                    key={item.id}
                    className="relative border rounded-xl font-semibold cursor-pointer hover:bg-gray-100"
                    onClick={() => navigate("/hr/vacancy/" + item.id)}
                  >
                    <div className="flex justify-between">
                      <div className="w-24 h-5 text-center bg-green-500 text-white italic rounded-tl-lg rounded-br-2xl">
                        {item.source in dic_reference_source
                          ? dic_reference_source[item.source]
                          : null}
                      </div>

                      {item.is_applied && (
                        <div className="w-24 h-5 text-center bg-cyan-500 text-white italic rounded-bl-2xl rounded-tr-lg">
                          Илгээсэн
                        </div>
                      )}
                    </div>

                    <div className="mt-1">
                      <div className="px-2 text-base">{item.name}</div>

                      <div className="px-2 pb-2">
                        <div className="flex flex-col md:flex-row md:gap-4">
                          <div className="text-green-700">
                            {item.vacancy_text}
                          </div>
                          <div>
                            <span className="text-gray-400 mr-1">
                              Бүртгэл эхлэх:
                            </span>
                            <span>{item.begin_date}</span>
                          </div>
                          <div className="">
                            <span className="text-gray-400 mr-1">
                              Бүртгэл дуусах:
                            </span>
                            <span>{item.end_date}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {list_filtered.length === 0 && (
                <div className="p-2 border rounded-lg">
                  <div className="text-cyan-500 text-lg italic font-semibold">
                    Хоосон байна...
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default React.memo(Index);
