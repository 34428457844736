import axios from "axios";

export function API() {
  const headers = {
    "Content-Type": "application/json,",
  };
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : null;

  const type =
    typeof window !== "undefined" ? localStorage.getItem("type") : null;

  if (token && type) {
    Object.assign(headers, {
      Authorization: type ? type + " " + token : "SSO " + token,
    });
  }

  var api = axios.create({
    baseURL: "https://portal.erdenetmc.mn/api",
    timeout: 200000,
    headers,
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.status === 401) {
        localStorage.clear();
        window.location.replace("/login");
      }
      throw error;
    }
  );
  return api;
}

export async function GET(url, params = null) {
  const response = await API().get(url, {
    params: {
      ...params,
    },
  });
  return response?.data;
}

export async function POST(url, params) {
  const response = await API().post(url, { ...params });
  return response?.data;
}

export async function PUT(url, params) {
  const response = await API().put(url, { ...params });
  return response?.data;
}

export async function DELETE(url, params = null) {
  const response = await API().delete(url, {
    params: {
      ...params,
    },
  });
  return response?.data;
}

export async function UPLOAD_FILE(url, formData) {
  const response = await API().post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data;
}

export async function GET_FILE(url) {
  const response = await API().get(url, {
    responseType: "blob",
  });
  const fileUrl = URL.createObjectURL(response.data);
  return fileUrl;
}
